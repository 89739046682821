'use client';

import { MessageBox } from '@wishket/design-system';

import { externalLinkList } from './WithdrawalInfoBox.constants';

import { useWithdrawalInfo } from '@/entities/withdrawal';
import { WithSkeleton } from '@/shared/Components';
import { withComponentLogger } from '@/shared/hocs';

const WithdrawalInfoBox = () => {
  const { withdrawalInfo, isLoading } = useWithdrawalInfo();

  return (
    <WithSkeleton isLoading={isLoading}>
      <MessageBox
        icon="service"
        title={
          withdrawalInfo.isWithdrawalRequested
            ? '탈퇴는 언제 처리될까요?'
            : '위시켓을 이용하시는데 아래와 같은 불편함이 있으신가요?'
        }
        description={
          withdrawalInfo.isWithdrawalRequested
            ? '영업일 기준 최대 24시간 이내로 탈퇴가 완료되었음을 이메일로 알려드립니다.'
            : ''
        }
        links={withdrawalInfo.isWithdrawalRequested ? [] : externalLinkList}
      />
    </WithSkeleton>
  );
};

export default withComponentLogger(WithdrawalInfoBox);
