import { Box, Switch, Typography } from '@wishket/design-system';

import MarketingDescriptionOpenButton from './MarketingAgreementToggleForm.parts';

interface MarketingAgreementToggleFormProps {
  isAgreeUse: boolean;
  isAgreeSubscribe: boolean;
  onToggleUse: () => void;
  onToggleSubscribe: () => void;
  onOpenUseDescriptionModal: () => void;
  onOpenSubscribeDescriptionModal: () => void;
}

const MarketingAgreementToggleForm = ({
  isAgreeUse,
  isAgreeSubscribe,
  onToggleUse,
  onToggleSubscribe,
  onOpenUseDescriptionModal,
  onOpenSubscribeDescriptionModal,
}: MarketingAgreementToggleFormProps) => {
  return (
    <Box className="flex flex-col gap-8">
      <Typography
        variant="subTitle1"
        className="font-normal text-w-gray-900 desktop:typo-title3"
      >
        이용 동의
      </Typography>
      <Box className="flex items-center justify-between">
        <Box className="flex items-stretch justify-start gap-3">
          <Typography variant="body1" className="font-medium text-w-gray-900">
            개인정보 마케팅 활용 동의
          </Typography>
          <MarketingDescriptionOpenButton
            onClick={onOpenUseDescriptionModal}
            testid="marketing-use-agreement-description-open-button"
          />
        </Box>
        <Switch
          checked={isAgreeUse}
          onChange={onToggleUse}
          data-testid="marketing-use-agreement-switch"
        />
      </Box>
      <Box className="flex items-center justify-between">
        <Box className="flex items-stretch justify-start gap-3">
          <Typography variant="body1" className="font-medium text-w-gray-900">
            마케팅 정보 수신 동의
          </Typography>
          <MarketingDescriptionOpenButton
            onClick={onOpenSubscribeDescriptionModal}
            testid="marketing-subscribe-agreement-description-open-button"
          />
        </Box>
        <Switch
          checked={isAgreeSubscribe}
          onChange={onToggleSubscribe}
          data-testid="marketing-subscribe-agreement-switch"
        />
      </Box>
    </Box>
  );
};

export default MarketingAgreementToggleForm;
