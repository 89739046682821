'use client';

import { useMarketingAgreement } from '@/entities/profile';
import {
  MarketingAgreementToggleForm,
  MarketingWithdrawalModal,
} from '@/features';
import { PolicyModal } from '@/shared/Components';
import { usePolicyModal } from '@/shared/hooks';
import { MarketingAgreementType } from '@/shared/types';

const MarketingAgreementToggle = () => {
  const {
    data,
    mutate,
    isLoading,
    marketingWithdrawalModal,
    onAgreeWithdrawal,
    marketingWithdrawalType,
  } = useMarketingAgreement();
  const {
    isPolicyModalOpen,
    closePolicyModal,
    openPolicyModal,
    policyModalType,
  } = usePolicyModal();

  if (isLoading) return null;

  const onToggleUse = () => {
    mutate.use({ isAgreeWithdrawal: false });
  };

  const onToggleSubscribe = () => {
    mutate.subscribe({ isAgreeWithdrawal: false });
  };

  const onOpenUseDescriptionModal = () => {
    openPolicyModal(MarketingAgreementType.USE);
  };

  const onOpenSubscribeDescriptionModal = () => {
    openPolicyModal(MarketingAgreementType.SUBSCRIBE);
  };

  return (
    <>
      <PolicyModal
        isOpen={isPolicyModalOpen}
        onClose={closePolicyModal}
        type={policyModalType}
      />
      <MarketingWithdrawalModal
        isOpen={marketingWithdrawalModal.isOpen}
        onClose={marketingWithdrawalModal.onClose}
        onAgree={onAgreeWithdrawal}
        type={marketingWithdrawalType}
      />
      <MarketingAgreementToggleForm
        isAgreeUse={data.toggleStatus.isAgreeUse}
        isAgreeSubscribe={data.toggleStatus.isAgreeSubscribe}
        onToggleUse={onToggleUse}
        onToggleSubscribe={onToggleSubscribe}
        onOpenUseDescriptionModal={onOpenUseDescriptionModal}
        onOpenSubscribeDescriptionModal={onOpenSubscribeDescriptionModal}
      />
    </>
  );
};

export default MarketingAgreementToggle;
