import { Box, Button, SystemIcon, Typography } from '@wishket/design-system';

import { Dialog, Icon } from '@/shared/Components';
import { SocialLoginDialogData } from '@/shared/types';
import { maskEmail, maskId } from '@/shared/utils';

interface NeedLinkDialogProps extends SocialLoginDialogData {
  onClickCloseButton: () => void;
  onClickSubmitButton: () => void;
}

const NeedLinkDialog = ({
  provider,
  user,
  onClickCloseButton,
  onClickSubmitButton,
}: NeedLinkDialogProps) => {
  return (
    <Dialog className="h-auto min-h-[496px] w-[320px] gap-8 rounded-2xl bg-w-white p-8 shadow-graymedium tablet:w-[400px] desktop:min-h-[430px] desktop:w-[520px]">
      <Box className="relative flex w-full flex-col items-center justify-center gap-6">
        <Box
          className="absolute right-0 top-0 cursor-pointer"
          onClick={onClickCloseButton}
        >
          <SystemIcon name="large_delete" />
        </Box>
        <Box>
          <Icon name="login" />
        </Box>
        <Box className="flex flex-col items-center justify-center gap-3">
          <Box className="flex flex-col items-center justify-center desktop:flex-row desktop:gap-1">
            <Typography
              variant="subTitle2"
              className="font-medium text-w-gray-900"
            >
              {`이미 같은 이메일로 가입한`}
            </Typography>
            <Typography
              variant="subTitle2"
              className="font-medium text-w-gray-900"
            >
              {`계정이 있어요!`}
            </Typography>
          </Box>
          <Box className="flex flex-col items-center justify-center desktop:flex-row desktop:gap-1">
            <Typography variant="body1" className="font-normal text-w-gray-700">
              {`${provider}을 연동하고 싶다면`}
            </Typography>
            <Typography variant="body1" className="font-normal text-w-gray-700">
              {`아래 계정으로 로그인해 주세요.`}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className="w-full gap-1.5 rounded-2xl bg-w-gray-10 px-6 py-5 text-w-gray-600">
        <Box className="flex items-start justify-start gap-4">
          <Typography
            variant="body2"
            className="w-10 break-keep font-medium text-w-gray-600"
          >
            아이디
          </Typography>
          <Typography
            variant="body2"
            className="h-auto break-all font-normal text-w-gray-600"
          >
            {maskId(user.id)}
          </Typography>
        </Box>
        <Box className="flex items-start justify-start gap-4">
          <Typography
            variant="body2"
            className="w-10 break-keep font-medium text-w-gray-600"
          >
            이메일
          </Typography>
          <Typography
            variant="body2"
            className="h-auto break-all font-normal text-w-gray-600"
          >
            {maskEmail(user.email)}
          </Typography>
        </Box>
      </Box>
      <Button variant="solid" className="w-full" onClick={onClickSubmitButton}>
        확인
      </Button>
    </Dialog>
  );
};

export default NeedLinkDialog;
