import { Box } from '@wishket/design-system';

import {
  BulletList,
  Description,
  InformationBox,
  SubTitle,
  Title,
  UnOrderedList,
} from '../Terms.parts';

const YozmITYouthProtection = () => {
  return (
    <Box className="flex w-full flex-col gap-8 break-all">
      <Title>요즘IT 청소년보호정책</Title>
      <Description>{`요즘IT는(이하 “회사”라 함) 청소년이 건전한 인격체로 성장할 수 있도록 돕기 위해 ‘정보통신망이용촉진 및 정보보호 등에 관한 법률’ 및 ‘청소년보호법’에 근거하여 청소년보호정책을 수립, 시행하고 있습니다.`}</Description>
      <SubTitle>1. 청소년 보호를 위한 목표 및 기본 원칙</SubTitle>
      <Description>{`회사는 청소년이 정신적·신체적으로 유해한 환경으로부터 보호받고 유익한 환경을 조성하도록 노력하며, 안전하게 인터넷을 이용할 수 있는 서비스를 제공하기 위해 청소년보호정책을 명시하고 있습니다.`}</Description>
      <SubTitle>2. 유해정보에 대한 청소년접근제한 및 관리조치</SubTitle>
      <Description>{`회사는 청소년에게 유해한 콘텐츠를 생산 및 게재하지 않음으로써 청소년 유해정보를 원천적으로 예방하고 있으며 각종 정보건전화 교육 및 캠페인 등을 통하여 네티켓 의식 고취를 위하여 노력하고 있습니다.`}</Description>
      <SubTitle>3. 유해정보로 인한 피해상담 및 고충처리</SubTitle>
      <Description>{`회사는 유해정보로 인한 피해상담 및 고충처리를 위한 전문인력을 배치하여 구제조치의 지연 및 처리 미숙으로 인한 피해가 확산되지 않도록 노력하고 있으며 자체적으로 처리가 곤란할 경우 피해 특성별 관련기관을 안내해 드리고 있습니다.`}</Description>
      <SubTitle>4. 청소년보호업무 담당자 교육 시행</SubTitle>
      <Description>{`회사는 청소년유해매체물에 대한 관리, 감독을 철저히 하기 위해 청소년보호 업무 담당자 및 책임자를 지정하고, 수시로 교육을 시행하고 있습니다.`}</Description>
      <SubTitle>5. 청소년보호책임자 및 담당자 연락법</SubTitle>
      <Description>
        {`아래와 같이 청소년보호 책임자 및 담당자를 지정하여 청소년유해 정보의 차단 및 관리, 청소년유해정보로부터의 청소년보호 정책을 수립하는 등 청소년보호 업무를 수행하고 있습니다.`}
        <InformationBox>
          <UnOrderedList>
            <BulletList>{`이름 : 박우범`}</BulletList>
            <BulletList>{`소속(직위) : 대표이사`}</BulletList>
            <BulletList>{`이메일 : parkwoobeom@wishket.com`}</BulletList>
            <BulletList>{`전화 : (02) 6925-4849`}</BulletList>
          </UnOrderedList>
        </InformationBox>
      </Description>
    </Box>
  );
};

export default YozmITYouthProtection;
