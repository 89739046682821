'use client';

import { useState } from 'react';

import { clientAxios } from '@/shared/api';

interface PasswordVerifyData {
  uid: string;
  token: string;
  result: boolean;
}

const usePasswordVerify = () => {
  const [isError, setIsError] = useState(false);
  const [passwordVerifyData, setPasswordVerifyData] = useState<
    PasswordVerifyData | undefined
  >(undefined);

  const [isLoading, setIsLoading] = useState(false);

  const fetchPasswordVerify = async ({ password }: { password: string }) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const { data } = await clientAxios.post('/passwordVerifyApi', {
        password,
      });
      if (data.ok && data.data.result) {
        setPasswordVerifyData(data.data);
      }
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    data: passwordVerifyData,
    fetch: fetchPasswordVerify,
  };
};

export default usePasswordVerify;
