'use client';

import { Box, Divider, Typography } from '@wishket/design-system';
import { useState } from 'react';

import MarketingAgreementToggle from '../MarketingAgreementToggle';

import { Icon, LoadingDialog, WithSkeleton } from '@/shared/Components';
import { useProfile, useSocialLink } from '@/entities/profile';
import { SocialLinkDupErrorDialog } from '@/features';
import { SocialLoginProvider } from '@/shared/types';
import { generateSocialProviderName } from '@/shared/utils';
import { withComponentLogger } from '@/shared/hocs';

const AccountInfo = () => {
  const [socialLinkLoadingMessage, setSocialLinkLoadingMessage] =
    useState<string>('');
  const { email, username } = useProfile();
  const {
    error,
    resetError,
    socialLinkInfo,
    isInitLoading,
    isFetchLoading,
    clickSocialLink,
    clickSocialUnlink,
  } = useSocialLink();

  const handleSocialUnlink = (provider: SocialLoginProvider) => {
    const providerName = generateSocialProviderName(provider);

    setSocialLinkLoadingMessage(`${providerName} 계정 연동을 해제 중입니다.`);
    confirm(`${providerName} 계정 연동을 해제하시겠습니까?`) &&
      clickSocialUnlink(provider);
  };

  const handleSocialLink = (provider: SocialLoginProvider) => {
    const providerName = generateSocialProviderName(provider);

    setSocialLinkLoadingMessage(`${providerName} 계정 연동 중입니다.`);
    clickSocialLink(provider);
  };

  return (
    <>
      {error.isError && error.status === 400 && error.data && (
        <SocialLinkDupErrorDialog
          onClickCloseButton={resetError}
          onClickSubmitButton={resetError}
          provider={error.data.provider as SocialLoginProvider}
          user={{
            id: error.data.username as string,
            email: error.data.email as string,
          }}
        />
      )}
      {!isInitLoading && isFetchLoading && (
        <LoadingDialog description={socialLinkLoadingMessage} />
      )}
      <Box
        className="flex flex-col desktop:min-h-[60vh]"
        data-testid="account-info"
      >
        <WithSkeleton isLoading={isInitLoading}>
          <Box className="flex flex-col gap-8">
            <Typography
              variant="subTitle1"
              className="font-normal text-w-gray-900 desktop:text-2xl"
            >
              계정 정보
            </Typography>
            <Box className="flex flex-col gap-1.5">
              <Typography
                variant="body1"
                className="font-medium text-w-gray-900"
              >
                아이디
              </Typography>
              <Typography variant="body1" className="text-w-gray-600">
                {username}
              </Typography>
            </Box>
            <Box className="flex flex-col justify-start gap-1.5">
              <Typography
                variant="body1"
                className="font-medium text-w-gray-900"
              >
                이메일
              </Typography>
              <Typography variant="body1" className="text-w-gray-600">
                {email}
              </Typography>
            </Box>
          </Box>
        </WithSkeleton>
        <Divider className="my-10" />
        <WithSkeleton isLoading={isInitLoading}>
          <Box className="flex h-fit w-full flex-col gap-8">
            <Typography
              variant="subTitle1"
              className="font-normal text-w-gray-900 desktop:text-2xl"
            >
              소셜 연동
            </Typography>
            <Box className="flex flex-col gap-1">
              <Box className="flex justify-between">
                <Typography
                  variant="body1"
                  className="font-medium text-w-gray-900"
                >
                  구글 계정 연동
                </Typography>
                <Typography
                  variant="body1"
                  className="cursor-pointer font-medium text-w-primary"
                  onClick={
                    socialLinkInfo.isActiveGoogle
                      ? () => handleSocialUnlink('google')
                      : () => handleSocialLink('google')
                  }
                >
                  {socialLinkInfo.isActiveGoogle ? '연동 해제' : '연동하기'}
                </Typography>
              </Box>
              <Box className="flex items-center gap-2">
                {socialLinkInfo.isActiveGoogle && <Icon name="check" />}
                <Typography
                  variant="body1"
                  className={
                    socialLinkInfo.isActiveGoogle
                      ? 'text-w-gray-600'
                      : 'text-w-gray-400'
                  }
                >
                  {socialLinkInfo.isActiveGoogle
                    ? '연동 중입니다.'
                    : '연동된 구글 계정이 없습니다.'}
                </Typography>
              </Box>
            </Box>
            <Box className="flex flex-col gap-1">
              <Box className="flex justify-between">
                <Typography
                  variant="body1"
                  className="font-medium text-w-gray-900"
                >
                  페이스북 계정 연동
                </Typography>
                <Typography
                  variant="body1"
                  className="cursor-pointer font-medium text-w-primary"
                  onClick={
                    socialLinkInfo.isActiveFacebook
                      ? () => handleSocialUnlink('facebook')
                      : () => handleSocialLink('facebook')
                  }
                >
                  {socialLinkInfo.isActiveFacebook ? '연동 해제' : '연동하기'}
                </Typography>
              </Box>
              <Box className="flex items-center gap-2">
                {socialLinkInfo.isActiveFacebook && <Icon name="check" />}
                <Typography
                  variant="body1"
                  className={
                    socialLinkInfo.isActiveFacebook
                      ? 'text-w-gray-600'
                      : 'text-w-gray-400'
                  }
                >
                  {socialLinkInfo.isActiveFacebook
                    ? '연동 중입니다.'
                    : '연동된 페이스북 계정이 없습니다.'}
                </Typography>
              </Box>
            </Box>
          </Box>
        </WithSkeleton>
        <Divider className="my-10" />
        <MarketingAgreementToggle />
      </Box>
    </>
  );
};

export default withComponentLogger(AccountInfo);
