'use client';

import { useState } from 'react';

import { clientAxios } from '@/shared/api';
import { PasswordChangeConfirmParams } from '@/shared/types';

const usePasswordChangeConfirm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState<boolean | undefined>(undefined);

  const fetchPasswordChange = async (
    passwordChangeConfirmParams: PasswordChangeConfirmParams
  ) => {
    setIsLoading(true);

    try {
      const { data } = await clientAxios.post('/passwordChangeConfirmApi', {
        ...passwordChangeConfirmParams,
      });
      if (data.ok) {
        setIsConfirm(true);
      }
    } catch (e) {
      setIsConfirm(false);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data: isConfirm,
    fetch: fetchPasswordChange,
  };
};

export default usePasswordChangeConfirm;
