import { SystemIcon, Typography } from '@wishket/design-system';
import Link from 'next/link';

const ContactUs = () => {
  return (
    <div className="border-b py-8 grid-in-contact desktop:border-none">
      <div className="mb-1 flex items-center gap-1">
        <SystemIcon
          name="large_service"
          className="text-w-gray-600"
          testId="footer-large-service-icon"
        />
        <Typography variant="body2" className="font-bold text-w-gray-600">
          {'고객 문의'}
        </Typography>
      </div>

      <div className="align-center mb-2 hover:underline">
        <Link href="tel:02-6925-4849">
          <Typography variant="title3" className="font-bold text-w-gray-600">
            {'02-6925-4849'}
          </Typography>
        </Link>
      </div>

      <div className="mb-1 flex items-center gap-1.5">
        <Typography variant="body3" className="text-w-gray-600">
          {'10:00-18:00'}
        </Typography>
        <Typography variant="caption1" className="text-w-gray-400">
          {'주말·공휴일 제외'}
        </Typography>
      </div>

      <Link href="mailto:help@wishket.com" className="hover:underline">
        <Typography variant="body3" className="text-w-gray-600">
          {'help@wishket.com'}
        </Typography>
      </Link>
    </div>
  );
};

export default ContactUs;
