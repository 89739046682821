'use client';

import { Box, TextTab, Typography } from '@wishket/design-system';
import { useSearchParams } from 'next/navigation';

import { WishketJobsServiceTerms, WishketServiceTerms } from '@/features';
import { withComponentLogger } from '@/shared/hocs';

enum Tab {
  WISHKET = 0,
  WISHKET_JOBS = 1,
}

const ServiceTerms = () => {
  const searchParams = useSearchParams();
  const defaultTab =
    searchParams.get('tab') === 'jobs' ? Tab.WISHKET_JOBS : Tab.WISHKET;

  return (
    <TextTab.Root currentActiveTab={defaultTab}>
      <Box className="w-full overflow-hidden">
        <TextTab.Tabs>
          <TextTab.Tab index={Tab.WISHKET}>
            <Typography variant="body1">위시켓 약관</Typography>
          </TextTab.Tab>
          <TextTab.Tab index={Tab.WISHKET_JOBS}>
            <Typography variant="body1">위시켓 잡스 약관</Typography>
          </TextTab.Tab>
        </TextTab.Tabs>
      </Box>
      <TextTab.Panel index={Tab.WISHKET}>
        <WishketServiceTerms />
      </TextTab.Panel>
      <TextTab.Panel index={Tab.WISHKET_JOBS}>
        <WishketJobsServiceTerms />
      </TextTab.Panel>
    </TextTab.Root>
  );
};

export default withComponentLogger(ServiceTerms);
