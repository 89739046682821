'use client';

import usePasswordReset from './usePasswordReset';

import { useThrottle } from '@/shared/hooks';
import { PasswordResetComplete, PasswordResetForm } from '@/features';
import { withComponentLogger } from '@/shared/hocs';

const PasswordReset = () => {
  const {
    isSignUp,
    agreement,
    isLoading,
    inputValue,
    isSubmitted,
    passwordError,
    agreementError,
    onSubmit,
    onAgreementChange,
    onPasswordChange,
  } = usePasswordReset();

  const throttledSubmit = useThrottle(onSubmit);
  return (
    <>
      {isSubmitted ? (
        <PasswordResetComplete />
      ) : (
        <PasswordResetForm
          isSignUp={isSignUp}
          isLoading={isLoading}
          agreement={agreement}
          inputValue={inputValue}
          errorStatus={passwordError}
          agreementError={agreementError}
          onSubmit={throttledSubmit}
          onAgreementChange={onAgreementChange}
          onPasswordChange={onPasswordChange}
        />
      )}
    </>
  );
};

export default withComponentLogger(PasswordReset);
