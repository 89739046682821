import { Box, ServiceLogo, Typography } from '@wishket/design-system';

import Link from 'next/link';

import { BetaServiceBadge } from '../WithdrawalRequest/WithdrawalRequest.parts';

import { PATH } from '@/shared/constants';

interface EmailUnsubscribeFailProps {
  flagType: string;
  flagInfo: string;
}

const EmailUnsubscribeSuccess = ({
  flagType,
  flagInfo,
}: EmailUnsubscribeFailProps) => {
  return (
    <Box className="flex w-full flex-col gap-8 desktop:w-[720px]">
      <Typography variant="title3" className="text-w-gray-900">
        수신 거부 설정 완료
      </Typography>
      <Box className="w-full">
        <Box className="flex w-full border-b p-3">
          <Box className="w-1/2">
            <Typography variant="body3" className="text-w-gray-900">
              구분
            </Typography>
          </Box>
          <Box className="w-1/2">
            <Typography variant="body3" className="w-1/2 text-w-gray-900">
              메일 내용
            </Typography>
          </Box>
        </Box>
        <Box className="flex w-full border-b p-3">
          <Box className="w-1/2">
            <Typography variant="body3" className="w-1/2 text-w-gray-600">
              {flagType}
            </Typography>
          </Box>
          <Box className="w-1/2">
            <Typography variant="body3" className="w-1/2 text-w-gray-600">
              {flagInfo}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className="flex flex-col gap-3">
        <Box className="flex items-start gap-2">
          <Box className="mt-3 flex items-center justify-center">
            <div className="h-0.5 w-0.5 shrink-0 rounded-full bg-w-gray-600" />
          </Box>
          <Typography variant="body2" className="text-w-gray-600">
            해당하는 이메일의 수신 거부 설정이 완료되었습니다.
          </Typography>
        </Box>
        <Box className="flex flex-col gap-2">
          <Box className="flex items-start gap-2">
            <Box className="mt-3 flex items-center justify-center">
              <div className="h-0.5 w-0.5 shrink-0 rounded-full bg-w-gray-600" />
            </Box>
            <Typography variant="body2" className="text-w-gray-600">
              서비스로 이동하기
            </Typography>
          </Box>
          <Box className="flex justify-center gap-4 rounded-xl bg-w-gray-10 py-6">
            <Link href={PATH.WISHKET}>
              <Box className="flex justify-center gap-0.5">
                <ServiceLogo name="wishket_symbol" />
                <Typography variant="body2" className="text-w-gray-900">
                  위시켓
                </Typography>
              </Box>
            </Link>
            <Link href={PATH.JOBS}>
              <Box className="flex justify-center gap-0.5">
                <ServiceLogo name="jobs_symbol" />
                <Typography variant="body2" className="text-w-gray-900">
                  위시켓잡스
                </Typography>
                <BetaServiceBadge className="pt-0.5" />
              </Box>
            </Link>
            <Link href={PATH.YOZMIT}>
              <Box className="flex justify-center gap-0.5">
                <ServiceLogo name="yozmIT_symbol" />
                <Typography variant="body2" className="text-w-gray-900">
                  요즘IT
                </Typography>
              </Box>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EmailUnsubscribeSuccess;
