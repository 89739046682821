'use client';

import { LoadingIndicator } from '@wishket/design-system';
import { useEffect } from 'react';

import useSocialSignUpWidget from './useSocialSignUpWidget';

import { SocialSignUpForm } from '@/features';
import { useSocialAuth } from '@/shared/providers';
import { withComponentLogger } from '@/shared/hocs';

const SocialSignUp = () => {
  const {
    inputValue,
    inputError,
    handleChange,
    onSubmit,
    isLoading,
    hasAccess,
  } = useSocialSignUpWidget();
  const { providerStatus, resetSocialAuthData } = useSocialAuth();

  useEffect(() => {
    resetSocialAuthData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading && <LoadingIndicator size="lg" isFixed />}
      {hasAccess && providerStatus.get && (
        <SocialSignUpForm
          provider={providerStatus.get}
          inputValue={inputValue}
          inputError={inputError}
          handleChange={handleChange}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

export default withComponentLogger(SocialSignUp);
