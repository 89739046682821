import { Box, Typography } from '@wishket/design-system';
import React, { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

interface PropsWithChildrenAndClassName extends PropsWithChildren {
  className?: string;
}

export const Title = ({
  children,
  id,
}: PropsWithChildren & { id?: string }) => {
  return (
    <Typography
      id={id}
      variant="subTitle1"
      className="font-normal text-w-gray-900"
    >
      {children}
    </Typography>
  );
};

export const SubTitle = ({ children }: PropsWithChildren) => {
  return (
    <Typography variant="body1" className="font-medium text-w-gray-900">
      {children}
    </Typography>
  );
};

export const DescriptionLink = ({
  children,
  href,
  isNewWindow = false,
}: PropsWithChildren & { href: string; isNewWindow?: boolean }) => {
  return (
    <Typography
      variant="body1"
      className="cursor-pointer font-medium text-w-primary"
    >
      <a
        href={href}
        className="no-underline"
        target={isNewWindow ? '_blank' : '_self'}
        rel="noreferrer"
      >
        {children}
      </a>
    </Typography>
  );
};

export const Description = ({
  children,
  className,
}: PropsWithChildrenAndClassName) => {
  return (
    <Typography
      variant="body1"
      className={twMerge(
        'break-all text-justify font-normal text-w-gray-600',
        className
      )}
    >
      {children}
    </Typography>
  );
};

export const OrderedList = ({
  children,
  className,
}: PropsWithChildrenAndClassName) => {
  return (
    <ol
      className={twMerge(
        'box-border flex w-full list-outside list-decimal flex-col gap-1 py-1.5 pl-6',
        className
      )}
    >
      {children}
    </ol>
  );
};

export const UnOrderedList = ({
  children,
  className,
}: PropsWithChildrenAndClassName) => {
  return (
    <ul
      className={twMerge(
        'box-border flex w-full list-outside list-none flex-col gap-1 py-1.5',
        className
      )}
    >
      {children}
    </ul>
  );
};

export const BulletList: React.FC<PropsWithChildrenAndClassName> = ({
  children,
  className,
}) => {
  return (
    <li className={twMerge('flex flex-row gap-2', className)}>
      <span className="pt-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2"
          height="2"
          viewBox="0 0 2 2"
          fill="none"
        >
          <circle cx="1" cy="1" r="1" fill="#616161" />
        </svg>
      </span>
      <span className="w-full">{children}</span>
    </li>
  );
};

export const UnStyledList = ({
  children,
  className,
}: PropsWithChildrenAndClassName) => {
  return (
    <ol
      className={twMerge(
        'box-border flex w-full list-outside list-none flex-col gap-1 py-1',
        className
      )}
    >
      {children}
    </ol>
  );
};

export const CustomOrderLi = ({
  children,
  className,
  orderNumber,
}: PropsWithChildren & { className?: string; orderNumber?: number }) => {
  return (
    <li className={twMerge('flex flex-row gap-2', className)}>
      <span className="flex w-8">{`(${orderNumber})`}</span>
      <span className="w-full">{children}</span>
    </li>
  );
};

export const InformationBox = ({
  children,
  className,
}: PropsWithChildrenAndClassName) => {
  return (
    <Box
      className={twMerge(
        'my-6 break-keep rounded-lg border-[1px] border-w-gray-200 p-6 text-left leading-relaxed',
        className
      )}
    >
      {children}
    </Box>
  );
};

export const TableHead = ({ children }: PropsWithChildren) => {
  return (
    <thead className="text-[14px] font-medium leading-6 text-w-gray-900">
      <tr className="border-b border-w-gray-900 py-3 align-text-top text-w-gray-900">
        {children}
      </tr>
    </thead>
  );
};

export const TableBody = ({ children }: PropsWithChildren) => {
  return (
    <tbody className="divide-y divide-w-gray-200 border-b border-w-gray-200 align-text-top text-[16px] font-normal leading-[26px] text-w-gray-600">
      {children}
    </tbody>
  );
};
