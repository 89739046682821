'use client';

import { useState } from 'react';
import { useRouter } from 'next/navigation';
import { isAxiosError } from 'axios';

import { clientAxios } from '@/shared/api';
import { ClientAuth } from '@/shared/auth';
import { PATH } from '@/shared/constants';
import { notifyToast } from '@/shared/utils';
import { useNextUrl } from '@/shared/hooks';

const useActivateAccount = () => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(true);
  const [isExpired, setIsExpired] = useState(false);
  const [isFetched, setIsFetched] = useState(false);

  const { withNextUrl } = useNextUrl();

  const getRedirectUrl = (signupService: string) => {
    switch (signupService) {
      case 'wishket-member':
        return PATH.HOME;
      case 'wishket':
        return PATH.WISHKET;
      case 'yozmit':
        return PATH.YOZMIT;
      case 'superstack':
        return PATH.JOBS;
      default:
        return PATH.HOME;
    }
  };

  const fetchActivateAccount = async (key: string) => {
    if (isFetched) return;

    try {
      setIsFetched(true);
      const { data } = await clientAxios.get(`/activateAccountApi?key=${key}`);

      if (data.ok) {
        const { access, refresh, signup_service } = data.data;

        ClientAuth.authenticate(access, refresh);
        router.replace(getRedirectUrl(signup_service));
      }
    } catch (e) {
      if (isAxiosError(e) && e.response) {
        const {
          error: { data },
        } = e.response.data;
        if (
          data.msg ===
          '만료된 인증키입니다. 인증기한이 만료되었으니 고객센터로 문의해주세요.'
        ) {
          setIsExpired(true);
          notifyToast({
            type: 'error',
            message: data.msg,
          });
        } else if (
          data.msg ===
          '해당 키값에 해당하는 인증 계정이 존재하지 않습니다. 인증키 값을 확인해주세요.'
        ) {
          await ClientAuth.deAuthenticate();

          notifyToast({
            type: 'error',
            message: data.msg,
          });
          router.replace(withNextUrl(PATH.LOGIN));
        } else {
          await ClientAuth.deAuthenticate();

          notifyToast({
            type: 'error',
            message: '이메일 인증에 실패했습니다. 다시 시도해주세요.',
          });
          router.replace(withNextUrl(PATH.LOGIN));
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isExpired,
    isLoading,
    fetch: fetchActivateAccount,
  };
};

export default useActivateAccount;
