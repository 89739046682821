import { SignUpApiParams, SocialSignUpApiParams } from '@/shared/types';
import { serverAxios, clientAxios } from '@/shared/api';
import { generateProviderBackendName } from '@/shared/utils';

export const signUpApi = async ({
  signup_service,
  username,
  email,
  password1,
  password2,
  privacyInfo,
  privacyMarketing,
  receiveMarketingInfo,
}: SignUpApiParams) => {
  return await serverAxios.post('/member/signup/', {
    signup_service,
    username,
    email,
    password1,
    password2,
    privacy_info_consent: privacyInfo,
    privacy_marketing_consent: privacyMarketing,
    receive_marketing_info_consent: receiveMarketingInfo,
  });
};

export const resendEmailVerifyApi = async (email: string) => {
  return await serverAxios.post('/member/resend-activation/', {
    msg: email,
  });
};

export const activateAccountApi = async ({ key }: { key: string }) => {
  return await clientAxios.get(
    `${process.env.NEXT_PUBLIC_APP_BASE_API_URL}/member/activate/${key}/`
  );
};

export const socialSignUpApi = async ({
  signup_service,
  username,
  password1,
  password2,
  accessToken,
  provider,
  socialAccountId,
  privacyInfo,
  privacyMarketing,
  receiveMarketingInfo,
}: SocialSignUpApiParams) => {
  const backendName = generateProviderBackendName(provider);

  return await clientAxios.post(
    `${process.env.NEXT_PUBLIC_APP_BASE_API_URL}/member/social/signup/`,
    {
      signup_service,
      username,
      password1,
      password2,
      access_token: accessToken,
      backend_name: backendName,
      social_account_id: socialAccountId,
      privacy_info_consent: privacyInfo,
      privacy_marketing_consent: privacyMarketing,
      receive_marketing_info_consent: receiveMarketingInfo,
    }
  );
};
