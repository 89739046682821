'use client';

import {
  Box,
  Button,
  LoadingIndicator,
  Typography,
} from '@wishket/design-system';

import { useLogout } from '@/entities/logout';
import { useThrottle } from '@/shared/hooks';
import { withComponentLogger } from '@/shared/hocs';

interface LogoutButtonProps {
  isButton?: boolean;
}

const LogoutButton = ({ isButton }: LogoutButtonProps) => {
  const { fetch: fetchLogout, isLoading: isLogoutLoading } = useLogout();
  const throttledFetchLogout = useThrottle(fetchLogout);

  return (
    <>
      {isLogoutLoading && !isButton && (
        <Box className="fixed left-1/2 top-1/2">
          <LoadingIndicator size="lg" />
        </Box>
      )}
      {isButton === true ? (
        <Box className="flex flex-col gap-6 bg-w-gray-50 px-5 pb-px pt-6 desktop:hidden">
          {isLogoutLoading && <LoadingIndicator size="lg" isFixed />}
          <Button className="w-full" onClick={fetchLogout} needThrottle>
            로그아웃
          </Button>
        </Box>
      ) : (
        <Box className="hidden items-center justify-end grid-in-action desktop:flex">
          <Box className="mx-5 h-[14px] w-px bg-w-gray-200" />
          <Typography
            variant="body2"
            className="cursor-pointer text-w-gray-600"
            onClick={throttledFetchLogout}
          >
            로그아웃
          </Typography>
        </Box>
      )}
    </>
  );
};

export default withComponentLogger(LogoutButton);
