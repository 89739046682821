'use client';

import { ChangeEvent, FormEvent, useEffect, useState } from 'react';

import { useInput } from '@/shared/hooks';
import { validateInput } from '@/shared/utils';
import { LoadingDialog } from '@/shared/Components';
import { SignUpInputValueType } from '@/shared/types';
import { useSendEmailPasswordChangeApi } from '@/entities/email';
import { PasswordFindComplete, PasswordFindForm } from '@/features';
import { withComponentLogger } from '@/shared/hocs';

const PasswordFind = () => {
  const [emailError, setEmailError] = useState({
    email: { valid: true, errorMessage: '' },
  });
  const { inputValue, handleTrimmedInputChange } = useInput<
    Pick<SignUpInputValueType, 'email'>
  >({ email: '' });

  const {
    isSuccess,
    isError,
    message: errorMessage,
    isLoading: isSendEmailLoading,
    resetData: resetSendEmailData,
    fetch: fetchSendEmailPasswordChange,
  } = useSendEmailPasswordChangeApi();

  useEffect(() => {
    if (isError) {
      setEmailError({
        email: {
          valid: false,
          errorMessage: errorMessage,
        },
      });
    }
  }, [isError, errorMessage, isSendEmailLoading]);

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const { email: validateEmail } = validateInput;

    const validation = validateEmail(value);

    if (!validation?.valid) {
      setEmailError({ ...emailError, [name]: validation });
    } else {
      setEmailError({
        ...emailError,
        [name]: { valid: true, errorMessage: '' },
      });
    }

    handleTrimmedInputChange(e);
  };

  const handleEmailSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!inputValue.email) {
      setEmailError({
        ...emailError,
        email: { valid: false, errorMessage: '이메일을 입력해 주세요.' },
      });
      return;
    }
    if (!emailError.email.valid || !inputValue.email) {
      return;
    }

    fetchSendEmailPasswordChange({ email: inputValue.email });
  };

  const resetEmailRequest = () => {
    resetSendEmailData();
  };

  return (
    <>
      {isSendEmailLoading && (
        <LoadingDialog description="비밀번호 재설정 메일을 전송 중입니다." />
      )}
      {isSuccess ? (
        <PasswordFindComplete
          email={inputValue.email}
          reset={resetEmailRequest}
        />
      ) : (
        <PasswordFindForm
          inputValue={inputValue}
          inputError={emailError}
          handleChange={handleEmailChange}
          handleSubmit={handleEmailSubmit}
        />
      )}
    </>
  );
};

export default withComponentLogger(PasswordFind);
