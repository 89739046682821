'use client';

import { signOut } from 'next-auth/react';
import { useState } from 'react';
import { isAxiosError } from 'axios';

import { SocialSignUpApiParams } from '@/shared/types';
import { clientAxios } from '@/shared/api';
import { ClientAuth } from '@/shared/auth';
import { useSocialAuth } from '@/shared/providers';

interface SocialSignUpError {
  id?: string;
}

const SOCIAL_SIGNUP_ERROR_MESSAGES = [
  'This username is already taken.',
  'This username is already taken but not confirmed. Please check your email for verification steps.',
];

const useSocialSignUp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<SocialSignUpError | undefined>(undefined);
  const [isSigned, setIsSigned] = useState<boolean | undefined>(undefined);
  const { socialAccessTokenStatus } = useSocialAuth();

  const resetSignStatus = () => {
    setIsSigned(undefined);
  };

  const fetchSocialSignUp = async ({
    signup_service,
    username,
    password1,
    password2,
    accessToken,
    provider,
    socialAccountId,
    privacyInfo,
    privacyMarketing,
    receiveMarketingInfo,
  }: SocialSignUpApiParams) => {
    setIsLoading(true);

    if (!socialAccessTokenStatus.get) return;

    try {
      const { data } = await clientAxios.post('/socialSignUpApi', {
        signup_service,
        username,
        password1,
        password2,
        accessToken,
        provider,
        socialAccountId,
        privacyInfo,
        privacyMarketing,
        receiveMarketingInfo,
      });

      if (data.ok) {
        const { access, refresh } = data.data;
        ClientAuth.authenticate(access, refresh);
        setIsSigned(true);
      }
    } catch (e) {
      setIsSigned(false);
      if (isAxiosError(e) && e.response) {
        const {
          error: { status, data },
        } = e.response.data;
        if (status === 400) {
          if (data?.msg === 'Already signed up') {
            setError({ id: '이미 가입된 계정입니다.' });
          } else if (
            SOCIAL_SIGNUP_ERROR_MESSAGES.some(
              (msg) => msg === data?.username[0]
            )
          ) {
            setError({
              id: '입력한 아이디는 이미 사용 중입니다. 다른 아이디를 입력해 주세요.',
            });
          }
        }
      }
    } finally {
      setIsLoading(false);
      signOut({ redirect: false });
    }
  };

  return {
    fetch: fetchSocialSignUp,
    reset: resetSignStatus,
    isLoading,
    error,
    isSigned,
  };
};

export default useSocialSignUp;
