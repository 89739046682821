export const externalLinkList = [
  {
    title: '이메일 · 문자 수신이 불편하신가요?',
    url: 'https://www.wishket.com/customer-support/10/40/314',
  },
  {
    title: '이메일 변경이 필요하신가요?',
    url: 'https://www.wishket.com/customer-support/10/42/144',
  },
];
