'use client';

import { useRouter, useSearchParams } from 'next/navigation';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';

import { useInput, useNextUrl } from '@/shared/hooks';
import { notifyToast, validateInput } from '@/shared/utils';
import { SignUpInputValueType } from '@/shared/types';
import {
  usePasswordChangeConfirm,
  usePasswordChange,
} from '@/entities/password';
import { PATH } from '@/shared/constants';

const usePasswordReset = () => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const [agreement, setAgreement] = useState(false);
  const [agreementError, setAgreementError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [passwordError, setPasswordError] = useState({
    password: { valid: true, errorMessage: '' },
    passwordConfirm: { valid: true, errorMessage: '' },
  });

  const { withNextUrl } = useNextUrl();
  const { inputValue, handleTrimmedInputChange } = useInput<
    Pick<SignUpInputValueType, 'password' | 'passwordConfirm'>
  >({
    password: '',
    passwordConfirm: '',
  });

  const { data: isConfirm, fetch } = usePasswordChangeConfirm();
  const {
    data: isPasswordChange,
    fetch: fetchPasswordChange,
    isLoading: isPasswordChangeLoading,
  } = usePasswordChange();

  const {
    password: validatePassword,
    passwordConfirm: validatePasswordConfirm,
  } = validateInput;
  const uid = searchParams.get('uid') || '';
  const token = searchParams.get('token') || '';
  const isSignUp = searchParams.get('isSignUp') === 'true';

  useEffect(() => {
    if (uid && token) {
      fetch({ uid, token });
    } else {
      notifyToast({
        type: 'error',
        message: '비밀번호 재설정이 실패했습니다. 다시 시도해주세요.',
      });
      router.push(withNextUrl(PATH.PASSWORD_FIND));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, token]);

  useEffect(() => {
    if (isConfirm === false) {
      notifyToast({
        type: 'error',
        message: '비밀번호 재설정이 실패했습니다. 다시 시도해주세요.',
      });
      router.push(withNextUrl(PATH.PASSWORD_FIND));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirm]);

  useEffect(() => {
    if (isPasswordChange) {
      setIsSubmitted(true);
    } else if (isPasswordChange === false) {
      notifyToast({
        type: 'error',
        message: '비밀번호 변경에 실패하였습니다.',
      });
      setPasswordError({
        password: {
          valid: false,
          errorMessage: '비밀번호를 다시 한 번 확인해 주세요.',
        },
        passwordConfirm: {
          valid: false,
          errorMessage: '비밀번호를 다시 한 번 확인해 주세요.',
        },
      });
    }
  }, [isPasswordChange]);

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, maxLength } = e.target;
    const isPassword = name === 'password';
    const incomingValue = value.trim();
    if (incomingValue.length === maxLength) {
      return setPasswordError({
        ...passwordError,
        [name]: { valid: false, errorMessage: '글자수를 초과하였습니다.' },
      });
    }
    const validation =
      name === 'passwordConfirm'
        ? validatePasswordConfirm(incomingValue, inputValue.password.trim())
        : validatePassword(incomingValue);

    handleTrimmedInputChange(e);
    if (isPassword) {
      const passwordConfirmValidation =
        isPassword && inputValue.passwordConfirm !== ''
          ? validateInput.passwordConfirm(
              inputValue.passwordConfirm.trim(),
              incomingValue as string
            )
          : passwordError.passwordConfirm;
      return setPasswordError({
        ...passwordError,
        [name]: validation,
        passwordConfirm: passwordConfirmValidation,
      });
    }

    if (!validation?.valid) {
      setPasswordError({ ...passwordError, [name]: validation });
    } else {
      setPasswordError({
        ...passwordError,
        [name]: { valid: true, errorMessage: '' },
      });
    }
  };

  const onAgreementChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAgreement(e.target.checked);
    setAgreementError(e.target.checked ? false : true);
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const passwordValidationCheck = validatePassword(inputValue.password);
    const passwordConfirmValidationCheck = validatePasswordConfirm(
      inputValue.passwordConfirm,
      inputValue.password
    );

    if (isSignUp && !agreement) {
      setAgreementError(true);

      return;
    }

    setPasswordError({
      ...passwordError,
      password: passwordValidationCheck,
      passwordConfirm: passwordConfirmValidationCheck,
    });

    if (passwordValidationCheck.valid && passwordConfirmValidationCheck.valid) {
      fetchPasswordChange({
        uid: uid,
        token: token,
        password1: inputValue.password.trim(),
        password2: inputValue.passwordConfirm.trim(),
      });
    }
  };

  return {
    isSignUp,
    agreement,
    inputValue,
    isSubmitted,
    passwordError,
    agreementError,
    isLoading: isPasswordChangeLoading,
    onSubmit,
    onAgreementChange,
    onPasswordChange,
  };
};

export default usePasswordReset;
