'use client';

import { Box, GridLayout } from '@wishket/design-system';
import { twMerge } from 'tailwind-merge';
import { usePathname } from 'next/navigation';

import LogoutButton from '../LogoutButton';

import { pageTitleMapper } from './GNB.constants';

import { TopBar, ServiceMenu } from '@/features';
import { useProfile } from '@/entities/profile';
import { PATH } from '@/shared/constants';
import { useSocialAuth } from '@/shared/providers';
import { withComponentLogger } from '@/shared/hocs';

interface GnbProps {
  isAuthorized?: boolean;
}

const GNB = ({ isAuthorized = false }: GnbProps) => {
  const pathName = usePathname();
  const { providerStatus } = useSocialAuth();
  const targetPathname =
    (pathName === PATH.SIGNUP_SOCIAL && providerStatus.get) || pathName;

  const pageTitle =
    pageTitleMapper.get(targetPathname) || pageTitleMapper.get('default');
  const isAvailable = pageTitle === pageTitleMapper.get('default');
  const { is_verification_required: isVerificationRequired } = useProfile();

  return (
    <Box className="left-0 right-0 top-0 z-20 border-b bg-w-white">
      <GridLayout
        gridTemplateAreaClass={twMerge(
          `grid-areas-topBar-mobile bg-white py-4`,
          `tablet:grid-areas-topBar-tablet`,
          `desktop:grid-areas-topBar-desktop`
        )}
      >
        <TopBar
          pageTitle={pageTitle as string}
          isAvailable={isAvailable}
          path={PATH.HOME}
        />
        <Box className="hidden items-center justify-end whitespace-nowrap grid-in-action desktop:flex">
          <ServiceMenu isEmailVerified={!isVerificationRequired} />
          {isAuthorized && <LogoutButton isButton={false} />}
        </Box>
      </GridLayout>
    </Box>
  );
};

export default withComponentLogger(GNB);
