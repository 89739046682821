import { Box, Button, Typography } from '@wishket/design-system';

import { Dialog } from '@/shared/Components';

interface ProjectResetNotificationDialogProps {
  onClose: () => void;
  onSuccess: () => void;
}

const ProjectResetNotificationDialog = ({
  onClose,
  onSuccess,
}: ProjectResetNotificationDialogProps) => {
  return (
    <Dialog className="w-[320px] rounded-2xl bg-w-white shadow-graymedium tablet:w-[400px]">
      <Box className="p-8">
        <Box className="mb-6">
          <Typography
            variant="subTitle2"
            className="font-medium text-w-gray-900"
          >
            파트너로 로그인
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1" className="text-w-gray-700">
            프로젝트 등록은 클라이언트 계정으로만 가능합니다. 파트너로 로그인 시
            작성한 프로젝트 내용이 삭제됩니다.
          </Typography>
        </Box>
        <Box className="mb-8 mt-4">
          <Typography variant="body1" className="text-w-gray-700">
            파트너 계정으로 로그인하시겠습니까?
          </Typography>
        </Box>
        <Box className="flex items-center justify-center gap-4">
          <Button
            className="w-full border-w-bluegray-200 bg-w-bluegray-200 hover:border-w-bluegray-300 hover:bg-w-bluegray-300"
            variant="solid"
            onClick={onClose}
          >
            취소
          </Button>
          <Button
            className="w-full border-w-orange-500 bg-w-orange-500 hover:border-w-orange-600 hover:bg-w-orange-600"
            variant="solid"
            onClick={onSuccess}
            needThrottle
          >
            로그인
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ProjectResetNotificationDialog;
