'use client';

import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { Box, Button, Typography } from '@wishket/design-system';

import { PATH } from '@/shared/constants';
import { Icon, LoadingDialog } from '@/shared/Components';
import { useProfile } from '@/entities/profile';
import { useVerifyEmailResend } from '@/entities/signUp';
import { useNextUrl } from '@/shared/hooks';
import { withComponentLogger } from '@/shared/hocs';

const EmailVerify = () => {
  const router = useRouter();
  const { email, is_verification_required } = useProfile();
  const { fetch, reset, data, isLoading } = useVerifyEmailResend();
  const { withNextUrl } = useNextUrl();

  useEffect(() => {
    if (!is_verification_required) router.replace(withNextUrl(PATH.HOME));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_verification_required]);

  const clickResendButton = async () => {
    if (isLoading) return;

    if (data) reset();

    await fetch(email);
  };

  return (
    <>
      {isLoading && (
        <LoadingDialog description="인증 메일을 재발송 중입니다." />
      )}
      <Box
        className="flex flex-col items-center gap-8 rounded-2xl desktop:w-[558px] desktop:p-8 desktop:shadow-contents-container"
        data-testid="email-verify"
      >
        <Box className="flex flex-col items-center gap-6">
          <Icon name="mail_send" />
          <Box className="flex w-full flex-col gap-3 text-center">
            <Typography
              variant="subTitle2"
              className="font-medium text-w-gray-900"
            >
              인증 메일이 발송되었어요!
            </Typography>
            <Typography
              variant="body2"
              className="whitespace-break-spaces break-keep text-w-gray-700"
            >
              {`${email}로 인증 메일이 발송되었습니다. \n이메일 인증 후 정상적인 서비스 이용이 가능합니다.`}
            </Typography>
          </Box>
        </Box>

        <Box className="flex w-full flex-col gap-1.5 rounded-2xl bg-w-gray-10 px-6 py-5">
          <Typography variant="body2" className="font-medium text-w-gray-600">
            인증 메일을 받지 못하셨나요?
          </Typography>
          <Typography variant="body2" className="text-w-gray-600">
            7일 이내에 이메일의 링크를 클릭해 주시면 인증이 완료됩니다.
          </Typography>
          <Typography variant="body2" className="text-w-gray-600">
            메일을 받지 못했다면 스팸함을 확인해 주세요.
          </Typography>
        </Box>
        <Button
          variant="solid"
          size="md"
          className="w-full"
          onClick={clickResendButton}
          needThrottle
        >
          인증 메일 다시 받기
        </Button>
      </Box>
    </>
  );
};

export default withComponentLogger(EmailVerify);
