'use client';

import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';

import {
  LoginMessageEnum,
  SocialLoginProvider,
  SocialSignUpInputErrorType,
  SocialSignUpInputValueType,
} from '@/shared/types';
import { useSocialLogin } from '@/entities/login';
import { useSocialSignUp } from '@/entities/signUp';
import { notifyToast, validateInput } from '@/shared/utils';
import { useAuthCompletion, useNextUrl } from '@/shared/hooks';
import { PATH } from '@/shared/constants';
import { useSocialAuth } from '@/shared/providers';

const INIT_INPUT_VALUE = {
  id: '',
  password: '',
  passwordConfirm: '',
  policyAgreement: false,
  marketingUseAgreement: false,
  marketingSubscribeAgreement: false,
};

const INIT_INPUT_ERROR = {
  id: { valid: true, errorMessage: '' },
  password: { valid: true, errorMessage: '' },
  passwordConfirm: { valid: true, errorMessage: '' },
  policyAgreement: { valid: true, errorMessage: '' },
  marketingUseAgreement: { valid: true, errorMessage: '' },
  marketingSubscribeAgreement: { valid: true, errorMessage: '' },
};

interface SocialAccessResponseData {
  providerAccessToken: string | null;
  provider: SocialLoginProvider | null;
  msg: LoginMessageEnum | null;
  socialAccountId: number | null;
}

const useSocialSignUpWidget = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [socialAccessData, setSocialAccessData] =
    useState<SocialAccessResponseData>({
      providerAccessToken: null,
      provider: null,
      msg: null,
      socialAccountId: null,
    });
  const [inputValue, setInputValue] =
    useState<SocialSignUpInputValueType>(INIT_INPUT_VALUE);
  const [inputError, setInputError] =
    useState<SocialSignUpInputErrorType>(INIT_INPUT_ERROR);

  const { referrerService, redirectAfterSignup, redirectNext } =
    useAuthCompletion();
  const { withNextUrl } = useNextUrl();

  const { data, isLogin, isLoading: isSocialLoginLoading } = useSocialLogin();
  const {
    fetch: fetchSocialSignUp,
    isLoading: isSocialSignUpLoading,
    error: socialSignUpError,
    isSigned,
    reset,
  } = useSocialSignUp();
  const { providerStatus } = useSocialAuth();

  const router = useRouter();

  const onChangeId = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const isOverLength = value.length > 20;
    const incomingValue = value.trim();

    if (isOverLength) {
      setInputError({
        ...inputError,
        id: {
          valid: false,
          errorMessage: '20글자 이하로 입력해 주세요',
        },
      });

      return;
    }
    const validation = validateInput.id(incomingValue);
    setInputError({ ...inputError, id: validation });
    setInputValue({ ...inputValue, id: incomingValue });
  };

  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const incomingValue = value.trim();
    const passwordValidation = validateInput.password(incomingValue);
    const passwordConfirmValidation =
      inputValue.passwordConfirm !== ''
        ? validateInput.passwordConfirm(
            inputValue.passwordConfirm.trim(),
            incomingValue
          )
        : inputError.passwordConfirm;
    setInputError({
      ...inputError,
      password: passwordValidation,
      passwordConfirm: passwordConfirmValidation,
    });
    setInputValue({ ...inputValue, password: incomingValue });
  };

  const onChangePasswordConfirm = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const incomingValue = value.trim();
    const validation = validateInput.passwordConfirm(
      incomingValue,
      inputValue.password.trim()
    );
    setInputError({
      ...inputError,
      passwordConfirm: validation,
    });
    setInputValue({ ...inputValue, passwordConfirm: incomingValue });
  };

  const changeAllAgreementChange = (checked: boolean) => {
    setInputValue({
      ...inputValue,
      policyAgreement: checked,
      marketingUseAgreement: checked,
      marketingSubscribeAgreement: checked,
    });

    const validation = validateInput.policyAgreement(checked);

    setInputError({ ...inputError, policyAgreement: validation });
  };

  const changeMarketingUseAgreement = (checked: boolean) => {
    setInputValue({
      ...inputValue,
      marketingUseAgreement: checked,
      marketingSubscribeAgreement: checked,
    });
  };

  const changeMarketingSubscribeAgreement = (checked: boolean) => {
    if (checked) {
      setInputValue({
        ...inputValue,
        marketingUseAgreement: checked,
        marketingSubscribeAgreement: checked,
      });
    } else {
      setInputValue({ ...inputValue, marketingSubscribeAgreement: checked });
    }
  };

  const changePolicyAgreement = (checked: boolean) => {
    setInputValue({ ...inputValue, policyAgreement: checked });

    const validation = validateInput.policyAgreement(checked);

    if (!validation?.valid) {
      setInputError({ ...inputError, policyAgreement: validation });
    } else {
      setInputError({
        ...inputError,
        policyAgreement: { valid: true, errorMessage: '' },
      });
    }
  };

  const onChangeAgreement = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    const agreementHandlers: Record<string, (checked: boolean) => void> = {
      allAgreement: changeAllAgreementChange,
      marketingUseAgreement: changeMarketingUseAgreement,
      marketingSubscribeAgreement: changeMarketingSubscribeAgreement,
      policyAgreement: changePolicyAgreement,
    };

    const handleChange = agreementHandlers[name];

    if (handleChange) handleChange(checked);
  };

  const resetValue = () => {
    setInputValue(INIT_INPUT_VALUE);
    setInputError(INIT_INPUT_ERROR);
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isLoading || isSocialSignUpLoading) return;

    const validationCheck = {
      id: validateInput.id(inputValue.id),
      password: validateInput.password(inputValue.password),
      passwordConfirm: validateInput.passwordConfirm(
        inputValue.passwordConfirm.trim(),
        inputValue.password.trim()
      ),
      policyAgreement: validateInput.policyAgreement(
        inputValue.policyAgreement
      ),
    };

    setInputError(validationCheck);

    if (
      validationCheck.id.valid &&
      validationCheck.password.valid &&
      validationCheck.passwordConfirm.valid &&
      inputValue.policyAgreement
    ) {
      fetchSignUp();
    }
  };

  const fetchSignUp = async () => {
    await fetchSocialSignUp({
      signup_service: referrerService,
      username: inputValue.id,
      password1: inputValue.password,
      password2: inputValue.passwordConfirm,
      accessToken: socialAccessData.providerAccessToken as string,
      provider: socialAccessData.provider as SocialLoginProvider,
      socialAccountId: socialAccessData.socialAccountId as number,
      privacyInfo: inputValue.policyAgreement,
      privacyMarketing: inputValue.marketingUseAgreement,
      receiveMarketingInfo: inputValue.marketingSubscribeAgreement,
    });
  };

  const handleSignUpError = () => {
    if (socialSignUpError) {
      setInputError({
        ...inputError,
        id: {
          valid: !socialSignUpError.id,
          errorMessage: socialSignUpError.id || '',
        },
      });
    } else {
      notifyToast({ type: 'error', message: '회원가입에 실패하였습니다.' });
    }
  };

  useEffect(() => {
    if (isSigned === undefined || isLoading) return;

    if (isSigned) {
      redirectAfterSignup({ isSocialSignUp: true });
      notifyToast({ message: '회원가입에 성공했습니다.' });
    } else handleSignUpError();

    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSigned, isLoading]);

  // `/signup/social` 접속 가능 조건
  // 1. Provider 로그인 상태의 경우
  // 2. Provider access가 `need_signup` 상태인 경우
  useEffect(() => {
    const validSignIn = async () => {
      if (isLogin === false) return router.replace(withNextUrl(PATH.LOGIN));

      if (isLogin === undefined || isSocialLoginLoading || !data) return;

      const { providerAccessToken, msg, social_account_id } = data;
      const isNeedSignUp = msg === LoginMessageEnum.NEED_SIGNUP;

      if (isNeedSignUp) {
        setSocialAccessData({
          providerAccessToken,
          provider: providerStatus.get,
          msg: msg as LoginMessageEnum,
          socialAccountId: social_account_id as number,
        });
      }

      redirectNext(msg as LoginMessageEnum);
    };

    setIsLoading(true);
    validSignIn().finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin, isSocialLoginLoading, data, providerStatus.get]);

  const hasAccess =
    !!socialAccessData && socialAccessData.msg === LoginMessageEnum.NEED_SIGNUP;

  return {
    inputValue,
    inputError,
    handleChange: {
      id: onChangeId,
      password: onChangePassword,
      passwordConfirm: onChangePasswordConfirm,
      agreement: onChangeAgreement,
    },
    onSubmit,
    resetValue,
    isLoading: isLoading || isSocialSignUpLoading,
    hasAccess,
  };
};

export default useSocialSignUpWidget;
