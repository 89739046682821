import { Box, Typography } from '@wishket/design-system';

interface MarketingDescriptionOpenButtonProps {
  onClick: () => void;
  testid?: string;
}

const MarketingDescriptionOpenButton = ({
  onClick,
  testid,
}: MarketingDescriptionOpenButtonProps) => {
  return (
    <Box onClick={onClick} className="cursor-pointer" data-testid={testid}>
      <Typography
        variant="body2"
        className="text-w-gray-400 underline decoration-solid decoration-auto underline-offset-auto [text-decoration-skip-ink:auto] [text-underline-position:from-font]"
      >
        보기
      </Typography>
    </Box>
  );
};

export default MarketingDescriptionOpenButton;
