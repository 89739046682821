'use client';

import React, { useEffect } from 'react';
import { LoadingIndicator } from '@wishket/design-system';

import useSignUpWidget from './useSignUpWidget';

import { LoadingDialog } from '@/shared/Components';
import {
  NeedLinkDialog,
  ProjectResetNotificationDialog,
  SignUpForm,
} from '@/features';
import { useAutoFillInterface, useDialog } from '@/shared/hooks';
import { useSocialAuth } from '@/shared/providers';
import { SocialLoginProvider } from '@/shared/types';
import {
  generateSocialProviderName,
  isKakao,
  notifyToast,
  openDefaultBrowserInKakao,
} from '@/shared/utils';
import { withComponentLogger } from '@/shared/hocs';

const SignUp = () => {
  const {
    isSocialLoginLoading,
    isLoading,
    inputValue,
    inputError,
    handleChange,
    handleFormSubmit,
    fetchSocialLogin,
    socialLoginData,
  } = useSignUpWidget();
  const { providerStatus } = useSocialAuth();
  const {
    isNeedLinkOpen,
    isPartnerHasProjectOpen,
    setLoginMessage,
    close: closeDialog,
    submitResetProject,
  } = useDialog();
  const { setAutoFillEmail } = useAutoFillInterface();

  const providerName =
    generateSocialProviderName(providerStatus.get as SocialLoginProvider) ?? '';
  const isNeedLinkDialogOpen = isNeedLinkOpen && !!providerName;

  const submitSocialSignUp = async (provider: SocialLoginProvider) => {
    if (isKakao()) {
      openDefaultBrowserInKakao();

      return;
    }

    try {
      await fetchSocialLogin(provider);
    } catch (e) {
      notifyToast({ type: 'error', message: '소셜 회원가입에 실패했습니다.' });
    }
  };

  useEffect(() => {
    if (socialLoginData) setLoginMessage(socialLoginData.msg);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialLoginData]);

  useEffect(() => {
    setAutoFillEmail(handleChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isSocialLoginLoading && <LoadingIndicator size="lg" isFixed />}
      {isLoading && <LoadingDialog description="회원가입 중입니다." />}
      {isPartnerHasProjectOpen && (
        <ProjectResetNotificationDialog
          onClose={closeDialog}
          onSuccess={submitResetProject}
        />
      )}
      {isNeedLinkDialogOpen && (
        <NeedLinkDialog
          provider={providerName}
          user={{
            id: socialLoginData?.username as string,
            email: socialLoginData?.email as string,
          }}
          onClickCloseButton={closeDialog}
          onClickSubmitButton={closeDialog}
        />
      )}
      <SignUpForm
        inputValue={inputValue}
        inputError={inputError}
        handleChange={handleChange}
        handleSubmit={handleFormSubmit}
        submitSocialSignUp={submitSocialSignUp}
      />
    </>
  );
};

export default withComponentLogger(SignUp);
