import Link from 'next/link';
import { twMerge } from 'tailwind-merge';

import { NameWithLink } from '../Footer.types';

import { Icon } from '@/shared/Components';

interface CircleIconWrapperProps extends NameWithLink {
  name: string;
  className?: string;
  testId?: string;
}

const CircleIconWrapper = ({
  name,
  link,
  className,
  testId,
}: CircleIconWrapperProps) => {
  return (
    <Link href={link} data-testid={testId}>
      <div className="h-8 w-8 rounded-full border border-w-gray-200 p-[7px]">
        <Icon name={name} className={className} />
      </div>
    </Link>
  );
};

const SnsSet = () => {
  return (
    <div
      className={twMerge(
        `flex gap-2 pb-8 grid-in-sns`,
        `desktop:justify-end desktop:border-b desktop:py-4`
      )}
    >
      <CircleIconWrapper
        name="wishket_facebook"
        link="https://www.facebook.com/wishket"
        testId="facebook-link"
      />
      <CircleIconWrapper
        name="wishket_blog"
        link="https://blog.wishket.com/"
        testId="blog-link"
      />
      <CircleIconWrapper
        name="wishket_naver"
        link="https://blog.naver.com/wishket"
        testId="naver-link"
      />
    </div>
  );
};

export default SnsSet;
