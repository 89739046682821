'use client';

import { Box, TextTab, Typography } from '@wishket/design-system';
import { useSearchParams } from 'next/navigation';

import { WishketJobsPrivacy, WishketPrivacy } from '@/features';
import { withComponentLogger } from '@/shared/hocs';

enum Tab {
  WISHKET = 0,
  WISHKET_JOBS = 1,
}

const PrivacyTerms = () => {
  const searchParams = useSearchParams();
  const defaultTab =
    searchParams.get('tab') === 'jobs' ? Tab.WISHKET_JOBS : Tab.WISHKET;

  return (
    <TextTab.Root currentActiveTab={defaultTab}>
      <Box className="w-full overflow-x-auto">
        <TextTab.Tabs>
          <TextTab.Tab index={Tab.WISHKET} className="w-max">
            <Typography variant="body1">위시켓 개인정보 처리방침</Typography>
          </TextTab.Tab>
          <TextTab.Tab index={Tab.WISHKET_JOBS}>
            <Typography variant="body1" className="w-max">
              위시켓 잡스 개인정보 처리방침
            </Typography>
          </TextTab.Tab>
        </TextTab.Tabs>
      </Box>
      <TextTab.Panel index={Tab.WISHKET}>
        <WishketPrivacy />
      </TextTab.Panel>
      <TextTab.Panel index={Tab.WISHKET_JOBS}>
        <WishketJobsPrivacy />
      </TextTab.Panel>
    </TextTab.Root>
  );
};

export default withComponentLogger(PrivacyTerms);
