import {
  PasswordChangeConfirmParams,
  PasswordChangeParams,
} from '@/shared/types';
import { serverAxios } from '@/shared/api';

export const passwordVerifyApi = async ({ password }: { password: string }) => {
  return await serverAxios.post('/member/password/verify/', {
    password,
  });
};

export const passwordChangeApi = async (
  passwordChangeParams: PasswordChangeParams
) => {
  return await serverAxios.post(`/member/password/change/`, {
    ...passwordChangeParams,
  });
};

export const passwordChangeConfirmApi = async (
  passwordConfirmParams: PasswordChangeConfirmParams
) => {
  return await serverAxios.post('/member/password/change-confirm/', {
    ...passwordConfirmParams,
  });
};
