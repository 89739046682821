'use client';

import { FormEvent, useEffect } from 'react';

import useLoginForm from './useLoginForm';

import {
  generateSocialProviderName,
  isKakao,
  notifyToast,
  openDefaultBrowserInKakao,
} from '@/shared/utils';
import {
  LoginForm,
  NeedLinkDialog,
  ProjectResetNotificationDialog,
} from '@/features';
import { LoadingDialog } from '@/shared/Components';
import {
  LoginData,
  LoginMessageEnum,
  SocialLoginData,
  SocialLoginProvider,
} from '@/shared/types';
import { useSocialAuth } from '@/shared/providers';
import { useAuthCompletion, useDialog } from '@/shared/hooks';
import { useLogin, useSocialLogin } from '@/entities/login';
import { withComponentLogger } from '@/shared/hocs';

const Login = () => {
  const {
    inputError,
    inputValue,
    changeEmailOrIdInput,
    changePasswordInput,
    changeRememberInput,
    validForm,
  } = useLoginForm();
  const { redirectNext } = useAuthCompletion();
  const {
    fetch: fetchLogin,
    reset,
    isLogin,
    data: loginData,
    isLoading,
    error,
  } = useLogin();
  const {
    isLogin: isSocialLogin,
    isLoading: isSocialLoading,
    reset: resetSocialLoginStatus,
    data: socialLoginData,
  } = useSocialLogin();
  const {
    hasAgreement,
    isNeedLinkOpen,
    isPartnerHasProjectOpen,
    setLoginMessage,
    close: closeDialog,
    submitResetProject,
  } = useDialog();
  const { fetchSocialLogin, providerStatus } = useSocialAuth();

  const submitLogin = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isOk = validForm();

    if (!isOk) return;

    fetchLogin({
      id: inputValue.emailOrId,
      password: inputValue.password,
      remember: inputValue.remember,
    });
  };

  const submitSocialLogin = async (provider: SocialLoginProvider) => {
    if (isKakao()) {
      openDefaultBrowserInKakao();

      return;
    }

    try {
      if (!hasAgreement) await fetchSocialLogin(provider);
    } catch (e) {
      notifyToast({ type: 'error', message: '소셜 로그인에 실패했습니다.' });
    }
  };

  const getLoginErrorMessage = (errorStatus?: number) => {
    if (errorStatus === 400)
      return '요청하신 이메일 주소로 여러 사용자가 발견되었습니다.\n고객센터에 연락하여 도움을 받으시기 바랍니다.';

    if (errorStatus === 401)
      return '아이디(이메일)또는 비밀번호를 잘못 입력했습니다.\n입력하신 내용을 다시 확인해 주세요.';

    return '로그인에 실패했습니다.';
  };

  const handleLogin = (
    loading: boolean,
    login: boolean | undefined,
    data: SocialLoginData | LoginData | null
  ) => {
    if (loading || login === undefined || !data) return;

    const responseMsg: LoginMessageEnum = data.msg;

    if (
      login &&
      (responseMsg === LoginMessageEnum.CAN_LOGIN ||
        responseMsg === LoginMessageEnum.NOT_ACTIVE_BY_EMAIL)
    ) {
      redirectNext(responseMsg);
      notifyToast({ message: '로그인이 완료되었습니다.' });

      return;
    } else if (
      responseMsg === LoginMessageEnum.NEED_SIGNUP ||
      responseMsg === LoginMessageEnum.NEED_LINK ||
      responseMsg === LoginMessageEnum.PARTNER_HAS_PROJECT
    ) {
      return;
    }

    notifyToast({ type: 'error', message: '로그인에 실패했습니다.' });
    reset();
    resetSocialLoginStatus();
  };

  const handleSubmitPartnerHasProjectSubmit = () => {
    if (!providerStatus.get) {
      fetchLogin({
        isAppliedDelete: true,
        id: inputValue.emailOrId,
        password: inputValue.password,
        remember: inputValue.remember,
      });
    }
  };

  const onCloseDialog = () => {
    closeDialog();
    reset();
    resetSocialLoginStatus();
  };

  const isLoginError = !!error;
  const loginErrorMessage = getLoginErrorMessage(error?.status);

  useEffect(() => {
    handleLogin(isLoading, isLogin, loginData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isLogin, loginData]);

  useEffect(() => {
    handleLogin(isSocialLoading, isSocialLogin, socialLoginData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSocialLogin, isSocialLoading, socialLoginData]);

  useEffect(() => {
    if (loginData) {
      setLoginMessage(loginData.msg);
    } else if (socialLoginData) {
      setLoginMessage(socialLoginData.msg);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialLoginData, loginData]);

  const providerName =
    generateSocialProviderName(providerStatus.get as SocialLoginProvider) ?? '';
  const isNeedLinkDialogOpen = isNeedLinkOpen && !!providerName;

  return (
    <>
      {isLoading && <LoadingDialog description="로그인 중입니다." />}
      {isSocialLoading && !!providerName && (
        <LoadingDialog description={`${providerName} 로그인 중입니다.`} />
      )}
      {isPartnerHasProjectOpen && (
        <ProjectResetNotificationDialog
          onClose={onCloseDialog}
          onSuccess={() => {
            submitResetProject(handleSubmitPartnerHasProjectSubmit);
          }}
        />
      )}
      {isNeedLinkDialogOpen && (
        <NeedLinkDialog
          provider={providerName}
          user={{
            id: socialLoginData?.username as string,
            email: socialLoginData?.email as string,
          }}
          onClickCloseButton={onCloseDialog}
          onClickSubmitButton={onCloseDialog}
        />
      )}
      <LoginForm
        inputValue={inputValue}
        inputError={inputError}
        handleChange={{
          emailOrId: changeEmailOrIdInput,
          password: changePasswordInput,
          remember: changeRememberInput,
        }}
        submitLogin={submitLogin}
        submitSocialLogin={submitSocialLogin}
        isLoginError={isLoginError}
        loginErrorMessage={loginErrorMessage}
      />
    </>
  );
};

export default withComponentLogger(Login);
