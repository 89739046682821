import { Box, ServiceLogo, Typography } from '@wishket/design-system';
import Link from 'next/link';
import { twMerge } from 'tailwind-merge';

const ServiceSet = () => {
  return (
    <Box
      className={twMerge(
        `flex w-full flex-col justify-start gap-6 border-b py-8 grid-in-service`,
        `desktop:flex-row desktop:items-center desktop:gap-8 desktop:py-4`
      )}
    >
      <Typography variant="body2" className="font-bold text-w-gray-600">
        {'서비스 전체보기'}
      </Typography>
      <Box className="flex gap-6 desktop:gap-4">
        <Link
          href="https://www.wishket.com"
          className="flex items-center gap-1 hover:underline"
          data-testid="wishket-link"
        >
          <ServiceLogo name="wishket_symbol" />
          <Typography variant="body3" className="text-w-gray-600">
            {'위시켓'}
          </Typography>
        </Link>
        <Link
          href="https://yozm.wishket.com/magazine/"
          className="flex items-center gap-1 hover:underline"
          data-testid="yozmit-link"
        >
          <ServiceLogo name="yozmIT_symbol" />
          <Typography variant="body3" className="text-w-gray-600">
            {'요즘IT'}
          </Typography>
        </Link>
      </Box>
    </Box>
  );
};

export default ServiceSet;
