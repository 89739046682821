import { Box } from '@wishket/design-system';

import {
  UnStyledList,
  Description,
  InformationBox,
  OrderedList,
  SubTitle,
  Title,
  UnOrderedList,
  CustomOrderLi,
  BulletList,
} from '../Terms.parts';

const WishketJobsPrivacy = () => {
  return (
    <Box className="flex w-full flex-col gap-8 break-all pt-8">
      <Title>위시켓 잡스 개인정보 처리방침</Title>
      <Description>{`위시켓은 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리 방침을 두고 있습니다. 위시켓은 위시켓 잡스 서비스의 개인정보 처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.`}</Description>
      <SubTitle>제1조 (기본 원칙)</SubTitle>
      <Description>{`주식회사 위시켓(이하 “위시켓”)은 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 통신비밀보호법, 전기통신사업법, 개인정보보호법 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 위시켓 잡스 개인정보 처리방침을 정하여 회원 권익 보호에 최선을 다하고 있습니다.`}</Description>
      <SubTitle>
        제2조 (위시켓 잡스 서비스 이용 시 위시켓 개인정보의 활용)
      </SubTitle>
      <Description>
        {`위시켓 잡스는 위시켓의 부속 서비스로 회원가입 및 관리, 원활한 고충처리, 각종 서비스의 제공을 위해 위시켓 최초 회원가입 당시 위시켓 회원으로부터 수집한 다음의 개인정보를 위시켓 잡스 서비스 최초 사용시 위시켓에 입력한 정보로부터 수집하여 사용합니다.`}
        <UnOrderedList className="pl-5">
          <BulletList>{`필수항목: 이메일 주소, 아이디, 비밀번호, 이용 목적`}</BulletList>
        </UnOrderedList>
      </Description>
      <SubTitle>제3조 (서비스 이용시 수집하는 개인정보)</SubTitle>
      <Description>
        {`위시켓은 원활하고 신뢰할 수 있는 서비스의 제공을 위해 위시켓 잡스 서비스 이용동의 후 아래와 같은 개인정보를 수집합니다.`}
        <OrderedList>
          <li>
            {`회원 본인확인, 부정가입의 방지, 가입의사의 확인, 상담 및 문자 처리, 공지사항 안내 등을 위한 정보`}
          </li>
          <UnStyledList>
            <CustomOrderLi
              orderNumber={1}
            >{`필수항목: 이름, 비밀번호, 핸드폰 번호`}</CustomOrderLi>
            <CustomOrderLi
              orderNumber={2}
            >{`선택항목: 페이스북 등 외부 서비스와의 연동을위해 이용자가 설정한 계정 정보, 생일, 출생연도, 성별`}</CustomOrderLi>
          </UnStyledList>

          <li>{`서비스 이용과정 및 사업처리 과정에서 자동수집되는 정보`} </li>
          <UnStyledList>
            <CustomOrderLi
              orderNumber={1}
            >{`서비스 이용 기록, 방문 기록, 접속 로그, 불량 이용 기록 등 정보`}</CustomOrderLi>
            <CustomOrderLi
              orderNumber={2}
            >{`기업회원 서비스 이용시 관련 이용 기록, 방문 기록, 접속 로그, 불량 이용 기록 등 정보`}</CustomOrderLi>
          </UnStyledList>

          <li>
            {`입사지원 및 채용공고 추천 등 서비스 제공 및 각종 맞춤 서비스 제공을 위해 수집하는 정보`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`선택항목: 교육이수사항, 경력, 수상 및 대외활동, 보유기술, 자격증, 외국어능력, 자기소개, 홈페이지, SNS 등`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>
            {`포인트 상응금액 및 취업축하금 지급을 위한 정보`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`필수항목: 본인 계좌정보(은행명 및 계좌번호) 및 증빙자료, 소득세법에 근거한 주민등록번호, 입사 후 3개월 분의 원천징수세액을 증명할 수 있는 자료`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>
            {`기업회원 서비스 이용을 위한 기업 및 대표자 신원확인 목적의 정보`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`필수항목: 사업자등록증, 직책 또는 소속, 내선번호 또는 핸드폰 번호, 대표를 희망하는 기업에 재직하고 있음을 증명할 수 있는 증빙자료`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>
            {`기업회원의 채용공고 등록시 전시를 위해 수집하는 정보`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`필수항목: 공고명, 역할, 채용조건 등 채용공고 기초정보, 주요업무, 자격요건, 필요 기술 및 경력 등 채용공고용 정보`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`선택항목: 인원, 역할 및 레벨, 구성원 인터뷰 등 채용희망부서의 정보, 우대사항, 사내문화 등 채용공고용 세부정보`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>
            {`채용중개 등 유료 서비스 이용시 요금 정산, 청구, 반환을 위한 정보`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`필수정보: 은행명, 계좌번호, 예금주 등 거래계좌정보 및 증빙자료, 세금계산서 발행을 위한 자료`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>
            {`고장수리 등 서비스 품질 개선을 위해 수집하는 정보`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`IP주소, 쿠키, 기기식별번호(MAC주소 등), 서비스 이용 기록, 방문 기록, 접속 로그, 불량 이용 기록 등 정보`}</CustomOrderLi>
            </UnStyledList>
          </li>
        </OrderedList>
      </Description>
      <SubTitle>제4조 (민감한 개인정보의 수집 금지 등)</SubTitle>
      <Description>{`위시켓은 회원의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종, 사상, 신조, 정치적 성향, 범죄기록, 의료정보 등)는 수집하지 않으며, 회원이 선택 항목의 정보를 입력하지 않은 경우에도 위시켓이 제공하는 서비스 이용에 제한은 없습니다.`}</Description>
      <SubTitle>제5조 (개인정보 수집 방법)</SubTitle>
      <Description>
        {`위시켓은 다음 각 호의 방법으로 개인정보를 수집합니다.`}
        <OrderedList>
          <li>{`위시켓 잡스가 제공하는 플랫폼, 서면 양식, 전화, 팩스, 이메일 등 서비스 가입이나 사용 중 회원의 자발적 제공을 통한 수집`}</li>
          <li>{`생성 정보 수집 툴을 통한 수집`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제6조 (개인정보 수집 및 이용 목적)</SubTitle>
      <Description>
        {`위시켓은 다음 각 호의 목적으로 회원의 개인정보를 수집 및 이용합니다. 수집한 개인정보는 다음의 목적 이외의 용도로 사용되지 않으며 이용 목적이 변경될 시에는 별도의 사전동의를 구합니다.`}
        <OrderedList>
          <li>{`위시켓 잡스 서비스 이용 및 위시켓의 위시켓 잡스 서비스 이용자 관리: 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인 확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, 각종 고지·통지, 고충처리, 분쟁 조정을 위한 기록 보존 등`}</li>
          <li>{`민원사무의 처리: 민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 등의 목적`}</li>
          <li>
            {`서비스의 제공`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`위시켓 잡스 개인회원: 채용중개 서비스의 제공, 각종 맞춤 서비스 제공, 취업 성공에 따른 포인트 상응금액 및 취업축하금의 정산 및 지급`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`위시켓 잡스 기업회원: 구인 서비스 이용을 위한 채용공고 정보의 전시, 채용중개 서비스를 통한 채용에 따른 수수료 정산 및 청구 및 반환`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>{`마케팅 및 광고에의 활용: 신규 서비스 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 통계 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리`}</li>
          <li>{`데이터 분석에의 활용: 고용 및 취업동향 파악을 위한 통계적 분석, 서비스 고도화를 위한 데이터 분석`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제7조 (개인정보 제3자 제공의 기본 원칙)</SubTitle>
      <Description>
        <OrderedList>
          <li>
            {`대상 회원이 사전에 공개에 동의한 경우`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`개인회원이 프로필 조회를 허용하거나 기업회원의 채용에 지원하는 경우 공개 동의한 정보가 기업회원에게 3개월(90일)까지 제공합니다.`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`위시켓 잡스 이용약관을 바탕으로 기업회원은 위시켓 잡스를 통해 제공받은 개인회원 정보를 초기목적(채용 등 인사절차) 외 목적으로 사용을 하지 않으며 3개월(90일)이내 파기합니다.`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>{`법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우`}</li>
          <li>{`유료 서비스 제공에 따른 요금정산 및 수취, 지급을 위해 필요한 경우 관련 금융기관 및 세무기관에 제공합니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제8조 (예외적 개인정보 제3자 제공)</SubTitle>
      <Description>{`위시켓은 개인정보를 제6조가 정의한 대상 외 제3자에게 제공하거나 외부에 공개하지 않습니다.`}</Description>
      <SubTitle>제9조 (개인정보의 취급 위탁)</SubTitle>
      <Description>{`위시켓은 개인정보를 제6조가 정의한 대상 외 개인 및 업체에게 위탁하지 않습니다.`}</Description>
      <SubTitle>제10조 (개인정보 보유 및 이용기간의 기본 원칙)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`원칙적으로 회원의 개인정보는 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기됩니다.`}</li>
          <li>{`개인정보보호를 위하여 이용자가 1년 동안 사이트를 이용하지 않은 경우, 해당 계정을 휴면계정으로 분리하여 해당 계정의 이용을 중지할 수 있습니다. 이 경우 위시켓은 휴면계정 처리 예정일로부터 30일 이전에 해당사실을 전자메일, 서면, SMS 중 하나의 방법으로 사전통지하며 이용자가 직접 본인확인을 거쳐, 다시 서비스 이용 의사표시를 한 경우에는 사이트 이용이 가능합니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제11조 (위시켓 내부 방침에 의한 개인정보의 보유)</SubTitle>
      <Description>
        {`제9조에도 불구하고, 위시켓은 위시켓 잡스 서비스 거래 관련 분쟁 방지를 위하여 위시켓 내부 방침에 따라 회원 가입 및 관리 관련 정보를 다음 각 호의 기간 동안 보존합니다.`}
        <OrderedList>
          <li>
            {`개인회원이 위시켓 잡스를 통한 채용공고 지원 이력을 보유할 시, 다음과 같은 목적으로 서비스 이용계약 해지 후 3년 동안 지원내역 및 관련한 개인정보를 보관합니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`위시켓 잡스 서비스를 통해 취업이 완료되었음에도 기업과의 담합하여 취업 사실을 공유하지 않고 기업의 부정이용에 동참하는 것을 방지`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`위시켓과 기업회원 간 채용 서비스 제공에 관한 계약 이행을 완료하기 위해 개인회원의 지원정보 보관이 필요`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>{`기업회원 서비스 계약중인 기업회원의 계약이행을 위한 정보는 계약기간 중 보관합니다.`}</li>
          <li>{`회원에 대하여 관계 법령 위반에 따른 수사·조사 등이 진행중인 경우: 해당 수사·조사 종료 시까지 보관`}</li>
          <li>{`위시켓과 회원 사이에 채권·채무관계가 잔존하는 경우: 해당 채권·채무관계 정산 완료시점까지 보관`}</li>
          <li>{`회원에서 탈퇴한 후 회원 재가입, 임의 해지 등을 반복적으로 행하여 정당하지 않은 경제상의 이익을 취하거나 이 과정에서 명의를 무단으로 사용하는 편법과 불법행위를 하는 회원을 차단하고자 위시켓 통합회원 계정의 탈퇴 또는 위시켓 잡스 서비스 계약의 해지 후 회원의 성명, 주민등록번호(또는 CI), 아이디(ID), 비밀번호(Password), 이메일(E-mail)정보를 1개월 간 보관하며, 로그기록 및 접속 아이피(IP) 정보는 5년 보관합니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제12조 (관련 법령에 의한 개인정보의 보유)</SubTitle>
      <Description>
        {`위시켓은 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 다음 각 호에 따라 회원의 해당 개인정보를 보관하며, 그 목적의 범위 내에서만 이를 이용합니다. 단, 위시켓 잡스 서비스 이용약관 및 서비스에 준하는 별도 계약에 의해 각 계약의 계약기간이 존속 중인 회원(서비스 이용계약을 해지하지 아니한 회원)의 경우 보관기간은 보존의무기간 이상으로 연장할 수 있으며, 해당 기간이 경과된 기록에 대해서는 회원의 삭제 요청이 있는 경우 파기합니다.`}
        <OrderedList>
          <li>{`계약 또는 청약철회 등에 관한 기록: 전자상거래 등에서의 소비자보호에 관한 법률에 따라 5년간 보존`}</li>
          <li>{`대금결제 및 재화 등의 공급에 관한 기록: 전자상거래 등에서의 소비자보호에 관한 법률에 따라 5년간 보존`}</li>
          <li>{`소비자의 불만 또는 분쟁 처리에 관한 기록: 전자상거래 등에서의 소비자보호에 관한 법률에 따라 3년간 보존`}</li>
          <li>{`표시·광고에 관한 기록: 전자상거래 등에서의 소비자보호에 관한 법률에 따라 6개월간 보존`}</li>
          <li>{`본인확인에 관한 기록: 정보통신망 이용촉진 및 정보보호 등에 관한 법률에 따라 6개월간 보존`}</li>
          <li>{`전기통신일시, 개시·종료시간, 가입자 번호, 사용 도수, 발신 기지국 위치추적자료에 관한 기록: 통신비밀보호법에 따라 1년간 보존`}</li>
          <li>{`컴퓨터통신, 인터넷 로그기록자료, 접속지 추적 자료: 통신비밀보호법에 따라 3개월간 보존`}</li>
          <li>{`신용정보의 수집/처리 및 이용 등에 관한 기록: 3년`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제13조 (개인정보의 파기 절차 및 방법)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`위시켓은 원칙적으로 개인정보 이용 목적이 달성된 경우에는 파기 사유가 발생한 개인정보를 선정하고, 위시켓의 개인정보 보호책임자의 승인을 받아 지체 없이 해당 개인정보를 파기합니다.`}</li>
          <li>
            {`파기의 절차, 기한 및 방법은 다음과 같습니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`파기 절차: 이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`파기 기한: 이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 이용 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={3}
              >{`파기 방법: 종이에 기록, 저장된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하며, 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없도록 기술적 방법을 사용하여 삭제합니다.`}</CustomOrderLi>
            </UnStyledList>
          </li>
        </OrderedList>
      </Description>
      <SubTitle>제14조 (개인정보의 수집, 이용, 제공에 대한 동의 철회)</SubTitle>
      <Description>{`회원은 위시켓 잡스 이용약관 등을 통해 동의한 개인정보의 수집, 이용, 제공에 대하여 동의한 내용을 언제든지 철회할 수 있습니다.`}</Description>
      <SubTitle>제15조 (회원의 권리와 행사방법)</SubTitle>
      <Description>
        <OrderedList>
          <li>
            {`회원은 위시켓에 대해 언제든지 다음과 각 호와 같은 권리를 행사할 수 있습니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`개인정보 열람 요구`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`오류 등이 있을 경우 정정 요구`}</CustomOrderLi>
              <CustomOrderLi orderNumber={3}>{`삭제 요구`}</CustomOrderLi>
              <CustomOrderLi orderNumber={4}>{`처리 정지 요구`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>{`제1항에 따른 권리 행사는 위시켓에 대해 개인정보 보호법 시행규칙 별지 제8호 서식에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 위시켓은 본인 확인을 위한 요청인의 신분증 사본 등의 증표를 제시 받아 해당 요구가 진정한 본인의 의사인지 여부를 확인할 수 있으며, 본인으로 확인되고 개인정보에 오류가 있거나 보존기간을 경과한 것이 판명되는 등 정정 또는 삭제할 필요가 있다고 인정되는 경우 지체 없이 그에 따른 조치를 취합니다.`}</li>
          <li>{`회원이 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 위시켓은 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다. 제1항에 따른 권리 행사는 회원의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하여야 합니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제16조 (법정대리인의 권리)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`법정대리인은 만 14세 미만 아동의 개인정보 수집·이용 또는 제공에 대한 동의를 철회할 수 있으며, 해당 아동이 제공한 개인정보에 대한 열람 또는 오류의 정정을 요구할 수 있습니다.`}</li>
          <li>{`유선 또는 서면을 통하여 만 14세 미만 아동인 회원의 법정대리인이 열람, 증명을 요구하는 경우, 위시켓은 대리관계를 증명하는 위임장 및 인감증명서, 요청인 및 대리인의 신분증 사본 등의 증표를 제시 받아 해당 요구를 하는 자가 진정한 법정대리인인지 여부를 확인합니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제17조 (위시켓의 개인정보 열람 및 이용 제한)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`회원 또는 법정대리인의 요청에 의해 해지 또는 삭제, 정정된 개인정보는 제10조 내지 제12조에 명시된 바에 따라 처리되고, 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.`}</li>
          <li>{`회원 및 법정대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입 해지를 요청할 수도 있습니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제18조 (쿠키의 운용)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`위시켓은 회원에게 특화된 맞춤서비스를 제공하기 위해서 회원들의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을 운용합니다. 쿠키란 웹사이트를 운영하는 데 이용되는 서버가 회원의 브라우저에 보내는 아주 작은 텍스트 파일로서 회원의 컴퓨터 하드디스크에 저장되기도 합니다.`}</li>
          <li>{`위시켓은 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 회원의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 횟수 파악 등을 통한 타깃 마케팅 및 개인 맞춤 서비스 제공 등의 목적으로 쿠키를 사용합니다.`}</li>
          <li>{`회원은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 회원은 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.`}</li>
          <li>{`제3항에 따라 쿠키 설정을 거부하는 방법으로, 회원은 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. 인터넷 익스플로러의 경우를 예로 들면 웹 브라우저 상단의 도구 → 인터넷 옵션 → 개인정보 메뉴를 통하여 쿠키 설정의 거부가 가능합니다.`}</li>
          <li>{`회원이 쿠키 설치를 거부하는 경우 로그인이 필요한 일부 서비스 이용에 어려움이 있을 수 있습니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제19조 (해킹 등에 대비한 대책)</SubTitle>
      <Description>
        {`위시켓은 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.`}
        <OrderedList>
          <li>{`개인정보 취급 직원의 최소화 및 교육: 개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.`}</li>
          <li>{`정기적인 자체 감사 실시: 개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.`}</li>
          <li>{`내부관리계획의 수립 및 시행: 개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.`}</li>
          <li>{`개인정보의 암호화: 이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.`}</li>
          <li>{`해킹 등에 대비한 기술적 대책: 위시켓은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.`}</li>
          <li>{`개인정보에 대한 접근 제한: 개인정보를 처리하는 데이터베이스시스템에 대한 접근 권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.`}</li>
          <li>{`접속 기록의 보관 및 위변조 방지: 개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.`}</li>
          <li>{`문서보안을 위한 잠금 장치 사용: 개인정보가 포함된 서류, 보조저장매체 등을 잠금 장치가 있는 안전한 장소에 보관하고 있습니다.`}</li>
          <li>{`비인가자에 대한 출입 통제: 개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.`}</li>
          <li>{`기타: 그 외 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제20조 (취급 직원의 최소화 및 교육)</SubTitle>
      <Description>{`위시켓의 개인정보 관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 위시켓은 개인정보 처리방침의 준수를 항상 강조하고 있습니다.`}</Description>
      <SubTitle>제21조 (개인정보보호전담기구의 운영)</SubTitle>
      <Description>{`위시켓은 사내 개인정보보호전담기구 등을 통하여 개인정보 처리방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다. 단, 회원 본인의 부주의나 인터넷상의 문제로 개인 정보가 유출되어 발생한 문제에 대해 위시켓은 일체의 책임을 지지 않습니다.`}</Description>
      <SubTitle>제22조 (개인정보관리 책임자 및 담당자)</SubTitle>
      <Description>
        {`회원은 위시켓 잡스 서비스를 이용하며 발생하는 모든 개인정보보호에 대한 문의, 불만처리, 피해구제 등 관련 민원을 다음 각 호의 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 위시켓은 정보주체의 문의에 대해 지체 없이 답변 및 처리할 것입니다.`}
        <UnStyledList className="pl-8">
          <CustomOrderLi orderNumber={1} className="mt-6">
            {`개인정보 보호 책임자`}
          </CustomOrderLi>
          <InformationBox>
            <UnOrderedList>
              <BulletList>{`성명: 박우범`}</BulletList>
              <BulletList>{`직책: 대표이사`}</BulletList>
              <BulletList>{`연락처: (02) 6925-4849, parkwoobeom@wishket.com`}</BulletList>
            </UnOrderedList>
            <div className="ml-3 break-all">
              ※ 개인정보 보호 담당부서로 연결됩니다.
            </div>
          </InformationBox>
          <CustomOrderLi orderNumber={2}>
            {`개인정보 보호 담당부서`}
          </CustomOrderLi>
          <InformationBox>
            <UnOrderedList>
              <BulletList>{`부서명: 관리팀`}</BulletList>
              <BulletList>{`담당자: 박우범`}</BulletList>
              <BulletList>{`연락처: (02) 6925-4849, parkwoobeom@wishket.com`}</BulletList>
            </UnOrderedList>
          </InformationBox>
        </UnStyledList>
      </Description>
      <SubTitle>제23조 (개인정보 열람 창구)</SubTitle>
      <Description>
        {`회원은 위시켓 잡스 관련 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 위시켓은 회원의 개인정보 열람 청구가 신속하게 처리되도록 노력하겠습니다.`}
        <InformationBox>
          <UnOrderedList>
            <BulletList>{`부서명: 관리팀`}</BulletList>
            <BulletList>{`담당자: 박우범`}</BulletList>
            <BulletList>{`연락처: (02) 6925-4849, parkwoobeom@wishket.com`}</BulletList>
          </UnOrderedList>
        </InformationBox>
      </Description>
      <SubTitle>제24조 (개인정보 처리방침 관련 고지 및 통지 방법)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`위시켓은 현행 위시켓 잡스 개인정보 처리방침에 대한 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 사이트를 통해 변경 이유 및 그 내용을 고지합니다. 다만, 개인정보의 수집 및 활용 등에 있어 이용자 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 고지합니다.`}</li>
          <li>{`위시켓은 회원이 동의한 범위를 넘어 회원의 개인정보를 이용하거나 제3자에게 취급위탁하기 위해 회원의 추가적인 동의를 얻고자 하는 때에는 미리 회원에게 개별적으로 서면, 전자우편, 전화 등으로 해당사항을 고지합니다.`}</li>
          <li>{`위시켓은 개인정보의 수집, 보관, 처리, 이용, 제공, 관리, 파기 등을 제3자에게 위탁하는 경우에는 개인정보 처리방침 등을 통하여 그 사실을 회원에게 고지합니다.`}</li>
          <li>{`만 14세 미만 아동의 개인정보를 수집, 이용하거나 제3자에게 제공하기 위하여 법정대리인의 동의를 받고자 하는 경우에는 전화, 팩스, 우편, 아동으로 하여금 통지 내용을 법정대리인에게 제출하게 하는 방법, 법정대리인에게 이메일을 보내 본 개인정보 처리방침을 볼 수 있도록 연결(하이퍼링크)시키는 방법 또는 기타 법정대리인에게 고지 내용이 도달할 수 있는 합리적인 방법을 통하여 법정대리인에게 고지합니다.`}</li>
        </OrderedList>
      </Description>
      <Title>부칙</Title>
      <Description>{`본 위시켓 잡스 개인정보 처리방침은 2022년 8월 16일부터 시행됩니다.`}</Description>
    </Box>
  );
};

export default WishketJobsPrivacy;
