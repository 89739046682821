'use client';

import { useState } from 'react';

import { clientAxios } from '@/shared/api';
import { PasswordChangeParams } from '@/shared/types';

const usePasswordChange = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordChange, setIsPasswordChange] = useState<boolean | undefined>(
    undefined
  );

  const fetchPasswordChange = async (
    passwordChangeParams: PasswordChangeParams
  ) => {
    setIsPasswordChange(undefined);
    setIsLoading(true);

    try {
      const { data } = await clientAxios.post('/passwordChangeApi', {
        ...passwordChangeParams,
      });
      if (data.ok) {
        setIsPasswordChange(true);
      }
    } catch (e) {
      setIsPasswordChange(false);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data: isPasswordChange,
    fetch: fetchPasswordChange,
  };
};

export default usePasswordChange;
