'use client';

import { useState } from 'react';

import { clientAxios } from '@/shared/api';

const useCancelWithdrawal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isWithdrawalCancelled, setIsWithdrawalCancelled] = useState<
    boolean | undefined
  >(undefined);

  const fetchCancelWithdrawal = async () => {
    setIsLoading(true);
    try {
      const { data } = await clientAxios.patch('/cancelWithdrawalApi');

      if (data.ok) {
        setIsWithdrawalCancelled(true);
      }
    } catch (e) {
      setIsWithdrawalCancelled(false);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data: isWithdrawalCancelled,
    fetch: fetchCancelWithdrawal,
  };
};

export default useCancelWithdrawal;
