import { Box, Typography } from '@wishket/design-system';
import Image from 'next/image';

const Welcome = () => {
  return (
    <Box
      className="flex flex-col items-start justify-start gap-10 py-8"
      data-testid="feature-welcome"
    >
      <Typography variant="title2" className="whitespace-pre text-w-gray-900">
        {'위시켓의 다양한 서비스를\n하나의 계정으로!'}
      </Typography>
      <Image
        src={`${process.env.NEXT_PUBLIC_APP_BASE_MEDIA_URL}/images/auth/Illust_Login.png`}
        alt="welcome"
        className="flex-none"
        width="461"
        height="240"
        role="presentation"
        unoptimized
        priority
      />
    </Box>
  );
};

export default Welcome;
