import { Typography } from '@wishket/design-system';

interface MainTitleProps {
  text: string;
}

export const MainTitle = ({ text }: MainTitleProps) => {
  return (
    <>
      <Typography
        variant="title3"
        className="hidden text-w-gray-900 desktop:block"
      >
        {text}
      </Typography>
      <Typography
        variant="subTitle1"
        className="hidden text-w-gray-900 tablet:block desktop:hidden"
      >
        {text}
      </Typography>
      <Typography
        variant="subTitle1"
        className="block text-w-gray-900 tablet:hidden"
      >
        {text}
      </Typography>
    </>
  );
};
