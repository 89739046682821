'use client';

import { useState } from 'react';
import { useRouter } from 'next/navigation';
import { signOut } from 'next-auth/react';

import { clientAxios } from '@/shared/api';
import { ClientAuth } from '@/shared/auth';
import { notifyToast } from '@/shared/utils';
import { PATH } from '@/shared/constants';
import { useAuthCompletion, useNextUrl } from '@/shared/hooks';

const useLogout = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const router = useRouter();
  const { redirectNext, referrerService } = useAuthCompletion();
  const { withNextUrl } = useNextUrl();

  const fetch = async () => {
    setIsLoading(true);

    await clientAxios
      .post('/logoutApi')
      .catch(() => {})
      .finally(async () => {
        await Promise.all([
          ClientAuth.deAuthenticate(),
          signOut({ redirect: false }),
        ])
          .catch(() => {})
          .finally(() => {
            setIsLoading(false);

            if (referrerService === 'wishket-member') {
              router.replace(withNextUrl(PATH.LOGIN));
            } else {
              redirectNext();
            }

            notifyToast({ message: '로그아웃이 완료되었습니다.' });
          });
      });
  };

  return { isLoading, fetch };
};

export default useLogout;
