import { Box, ServiceLogo, Typography } from '@wishket/design-system';

import Link from 'next/link';

import { BetaServiceBadge } from '../WithdrawalRequest/WithdrawalRequest.parts';

import { PATH } from '@/shared/constants';

const EmailUnsubscribeFail = () => {
  return (
    <Box className="flex w-full flex-col gap-8 desktop:w-[720px]">
      <Typography variant="title3" className="text-w-gray-900">
        수신 거부 설정 실패
      </Typography>
      <Box className="flex flex-col gap-3">
        <Box className="flex items-start gap-2">
          <Box className="mt-3 flex items-center justify-center">
            <div className="h-0.5 w-0.5 shrink-0 rounded-full bg-w-gray-600" />
          </Box>
          <Typography
            variant="body2"
            className="whitespace-pre text-w-gray-600"
          >
            {
              '수신 거부 설정 도중 오류가 발생했습니다.\n고객센터(help@wishket.com / 02-6925-4849)로 문의해 주세요.'
            }
          </Typography>
        </Box>
        <Box className="flex flex-col gap-2">
          <Box className="flex items-start gap-2">
            <Box className="mt-3 flex items-center justify-center">
              <div className="h-0.5 w-0.5 shrink-0 rounded-full bg-w-gray-600" />
            </Box>
            <Typography variant="body2" className="text-w-gray-600">
              서비스로 이동하기
            </Typography>
          </Box>
          <Box className="flex justify-center gap-4 rounded-xl bg-w-gray-10 py-6">
            <Link href={PATH.WISHKET}>
              <Box className="flex justify-center gap-0.5">
                <ServiceLogo name="wishket_symbol" />
                <Typography variant="body2" className="text-w-gray-900">
                  위시켓
                </Typography>
              </Box>
            </Link>
            <Link href={PATH.JOBS}>
              <Box className="flex justify-center gap-0.5">
                <ServiceLogo name="jobs_symbol" />
                <Typography variant="body2" className="text-w-gray-900">
                  위시켓잡스
                </Typography>
                <BetaServiceBadge className="pt-0.5" />
              </Box>
            </Link>
            <Link href={PATH.YOZMIT}>
              <Box className="flex justify-center gap-0.5">
                <ServiceLogo name="yozmIT_symbol" />
                <Typography variant="body2" className="text-w-gray-900">
                  요즘IT
                </Typography>
              </Box>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EmailUnsubscribeFail;
