'use client';

import Link from 'next/link';
import { twJoin, twMerge } from 'tailwind-merge';
import { usePathname } from 'next/navigation';
import { Box, SystemIcon, Typography } from '@wishket/design-system';
import { useRef, useState } from 'react';

import { TERMS_TAB_LIST } from './TermsTab.constants';

import { useClickOutside } from '@/shared/hooks';
import { withComponentLogger } from '@/shared/hocs';

const DropDown = ({ pathName }: { pathName: string }) => {
  const [isFocus, setIsFocus] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(ref, () => {
    setIsFocus(false);
  });

  const toggleFocus = () => {
    setIsFocus(!isFocus);
  };

  return (
    <div
      className="relative flex w-full cursor-pointer items-center justify-between rounded-lg px-4 py-2.5 ring-1 ring-w-gray-200 hover:ring-w-primary desktop:hidden"
      ref={ref}
      onClick={toggleFocus}
    >
      <Typography variant="body1" className="text-w-gray-600">
        {TERMS_TAB_LIST.find((terms) => terms.path === pathName)?.name}
      </Typography>
      <SystemIcon name={isFocus ? 'medium_arrow_up' : 'medium_arrow_down'} />
      {isFocus && (
        <Box className="absolute left-0 top-full z-10 flex w-full flex-col rounded-lg bg-w-white py-3 text-w-gray-600 shadow-contents-container ring-1 ring-w-gray-200">
          {TERMS_TAB_LIST.map((terms) => (
            <Link
              key={terms.path}
              href={terms.path}
              className={twJoin(
                'px-4 py-2',
                pathName === terms.path && 'border-w-primary'
              )}
            >
              <Typography
                variant="body1"
                className={twJoin(pathName === terms.path && 'text-w-cyan-500')}
              >
                {terms.name}
              </Typography>
            </Link>
          ))}
        </Box>
      )}
    </div>
  );
};

const TermsTab = () => {
  const pathName = usePathname();

  return (
    <>
      <Box className="hidden desktop:flex">
        {TERMS_TAB_LIST.map((terms, index) => (
          <Link
            key={terms.path}
            href={terms.path}
            className={twMerge(
              'flex flex-1 items-center justify-center border border-w-gray-200 px-6 py-4',
              index === 0 && 'rounded-l-xl',
              index === TERMS_TAB_LIST.length - 1 && 'rounded-r-xl',
              index !== 0 && '-ml-px',
              pathName === terms.path
                ? 'z-10 border-w-primary bg-w-cyan-10'
                : 'hover:bg-w-gray-10'
            )}
          >
            <Typography
              variant="body1"
              className={twMerge(
                'text-w-gray-600',
                pathName === terms.path && 'font-medium text-w-cyan-500'
              )}
            >
              {terms.name}
            </Typography>
          </Link>
        ))}
      </Box>
      <DropDown pathName={pathName} />
    </>
  );
};

export default withComponentLogger(TermsTab);
