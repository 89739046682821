import { Box } from '@wishket/design-system';

import {
  Description,
  SubTitle,
  Title,
  OrderedList,
  UnStyledList,
  DescriptionLink,
  CustomOrderLi,
} from '../Terms.parts';

const WishketJobsServiceTerms = () => {
  return (
    <Box className="flex w-full flex-col gap-8 break-all pt-8">
      <Title>위시켓 잡스 서비스 이용약관</Title>
      <SubTitle>제1조 (목적)</SubTitle>
      <Description>
        {`본 약관은 주식회사 위시켓(이하 위시켓이라 합니다)이 제공하는 위시켓 잡스 서비스의 이용과 관련하여 위시켓과 서비스 이용자 간의 권리, 의무, 책임사항, 및 기타 필요한 사항을 규정함을 목적으로 합니다.`}
      </Description>
      <SubTitle>제2조 (용어의 정의)</SubTitle>
      <Description>
        <OrderedList>
          <li>
            {`“사이트”라 함은 위시켓이 회원에게 서비스를 제공하기 위하여 컴퓨터 등 정보 통신 설비를 이용하여 설정한 가상의 영업장, 어플리케이션, 웹사이트 등을 말합니다.`}
            <UnStyledList>
              <CustomOrderLi orderNumber={1}>
                <DescriptionLink
                  href="https://jobs.wishket.com/superstack/"
                  isNewWindow
                >
                  jobs.wishket.com
                </DescriptionLink>
              </CustomOrderLi>
            </UnStyledList>
          </li>
          <li>
            {`“서비스”라 함은 위시켓이 운영하는 가상의 영업장, 웹사이트, 또는 어플리케이션 등을 통해 개인회원과 기업회원에게 제공하는 플랫폼 서비스 및 부속 서비스 입니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`본 약관에 준하는 서비스는 주식회사 위시켓이 제공하는 전체 서비스 중 일부이며 전체 서비스와 구분되어 본 약관이 정의하는 사항에 한정합니다.`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>{`“개인회원”은 서비스를 이용하기 위해 본 약관에 동의하여 이용계약을 체결한 이용자를 말합니다.`}</li>
          <li>{`“기업회원”은 특정 기업의 대표자임을 인증하는 절차를 거쳐 서비스의 이용에 대한 계약을 체결한 이용자를 말합니다.`}</li>
          <li>{`“회원”이라 함은 개인회원 자격, 혹은 기업회원 자격을 보유한 이용자를 의미합니다.`}</li>
          <li>{`“비회원”은 회원으로 가입하지 않고 위시켓이 제공하는 서비스를 직접적으로 이용하지 않는 이용자를 말합니다.`}</li>
          <li>{`“이용자”라 함은 본 조 3항, 4항, 6항이 정의하는 개인회원, 기업회원, 비회원을 말합니다.`}</li>
          <li>{`“프로필”이라 함은 개인회원이 서비스 이용 및 공개전시의 목적으로 사이트에 업로드한 정보 및 이력정보(이력서), 파일 등을 말합니다.`}</li>
          <li>{`“포인트”는 개인회원의 사이트 이용 및 활동에 대해 제공하는 가상의 점수입니다.`}</li>
          <li>{`“취업축하금”은 사이트에 채용공고를 작성한 기업회원이 위시켓에 지급하여 당 채용공고에 지원 및 합격 후 자격요건에 부합하는 개인회원에게 위시켓이 당 개인회원의 활동이력을 바탕으로 산정해 지급하는 금액입니다.`}</li>
          <li>{`“게시물 등”은 개인회원이 사이트 등에 등록한 프로필 및 기타 사이트에 게시한 일체의 게시물을 의미합니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제3조 (약관의 명시와 개정)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`위시켓은 이 약관의 내용을 회원이 알 수 있도록 사이트의 초기 화면 또는 링크로 연결된 화면에 게시하거나 기타의 방법으로 회원에게 공지합니다.`}</li>
          <li>{`위시켓은 회원이 이 약관의 내용에 관하여 질의 및 응답을 할 수 있도록 조치를 취합니다.`}</li>
          <li>{`위시켓은 「약관의 규제에 관한 법률」 등 관련 법령에 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.`}</li>
          <li>{`위시켓은 약관을 개정할 경우에는 적용일자 및 개정내용, 개정 사유 등을 명시하여 그 적용일자로부터 최소한 7일 이전, 회원에게 불리하거나 중대한 사항의 변경은 30일 이전부터 그 적용일자 경과 후 상당한 기간이 경과할 때까지 사이트 내 화면을 통해 공지합니다.`}</li>
          <li>{`위시켓은 약관을 개정할 경우에는 원칙적으로 개정약관 공지 후 개정약관의 적용에 대한 회원의 동의 여부를 확인합니다. 다만 개정약관 공지 시 회원이 동의 또는 거부의 의사표시를 하지 않으면 승낙한 것으로 간주하겠다는 내용도 함께 공지한 경우에는 회원이 약관 시행일까지 거부의사를 표시하지 않는다면 개정약관에 동의한 것으로 간주합니다.`}</li>
          <li>{`개인회원이 개정약관의 적용에 동의하지 않는 경우 회원은 서비스 이용계약을 해지할 수 있습니다.`}</li>
          <li>{`기업회원이 개정약관의 적용에 동의하지 않을 시 현행하는 채용공고 및 계약사항, 미수금이 없는 조건 하에 서비스 이용계약을 해지할 수 있습니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제4조 (약관 외 준칙)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`본 약관에 규정되지 않은 사항에 대해서는 관련법령 또는 위시켓이 정한 개별 서비스의 이용약관, 운영정책 및 규칙 등(이하 ‘세부지침’)의 규정에 따릅니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제5조 (개인회원에 대한 고지, 통지 및 공지)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`위시켓은 개인회원이 가입 시 등록한 이메일 주소로 이메일을 발송하거나, 전화번호로 문자 메세지를 보내거나, 사이트의 게시판이나 화면에 일정한 사항을 게시하여 공지하는 등의 합리적이며 가능한 방법으로 개인회원에 대한 개별 고지나 통지를 할 수 있습니다.`}</li>
          <li>{`회원 전체에 대한 공지는 3일 이상 사이트에 게시함으로써 효력이 발생합니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제6조 (서비스 이용신청 및 이용계약의 성립)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`약관동의 및 서비스 이용신청을 위해서는 위시켓 통합회원 계정이 필요합니다.`}</li>
          <li>
            {`서비스 이용계약의 계약신청은 본 약관에 동의할 때 이루어집니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`약관에 관한 동의는 사이트 최초이용시 표시되는 본 약관에 동의함을 선택하거나 기타 동의 버튼을 통해 의사표시를 합니다.`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>{`기업회원 자격부여 및 기업회원 대상 서비스의 이용계약은 본 약관과 별도의 계약을 통해 구축됩니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제7조 (이용신청의 승낙과 제한)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`서비스를 이용하려는 자(이하 “신청자”라 합니다)가 제6조에서 정하는 바에 따라 정당히 약관동의를 한 경우에 상당한 이유가 없는 한 위시켓은 이용신청을 승낙합니다.`}</li>
          <li>
            {`신청자가 다음 각 호의 어느 하나에 해당하는 이용신청에 대해서는 위시켓이 승낙을 하지 않을 수 있습니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`자신의 책임 있는 사유에 의해 이용제한 기록이 있는 자가 이용신청을 하는 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`만 15세 미만인 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={3}
              >{`위시켓 통합회원 계정 내 정보 등 선행해 입력한 정보를 허위로 기재한 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={4}
              >{`위시켓의 이익을 저해하려는 목적으로 신청하는 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={5}
              >{`이전에 회원의 자격을 상실한 적이 있는 자로서 위시켓의 회원 재가입 승낙을 얻지 못한 경우`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>
            {`위시켓은 다음 각 호의 어느 하나에 해당하는 경우에는 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`서비스 관련 제반 용량의 부족함 등 위시켓이 보유한 설비에 여유가 없는 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`위시켓 혹은 신청자에게 기술상 장애 사유가 있는 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={3}
              >{`그 밖에 본 조에 준하는 사유로 이용신청의 승낙이 곤란한 경우`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>{`신청자의 이용신청에 대하여 위시켓이 승낙한 경우 회원이 본 약관을 위반하지 않은 이상 회원과 위시켓 간에 유효하게 서비스 이용계약이 체결된 것으로 간주합니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제8조 (서비스 이용시간)</SubTitle>
      <Description>
        <OrderedList>
          <li>
            {`위시켓은 연중무휴, 1일 24시간 사이트 및 서비스를 제공합니다. 다만, 위시켓은 서비스의 종류나 성질에 따라 제공하는 서비스 중 일부에 대해서는 별도로 이용시간을 정할 수 있으며, 이 경우 위시켓은 그 이용시간을 사전에 회원에게 공지 또는 통지합니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`위시켓의 인원이 관할하는 서비스 세부사항은 규정노동시간의 준수, 휴무 등의 조건으로 인해 공급보류 및 일시중지될 수 있습니다.`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>{`위시켓은 자료의 가공과 갱신을 위한 시스템 작업시간, 장애해결을 위한 보수작업 시간, 회선 장애 등이 발생한 경우 일시적으로 서비스를 중단할 수 있으며 계획된 작업의 경우 공지란에 서비스 중단 시간과 작업 내용을 알립니다. 다만, 위시켓이 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제9조 (서비스의 내용)</SubTitle>
      <Description>
        <OrderedList>
          <li>
            {`위시켓은 서비스 구축현황 및 본 약관에 준하여 이하 각 호의 서비스 세부사항을 회원에게 제공합니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`기업정보, 개인정보, 채용공고, 이력서 등 회원이 등록 및 공개를 승인한 정보의 조회 및 열람`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`사이트를 통한 회원 간 교류와 소통을 보조`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={3}
              >{`온라인 플랫폼 등 정보 제공 및 전달을 위한 기술적 제반여건의 제공`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={4}
              >{`온라인 입사지원 및 지원현황관리의 수단`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={5}
              >{`기타 위시켓이 추가 개발하거나 기업간 제휴 등을 통해 회원에게 제공하는 일체의 서비스`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>{`위시켓은 필요한 경우 서비스의 내용을 추가 또는 변경 할 수 있습니다. 단, 이 경우 위시켓은 추가 또는 변경내용을 회원에게 공지합니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제10조 (프로필의 등록 및 열람)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`개인회원은 약관동의 또는 로그인 후 프로필을 작성할 수 있으며 사이트에 등록한 프로필의 내용을 수정할 수 있습니다.`}</li>
          <li>{`개인회원은 기업회원이 등록한 채용공고에 대하여 의사 및 개인회원 본인의 프로필 중 정보를 전달하는 방식으로 입사지원을 할 수 있습니다. 개인회원은 프로필 중 인적사항, 경력사항 등을 사실과 같도록 기재하여야 하며, 프로필에 기재된 내용이 사실과 다른 경우 개인회원은 이로 인하여 발생하는 분쟁 및 손해배상에 대한 책임을 부담하여야 합니다.`}</li>
          <li>{`개인회원이 등록한 자료로 인하여 타인 또는 타 법인으로부터 허위사실 및 명예훼손 등으로 조치요청이 접수된 경우 위시켓은 개인회원에게 사전 통지 없이 본 자료를 삭제 또는 비공개 처리할 수 있으며, 처리 후 메일 등의 방법으로 개인회원에게 통지할 수 있습니다.`}</li>
          <li>{`안정적인 서비스 제공을 위한 테스트 및 모니터링, 고객문의 응대 등의 용도로 위시켓이 개인회원의 프로필을 열람할 수 있습니다.`}</li>
          <li>{`개인회원은 프로필의 공개 및 비공개 설정을 자유롭게 선택할 수 있습니다.`}</li>
          <li>
            {`개인회원이 프로필의 열람 및 채용공고 추천 제공을 희망하였을 경우, 위시켓은 개인회원이 다음 각 호의 사항에 대하여 동의한 것으로 보며, 채용공고 추천 서비스를 제공할 수 있습니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`위시켓 및 기업회원의 개인회원 프로필 열람`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`위시켓이 개인회원에게 적합한 입사지원 제안 및 채용공고 추천`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={3}
              >{`개인회원이 설정한 수단을 통한 위시켓의 채용공고의 전달 및 입사지원 제안`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>
            {`다음 각 호의 하나에 해당할 경우 위시켓은 개인회원의 프로필의 열람 및 채용공고 추천을 중지합니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`개인회원의 계정이 위시켓 이용약관 제20조 제2항에 의하여 휴면계정으로 되었을 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`개인회원이 프로필 설정을 비공개로 지정하였을 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={3}
              >{`개인회원이 서비스 이용계약 해지 등으로 회원자격을 상실하였을 경우`}</CustomOrderLi>
            </UnStyledList>
          </li>
        </OrderedList>
      </Description>
      <SubTitle>제11조 (포인트 및 취업축하금의 규정)</SubTitle>
      <Description>
        <OrderedList>
          <li>
            {`위시켓은 취업축하금 및 포인트를 위시켓이 정한 정책에 따라 개인회원에게 지급합니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`정책은 약관 외 별도의 사이트 내 화면을 통해 공지합니다.`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`정책은 적립 및 지급 기준, 지급 조건 및 제한 등의 내용이 달라질 수 있습니다.`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={3}
              >{`변경 및 갱신된 정책은 개인회원이 이전 정책에 준해 적립한 포인트 및 취업측하금에 소급적용되지 않습니다.`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>{`개인회원이 포인트 정책 중 지급기준에 부합하는 상태인 동시에 누적한 포인트를 보유할 때 위시켓은 포인트 정책에 준해 정한 금액을 개인회원에게 지급합니다.`}</li>
          <li>{`각 포인트는 적립시점의 포인트 정책에 준하여 개인회원에게 지급됩니다.`}</li>
          <li>{`취업축하금은 기업회원이 해당 취업축하금 지급을 위해 위시켓에 예치한 금액을 한도로 합니다.`}</li>
          <li>
            {`위시켓은 이하 각 호가 정한 조건에 개인회원이 부합할 때 개인회원에게 취업축하금을 지급합니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`개인회원이 사이트에 등록한 채용공고를 통해 기업회원의 기업에 3개월(90일) 간 재직했으며 위시켓이 정한 자료를 위시켓에게 제출하여 위시켓이 개인회원의 재직현황 증빙 및 지급관련정보를 확인할 수 있을 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`개인회원이 사이트에 등록한 채용공고를 통해 기업회원의 기업에 기업회원이 설정한 기간 이상 재직했으며 위시켓이 정한 자료를 위시켓에게 제출하여 위시켓이 개인회원의 재직현황 증빙 및 지급관련정보를 확인할 수 있을 경우`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>{`개인회원은 기업회원이 사이트에 등록한 채용공고를 통해 입사한 기업에 재직중이며 24개월 이내에 위시켓에게 포인트 및 취업축하금의 정산 및 지급을 신청을 할 수 있습니다.`}</li>
          <li>{`위시켓은 본 조가 정한 조건에 개인회원이 부합할 경우 개인회원의 포인트 및 취업축하금 지급신청을 확인한 시점으로부터 2개월(60일) 이내에 개인회원이 누적한 포인트에 준하는 금액 및 취업축하금을 개인회원에게 지급합니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제12조 (유료 서비스의 규정)</SubTitle>
      <Description>
        <OrderedList>
          <li>
            {`위시켓이 제공하는 서비스의 세부사항은 위시켓의 정책에 준하여 유료로 제공될 수 있습니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`정책은 약관 외 별도의 사이트 내 화면을 통해 공지합니다.`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`정책은 유료 여부, 서비스 당 책정 금액 등의 내용이 달라질 수 있으며 정책이 변경될 시 위시켓은 변경에 대해 적용일자 및 개정내용, 개정 사유 등을 명시하여 그 적용일자로부터 최소한 7일 이전, 회원에게 불리하거나 중대한 사항의 변경은 30일 이전부터 그 적용일자 경과 후 상당한 기간이 경과할 때까지 사이트 내 화면을 통해 공지합니다.`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={3}
              >{`정책 대상 회원에게 변경사항에 대해 공지합니다.`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>{`변경 및 갱신된 정책은 회원이 이전 정책에 준해 이용중인 유료 서비스에 소급적용하지 않습니다.`}</li>
          <li>{`변경 및 갱신된 정책은 회원이 이전 정책에 준해 이용중인 무료 서비스의 이용기간을 변경 및 갱신된 정책의 도입 이후 일정 시점까지로 한정할 수 있으며 위시켓은 한정하는 시점을 정책 대상 회원에게 공지합니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제13조 (이용요금 오류의 조정)</SubTitle>
      <Description>
        <OrderedList>
          <li>
            {`회원이 지급한 이용요금의 과오납 등 사유가 있는 경우 위시켓은 다음 각 호에 해당하는 조치를 취합니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`과다 납입한 이용요금에 대해서는 과다 납입된 금액을 환급하며 회원이 동의할 경우 다음 달에 청구될 이용요금에서 해당 금액을 차감하여 청구합니다.`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`이용요금을 환급 받아야 할 개인회원이 체납한 이용요금이 있을 경우 환급해야 할 이용요금에서 체납된 이용요금을 우선 공제하고 남은 금액을 반환합니다.`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={3}
              >{`이용요금을 과소 청구한 경우 위시켓은 회원에게 과소 청구된 금액을 합산하여 다음달 이용요금과 함께 청구하며 다음달 청구할 이용요금이 없을 경우 이용요금이 과소 청구되었음을 확인한 즉시 청구합니다.`}</CustomOrderLi>
            </UnStyledList>
          </li>
        </OrderedList>
      </Description>
      <SubTitle>제14조 (이용요금의 환불)</SubTitle>
      <Description>
        <OrderedList>
          <li>
            {`위시켓은 다음 각 호에 경우에는 이용요금을 환불합니다. 다만, B호 및 C호의 하나에 해당하면서 유료 상품이나 유료 서비스의 이용이 정상적으로 가능하였던 일부 기간이 있을 경우, 해당 기간에 상응하는 이용요금을 공제한 나머지 금액을 환불합니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`유료 상품이나 유료 서비스의 이용이 개시되지 않은 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`위시켓의 네트워크 또는 시스템 장애로 서비스 이용이 불가능한 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={3}
              >{`위시켓의 귀책사유로 인하여 유료 서비스의 이용이 어려운 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={4}
              >{`기타 약관으로 정하는 경우`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>
            {`다음 각 호에 해당할 경우 위시켓은 회원에게 이용요금을 환불하지 않습니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`위시켓이 본 약관 21조 4항에 따라 제재조치를 취한 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`기타 이용요금의 환불이 적절하지 않은 것으로 약관이 정하는 경우`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>{`이용요금의 환불을 요청하는 회원은 환불 사유를 명시한 서면으로 위시켓에 이용요금의 환불을 요청(이하 “환불요청”이라 합니다)하여야 합니다.`}</li>
          <li>{`위시켓은 회원으로부터 환불요청을 받은 날로부터 3영업일 이내에 개인회원의 환불요청이 위시켓이 정한 환불 조건을 갖추었는지 여부를 판단하여 개인회원에게 그 내용을 통지합니다.`}</li>
          <li>{`회원의 환불 요청이 환불 조건에 부합하는 것으로 판단될 경우 위시켓은 4항의 통지일로부터 3영업일 이내에 약관이 정한 금액을 환불합니다.`}</li>
          <li>{`기타 이용요금의 환불 관련 상세 사항은 약관이 정하는 바에 따릅니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제15조 (정보의 제공 및 광고의 게재)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`위시켓은 회원에게 서비스 이용에 필요한 사항을 알리거나 각종 상품∙서비스 소개, 홍보 등의 목적을 위한 정보를 우편물, 이메일이나 어플리케이션 푸쉬 알림, 모바일 장치 IoT 등 신종 기술 및 기기를 이용한 방법으로 제공할 수 있습니다.`}</li>
          <li>{`위시켓은 서비스와 관련되는 정보 또는 광고를 서비스 화면, 웹사이트, 전자우편 등에 게재할 수 있습니다.`}</li>
          <li>{`광고가 게재된 전자우편 등 유인물을 수신한 회원은 위시켓에게 수신거절을 의뢰할 수 있으며 수신거절의사를 확인한 위시켓은 대상 회원에게 광고를 포함한 유인물의 발송을 즉시 중지합니다.`}</li>
          <li>{`위시켓은 사이트 등에 게재되어 있는 기업회원 등 광고주의 판촉활동에 회원이 참여하거나 교신 또는 거래를 함으로써 발생하는 모든 손실과 손해에 대해 일체의 책임을 지지 않습니다.`}</li>
          <li>{`약관동의 및 서비스 이용 시 사이트 등에 노출되는 광고 게재에 대해 회원이 동의하는 것으로 간주합니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제16조 (게시물 작성과 정보 수정, 삭제 등 권한)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`게시물 등의 내용이 사실이 아니거나 부정확하게 작성되어 발생하는 모든 책임은 전적으로 게시물 등을 등록한 회원에게 있습니다. 위시켓은 검증에 노력을 기울이나 회원이 작성한 게시물 등의 정확성 및 진실성을 보증하지는 않으며 책임을 부담하지 않습니다.`}</li>
          <li>{`모든 게시물 등의 작성 및 관리는 게시물 등을 작성한 회원 본인이 하는 것이 원칙입니다. 회원의 사정상 위탁 또는 대행관리를 하더라도 게시물 등으로 발생하는 책임은 회원에게 있으며 회원은 주기적으로 자신의 자료를 확인하여 항상 정확성을 유지하도록 관리해야 합니다.`}</li>
          <li>
            {`위시켓은 회원이 작성한 게시물 등이 다음 각 호에 해당한다고 판단되는 경우 이를 삭제 또는 비공개 처리하거나 게시를 거부할 수 있습니다.`}
            <UnStyledList>
              <CustomOrderLi orderNumber={1}>{`음란 게시물`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`청소년에게 유해한 게시물`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={3}
              >{`차별∙갈등을 조장하는 게시물`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={4}
              >{`도배 광고 또는 홍보 스팸성 게시물`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={5}
              >{`계정의 양도나 거래를 내용으로 하는 게시물`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={6}
              >{`타인을 사칭하는 게시물`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={7}
              >{`기타 법령에 위반되거나 그에 준하는 게시물`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>{`회원이 등록한 게시물 등으로 인해 제3자로부터 허위사실 또는 명예훼손 등을 이유로 조치 요청이 접수된 경우 또는 회원이 등록한 게시물 등으로 인하여 사이트 등의 원활한 운영에 영향을 미친다고 판단되는 경우, 위시켓은 해당 게시물 등을 직권으로 삭제 또는 비공개 처리할 수 있으며 회원에게 처리 사실 및 사유를 사후 통지할 수 있습니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제17조 (자료 내용의 활용 및 온라인 입사 지원 정보)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`개인회원이 사이트에 등록한 프로필은 채용 및 고용 동향의 분석 자료 제작을 위한 기초자료가 되어 위시켓에 의해 활용될 수 있으며 프로필을 기반으로 만들어진 당 자료는 매체를 통해 언론에 배포될 수 있습니다. 다만, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보 보호법 등 관련 법령에 따른 개인정보는 채용 관련 정보 제공 및 활용대상에서 제외됩니다.`}</li>
          <li>{`위시켓은 사이트 등의 시스템을 통해 지원한 회원의 정보 열람 여부 및 채용공고에 입사 지원한 구직자들의 각종 통계 데이터를 회원에게 제공할 수 있습니다.`}</li>
          <li>
            {`사이트 내 입사지원 및 기타 서비스 이용 과정에서 정당한 절차 및 정보제공 회원의 동의를 거쳐 공개되고 기업회원이 열람한 개인회원의 정보는 해당 기업회원 기업의 인사자료로 쓰일 수 있습니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`기업회원은 3항으로 인해 취득한 개인회원의 정보를 본 약관이 정의하는 목적 및 서비스 본연의 목적인 인사절차 외 용처에 사용할 수 없으며, 채용 등 인사절차의 발생시점 이후 3개월(90일) 이내 파기해야 합니다.`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`기업회원이 채용 후 인사관리 등 본 약관과 서비스가 정의하는 인력중개 외의 목적으로 개인회원의 개인정보를 수집할 경우, 기업회원은 본 약관과 별개로 개인회원의 정보제공동의를 받아 개인회원의 정보를 수집해야 하며 위시켓은 당 계약 및 정보에 관련하지 않습니다.`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>{`위시켓은 사이트 등에서 이용자가 제공에 동의한 정보에 대해 이용자의 서비스 편의를 목적으로 정보를 가공하여 회원에 제공할 수 있습니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제18조 (제휴를 통한 서비스)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`회원의 프로필은 회사가 개인회원에게 별도의 동의절차를 거쳐 회사의 제휴매체(웹사이트, 신문, 잡지 등의 온∙오프라인 매체)에게 열람될 수 있습니다.`}</li>
          <li>{`1항의 서비스 제공을 위한 개인회원에 대한 사전 고지 및 동의절차, 제휴매체 목록의 공지 등에 대한 세부내용은 개인정보 처리방침의 개인회원 자료 활용 정책을 따릅니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제19조 (위시켓의 의무)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`위시켓은 본 약관에서 정한 내용에 따라 계속적, 안정적으로 서비스를 제공할 수 있도록 최선을 다하여 노력합니다.`}</li>
          <li>{`위시켓은 서비스 이용이나 운영과 관련된 회원의 불만사항이 접수되는 경우, 이를 즉시 처리하여 그 결과를 회신할 수 있도록 노력합니다. 즉시 처리가 어려운 경우에는 그 사유와 처리 일정을 서비스 화면 또는 기타 방법을 통해 회원에게 통지합니다.`}</li>
          <li>{`위시켓은 유료 결제와 관련한 결제사항 정보를 5년간 보존합니다. 단 회원 자격이 없는 이용자는 예외로 합니다.`}</li>
          <li>{`위시켓은 천재지변 등 예측하지 못한 일이 발생하거나 시스템의 장애가 발생하여 서비스가 중단될 경우 이에 대한 손해에 대해서는 책임을 지지 않습니다. 다만 자료의 복구나 정상적인 서비스 지원이 되도록 최선을 다할 의무를 가집니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제20조 (회원의 의무)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`회원은 관계법령과 본 약관의 규정, 위시켓의 서비스 운영정책, 기타 고지된 서비스 이용상의 유의 사항을 준수하여야 하며 기타 위시켓의 업무에 지장을 초래하는 행위를 하여서는 안됩니다.`}</li>
          <li>{`회원이 신청한 유료 서비스는 등록 또는 신청과 동시에 위시켓과 채권, 채무 관계가 발생하며 회원은 이용요금을 위시켓이 지정한 기일 내에 납부하여야 합니다.`}</li>
          <li>{`회원이 결제 수단으로 신용카드를 사용할 경우 비밀번호 등의 관리책임은 회원에게 있습니다. 단, 사이트의 결함에 따른 정보유실 발생에 대한 책임은 회원이 부담하지 않습니다.`}</li>
          <li>{`회원은 서비스를 이용하여 얻은 정보를 위시켓의 사전동의 없이 복사, 복제, 번역, 출판, 방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.`}</li>
          <li>
            {`회원은 본 서비스를 구직 이외의 목적으로 사용할 수 없으며 다음 각 호의 행위를 하여서는 안됩니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`다른 회원의 아이디를 부정 사용하는 행위`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={3}
              >{`타인의 명예를 훼손하거나 모욕하는 행위`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={4}
              >{`타인의 지적재산권 등의 권리를 침해하는 행위`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={5}
              >{`해킹행위 또는 바이러스의 유포 행위`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={6}
              >{`타인의 의사에 반하여 광고성 정보 등 일정한 내용을 계속적으로 전송하는 행위`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={7}
              >{`서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있다고 판단되는 행위`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={8}
              >{`사이트 등의 정보 및 서비스를 이용한 영리 행위`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={9}
              >{`그 외 약관사항을 위반하는 행위`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={10}
              >{`그 밖에 선량한 풍속, 기타 사회질서를 해하거나 관계법령에 위반하는 행위`}</CustomOrderLi>
            </UnStyledList>
          </li>
        </OrderedList>
      </Description>
      <SubTitle>제21조 (이용계약의 해지, 제한, 자료의 삭제)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`회원이 서비스 이용계약을 해지하고자 할 경우, 위시켓 고객센터를 이용해 위시켓에 대한 서비스 이용계약 해지를 신청을 합니다. 위시켓은 회원의 해지사유를 확인하는 즉시 서비스 이용계약 해지에 필요한 조치를 취합니다.`}</li>
          <li>{`기업회원은 서비스 이용계약을 해지에 앞서 현행하는 채용공고의 중지, 위시켓과의 계약사항의 종결, 미수금 해소를 해야 하며 이행치 않을 경우 서비스 이용계약은 해지될 수 없습니다.`}</li>
          <li>{`회원이 서비스 이용계약을 해지한 경우 위시켓은 해지 즉시 회원이 채용중개 서비스 이용 시 입력한 모든 정보를 파기합니다. 다만, 관련 법령 및 개인정보 처리방침에 따라 위시켓이 회원 정보를 보유할 수 있는 경우는 보유 목적에 필요한 최소한의 정보를 보관할 수 있습니다.`}</li>
          <li>
            {`회원이 다음의 사항에 해당하는 경우 위시켓은 회원의 사전 동의를 얻지 않고 서비스 이용 제한 또는 회원의 자격박탈 등의 조치를 취할 수 있습니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`유료 서비스 이용 요금을 납부하지 않았을 때`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`7조 2항의 각 호 중 어느 하나에 해당하는 행위를 했음을 발견했을 때`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={3}
              >{`20조 5항의 각 호 중 어느 하나에 해당하는 행위를 했음을 발견했을 때`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={4}
              >{`서비스에서 제공되는 정보를 유용하였을 때`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={5}
              >{`회원이 등록한 게시물 등의 내용이 사실과 다르거나 조작되었을 때`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={6}
              >{`관련 법규에 위반하거나 선량한 풍속 기타 사회통념상 허용되지 않는 행위를 하였을 때`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={7}
              >{`약관사항을 위반할 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={8}
              >{`기타 위시켓의 명예를 훼손하였거나 위시켓이 판단하기에 적합하지 않은 목적으로 회원가입을 하였을 때`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>{`회원은 3항에 따른 위시켓의 제재조치에 대하여 이의 사유 및 증빙자료를 제출하는 방식으로 위시켓에게 이의를 신청할 수 있습니다. 회원의 이의 신청을 접수한 경우 위시켓은 제출된 이의 사유 및 증빙자료 기타 관련 사항을 신중히 검토하여 이의 신청에 대한 판단을 하고 그 결과를 통지합니다. 이에 대한 상세한 내용은 개인정보 처리방침을 확인 바랍니다.`}</li>
          <li>{`개인회원이 유료 서비스를 이용하는 중 위시켓의 책임에 의해 정상적인 서비스가 제공되지 않을 경우 회원은 유료 서비스의 해지를 요청할 수 있으며 위시켓은 해지일까지 이용일수를 1일 기준금액으로 계산하여 이용금액을 공제 후 환급합니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제22조 (손해배상 및 면책)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`본 약관에서 다른 정함이 없는 한, 제공하는 모든 서비스와 관련하여 위시켓의 고의 또는 중대한 과실로 인하여 회원에게 손해가 발생한 경우 위시켓은 그 손해를 배상하여야 합니다.`}</li>
          <li>{`위시켓은 개인정보 처리방침에서 정하는 내용 위반으로 회원의 손해가 발생한 경우 위반사항 시정 및 대상 회원에 대한 손해배상에 임합니다.`}</li>
          <li>{`회원이 본 약관을 위반한 행위로 위시켓, 타 회원, 제3자에게 손해를 입히거나 분쟁을 발생시킨 경우, 또는 회원에게 책임이 있는 행위로 인해 위시켓, 타 회원, 제3자에게 손해를 입히거나 분쟁을 발생시킨 경우 회원은 그 분쟁에 대응하며 손해를 배상하여야 합니다.`}</li>
          <li>{`위시켓은 개인회원의 서비스를 통한 구직활동에 대하여 어떠한 책임도 부담하지 않습니다.`}</li>
          <li>{`위시켓은 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우 서비스 제공에 관한 책임이 면제됩니다.`}</li>
          <li>{`위시켓은 서비스 이용과 관련하여 회원에게 발생한 손해 가운데 회원의 고의 또는 과실로 인한 서비스 이용의 장애 및 손해에 대하여 어떠한 책임도 부담하지 않습니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제23조 (개인정보의 보호 및 관리)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`위시켓은 회원에게 서비스를 제공하는 과정에서 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법 등 관련법령이 규정한 절차와 방법에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 이용에 대해서는 관련법령 및 회사의 개인정보 처리방침이 적용됩니다.`}</li>
          <li>{`서비스 외의 단순히 링크된 제3자 제공의 서비스에 대하여는 위시켓의 개인정보 처리방침이 적용되지 않습니다.`}</li>
          <li>{`위시켓은 회원의 귀책사유로 인하여 노출된 회원의 회원정보를 포함한 모든 정보에 대해서 일체의 책임을 지지 않습니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제24조 (신용정보의 제공활용 동의)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`위시켓이 회원가입 및 서비스 이용과 관련하여 취득한 회원의 개인신용정보를 타인에게 제공하거나 활용하고자 할 때에는 신용정보의 이용 및 보호에 관한 법률 제23조의 규정에 따라 사전에 그 사유 및 해당기관 또는 업체명 등을 밝히고 해당 회원의 동의를 얻어야 합니다.`}</li>
          <li>{`서비스와 관련하여 신용정보의 이용 및 보호에 관한 법률에 따라 위시켓이 타인에게 제공 활용에 대한 동의를 회원에게 얻은 경우, 회원은 위시켓이 신용정보 사업자 또는 신용정보 집중기관에 정보를 제공하여 회원의 신용을 판단하기 위한 자료로 활용하거나, 공공기관에서 정책자료로 활용되도록 정보를 제공하는 데 동의한 것으로 간주합니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제25조 (분쟁의 해결)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`서비스 제공과 관련하여 위시켓과 회원 사이에 분쟁이 발생한 경우, 위시켓과 회원은 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다.`}</li>
          <li>{`발생한 분쟁에 대하여 소송이 제기될 경우에는 당사자의 주소지를 관할하는 법원을 관할 법원으로 합니다. 다만 본 조의 규정은 위시켓 회원간 서비스 계약에 의하여 중재로 해결하는 경우에는 적용되지 아니합니다.`}</li>
        </OrderedList>
      </Description>
      <Title>부칙</Title>
      <Description>본 약관은 2022년 8월 16일 부터 시행됩니다.</Description>
    </Box>
  );
};

export default WishketJobsServiceTerms;
