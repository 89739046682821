import { Box, Divider, Typography } from '@wishket/design-system';

import {
  BulletList,
  CustomOrderLi,
  Description,
  DescriptionLink,
  InformationBox,
  OrderedList,
  SubTitle,
  Title,
  UnOrderedList,
  UnStyledList,
} from '../Terms.parts';

const WishketServiceTerms = () => {
  return (
    <Box className="flex w-full flex-col gap-8 break-all pt-8">
      <Title>목차</Title>
      <Box className="flex flex-col gap-2">
        <DescriptionLink href="#service_contract">
          제1장 서비스 이용계약
        </DescriptionLink>
        <DescriptionLink href="#wishket_escrow">
          제2장 대금보호시스템
        </DescriptionLink>
        <DescriptionLink href="#site_policy">
          제3장 안심중재솔루션
        </DescriptionLink>
        <DescriptionLink href="#addendum">부칙</DescriptionLink>
        <Description>최종 약관 시행일자 : 2024년 03월 25일</Description>
      </Box>
      <Divider />
      <Box>
        <Description>
          본 약관은 전기통신사업법령 및 정보통신이용촉진 및 정보보호 등에 관한
          법률 및 공정거래위원회 표준 약관을 기초로 합니다. 주식회사 위시켓에서
          운영하는 모든 웹사이트는 개별적인 이용약관이 없는 한 본 약관을
          준용합니다.
        </Description>
      </Box>
      <Title id="service_contract">제1장 서비스 이용</Title>
      <SubTitle>제1조 (목적)</SubTitle>
      <Description>
        {`본 약관은 주식회사 위시켓(이하 "위시켓"이라 합니다)이 회원에게 제공하는 서비스의 이용과 관련하여 위시켓과 회원 간의 권리, 의무, 책임사항 및 기타 필요한 사항을 규정함을 목적으로 합니다.`}
      </Description>
      <SubTitle>제2조 (정의)</SubTitle>
      <Description>
        본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
        <UnStyledList>
          <CustomOrderLi orderNumber={1}>
            {`“회원“은 본 약관에 동의하고 위시켓 플랫폼(위시켓 홈페이지`}
            <DescriptionLink href="https://www.wishket.com" isNewWindow>
              (www.wishket.com)
            </DescriptionLink>
            {`를 포함한 위시켓이 관리/운영하는 웹사이트 및 어플리케이션 등 서비스 플랫폼 일체를 의미함)의 회원가입 절차를 완료한 자를 의미합니다.`}
          </CustomOrderLi>
          <CustomOrderLi orderNumber={2}>
            {`“클라이언트“는 회원 중 프로젝트를 의뢰하기 위하여 회원이 된 자 또는 특정 프로젝트에서 프로젝트를 의뢰한 자를 의미합니다.`}
          </CustomOrderLi>
          <CustomOrderLi orderNumber={3}>
            {`“파트너”는 회원 중 클라이언트의 의뢰를 받아 프로젝트를 수행하기 위하여 회원이 된 자 또는 특정 프로젝트에서 클라이언트의 의뢰를 받아 업무를 수행하는 자를 의미합니다.`}
          </CustomOrderLi>
          <CustomOrderLi orderNumber={4}>
            {`“서비스“는 회원들이 적합한 거래 상대방을 찾을 수 있도록 회원이 스스로 작성한 정보를 다른 회원이 볼 수 있도록 위시켓 홈페이지에 게시할 수 있도록 하는 온라인 서비스, 위시켓 회원간 서비스 계약의 체결 중개 및 대금보호시스템 등을 제공하는 오프라인 서비스를 의미합니다.`}
          </CustomOrderLi>
          <CustomOrderLi orderNumber={5}>
            {`“계정(ID)“이라 함은 회원의 식별과 서비스 이용을 위하여 회원이 선정하고 위시켓이 부여하는 문자, 숫자 또는 특수 문자의 조합을 의미합니다.`}
          </CustomOrderLi>
          <CustomOrderLi orderNumber={6}>
            {`“비밀번호“라 함은 회원이 부여받은 계정(ID)과 일치되는 회원임을 확인하고 회원의 정보 및 권익보호를 위해 회원 자신이 선정하여 비밀로 관리하는 문자, 숫자 또는 특수 문자의 조합을 의미합니다.`}
          </CustomOrderLi>
          <CustomOrderLi orderNumber={7}>
            {`“계정정보“라 함은 회원의 계정(ID), 비밀번호, 성명 등 회원이 위시켓에 제공한 일반정보 및 서비스 이용정보 등 생성 정보를 통칭합니다.`}
          </CustomOrderLi>
        </UnStyledList>
      </Description>
      <SubTitle>제3조 (약관의 명시와 개정)</SubTitle>
      <Description>
        <OrderedList>
          <li>
            {`위시켓은 이 약관의 내용을 회원이 알 수 있도록 위시켓 플랫폼에 게시하거나 연결화면을 제공하는 방법으로 회원에게 공지합니다.`}
          </li>
          <li>
            {`위시켓은 회원이 위시켓과 이 약관의 내용에 관하여 질의 및 응답을 할 수 있도록 조치를 취합니다.`}
          </li>
          <li>
            {`위시켓은 「약관의 규제에 관한 법률」 등 관련 법령에 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.`}
          </li>
          <li>
            {`위시켓은 약관을 개정할 경우에는 적용일자 및 개정내용, 개정 사유 등을 명시하여 그 적용일자로부터 최소한 7일 이전(회원에게 불리하거나 중대한 사항의 변경은 30일 이전)부터 그 적용일자 경과 후 상당한 기간이 경과할 때까지 초기화면 또는 초기화면과의 연결화면을 통해 공지합니다.`}
          </li>
          <li>
            {`위시켓은 약관을 개정할 경우에는 원칙적으로 개정약관 공지 후 개정약관의 적용에 대한 회원의 동의 여부를 확인합니다. 다만 개정약관 공지 시 회원이 동의 또는 거부의 의사표시를 하지 않으면 승낙한 것으로 간주하겠다는 내용도 함께 공지한 경우에는 회원이 약관 시행일까지 거부의사를 표시하지 않는다면 개정약관에 동의한 것으로 간주할 수 있습니다.`}
          </li>
          <li>
            {`회원은 개정약관의 적용을 거부할 수 있으며, 이 경우 회원은 회원 탈퇴를 거쳐 위시켓 서비스 이용을 중단할 수 있습니다.`}
          </li>
          <li>
            {`변경된 약관이 시행된 이후에도 계속 위시켓이 제공하는 서비스를 이용하는 경우에는 회원이 변경된 약관에 동의한 것으로 봅니다.`}
          </li>
        </OrderedList>
      </Description>
      <SubTitle>제4조 (회원가입)</SubTitle>
      <Description>
        <OrderedList>
          <li>
            {`회원이 되려고 하는 자는 약관의 내용에 대하여 동의를 하고, 위시켓 플랫폼의 회원가입 페이지에서 제공하는 양식을 작성하여 회원가입 하는 방법으로 이용신청을 하여야 합니다.`}
          </li>
          <li>
            {`제1항의 회원가입이 완료된 이후에도 클라이언트가 프로젝트를 등록하거나, 파트너가 프로젝트에 지원하는 등 위시켓이 제공하는 서비스를 이용하기 위하여 성명, 법인명, 핸드폰 번호, 회사 형태, 파트너스 정보, 자기 소개, 보유 기술, 포트폴리오 등 추가적인 정보를 입력하여야 하며, 추가적인 정보를 입력하지 아니한 경우 서비스 이용이 제한될 수 있습니다.`}
          </li>
          <li>
            {`위시켓은 회원이 되려고 하는 자에게 실명확인 및 본인인증, 기타 필요한 정보를 요구할 수 있으며, 그 절차와 방식은 관련 법령에 따릅니다.`}
          </li>
          <li>
            {`회원이 되려고 하는 자는 이용 신청 시 제1항 및 제2항에서 요구하는 정보 항목에 대하여 실제 정보를 기재하여야 합니다. 실명 또는 정보를 허위로 기재하거나 타인의 명의를 도용한 경우 이 약관에 의한 회원의 권리를 주장할 수 없고, 위시켓은 환급 없이 서비스 이용계약을 취소하거나 해지할 수 있습니다.`}
          </li>
          <li>
            {`회원가입은 회원의 이용 약관에 대한 동의와 회원의 이용 신청에 대한 위시켓의 승낙으로 성립합니다. 이용 약관에 관한 동의는 이용 신청 당시 위시켓 이용약관에 동의함을 선택하거나 기타 동의 버튼 또는 서명 등을 통해 의사표시를 합니다.`}
          </li>
          <li>
            {`각 회원은 가입시 입력한 당사자 정보가 변경되는 경우 이를 즉시 변경 입력하여야 하며, 이러한 변경이 없는 경우 변경 전의 정보로 이루어진 통지 및 연락 등에 의해 발생한 손해는 변경하지 않은 회원의 책임으로 합니다.`}
          </li>
        </OrderedList>
      </Description>
      <SubTitle>제5조 (이용신청의 승낙과 제한)</SubTitle>
      <Description>
        <OrderedList>
          <li>
            {`회원이 되려는 자가 제4조에서 정하는 바에 따라 정당히 이용신청을 한 경우에 상당한 이유가 없는 한 위시켓은 이용신청을 승낙합니다.`}
          </li>
          <li>
            {`위시켓은 다음 각 호의 어느 하나에 해당하는 이용신청에 대해서는 승낙을 하지 않을 수 있습니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`제4조에 위반하여 이용신청을 하는 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`자신의 책임 있는 사유에 의한 이용제한 기록이 있는 자가 이용신청을 하는 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={3}
              >{`법령에서 금지하는 위법행위를 할 목적으로 이용신청을 하는 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={4}
              >{`위시켓의 이익을 저해하려는 목적으로 신청하는 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={5}
              >{`이전에 회원의 자격을 상실한 적이 있는 자로서 위시켓의 회원 재가입 승낙을 얻지 못한 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={6}
              >{`그 밖에 1호 내지 5호에 준하는 사유로서 승낙이 부적절하다고 판단되는 경우`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>
            {`위시켓은 다음 각 호의 어느 하나에 해당하는 경우에는 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`서비스 관련 제반 용량의 부족함 등 위시켓의 설비에 여유가 없는 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`기술상 장애 사유가 있는 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={3}
              >{`그 밖에 위 각 호에 준하는 사유로 이용신청의 승낙이 곤란한 경우`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>
            {`회원이 한 제5조의 이용신청에 대하여 위시켓이 승낙한 경우 회원이 본 약관 및 관련 법령을 위반하지 않은 이상 회원과 위시켓 간에 유효하게 서비스 이용계약이 체결된 것으로 봅니다.`}
          </li>
        </OrderedList>
      </Description>
      <SubTitle>제6조 (회원 계정(ID) 및 비밀번호)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`위시켓은 회원에 대하여 계정(ID)을 부여합니다.`}</li>
          <li>
            {`위시켓은 계정정보를 통하여 당해 회원의 서비스 이용가능 여부 등을 판단하는 등 제반 회원 관리업무를 수행할 권한이 있으며, 다음과 같은 이유로 회원의 아이디 및 비밀번호 변경을 요구하거나 변경 할 수 있습니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`회원 아이디가 회원의 이메일, 전화번호 등으로 등록되어 사생활 침해가 우려되는 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`타인에게 혐오감을 주거나 미풍양속에 저해할 목적으로 신청한 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={3}
              >{`보안 및 아이디 정책, 서비스의 원활한 제공 등의 목적으로 변경할 필요성이 있는 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={4}
              >{`기타 위시켓이 필요하다고 인정되는 경우`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>{`회원은 자신의 계정정보를 선량한 관리자로서의 주의 의무를 다하여 관리하여야 합니다. 회원이 본인의 계정정보를 소홀히 관리하거나 제3자에게 이용을 승낙함으로써 발생하는 손해에 대하여는 회원에게 책임이 있으며 위시켓의 고의 또는 과실이 없는 한 위시켓은 책임을 부담하지 않습니다.`}</li>
          <li>{`비밀번호의 관리책임은 회원에게 있으며, 회원이 원하는 경우에는 보안상의 이유 등으로 언제든지 변경이 가능합니다.`}</li>
          <li>{`회원은 정기적으로 비밀번호를 변경하여야 합니다.`}</li>
          <li>{`회원은 계정 정보가 도용되거나 제3자가 이를 사용하고 있음을 인지한 경우 이를 즉시 위시켓에 통지하고 위시켓의 안내에 따라야 합니다. 회원이 이를 인지하고서도 통지하지 않거나, 통지한 경우에도 위시켓의 안내에 따르지 않아 발생한 피해에 대해 위시켓은 고의 또는 과실이 없는 한 책임지지 않습니다.`}</li>
          <li>{`회원은 매 접속 종료 시 확실히 로그아웃을 하여야 합니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제7조 (회원 정보의 제공 및 변경)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`회원은 본 약관에 의하여 위시켓에 정보를 제공하여야 하는 경우에는 진실된 정보를 제공하여야 하며, 허위정보 제공으로 인해 발생한 불이익에 대해서는 보호받지 못합니다.`}</li>
          <li>{`회원은 위시켓 플랫폼을 통하여 언제든지 자신의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 실명, 계정(ID) 등은 수정이 제한될 수 있습니다. 이러한 경우에는 새로운 계정(ID)으로 재가입해야 하며, 회원탈퇴한 계정(ID)은 재가입이 제한됩니다.`}</li>
          <li>{`회원은 위시켓에 제공한 정보가 변경되었을 경우 지체 없이 온라인으로 수정을 하거나 스스로 수정이 불가능한 경우 위시켓에 대하여 그 변경사항을 알려야 합니다.`}</li>
          <li>{`회원이 회원정보의 변경사항을 제2항에 의하여 수정하지 아니하거나 제3항에 의하여 위시켓에 알리지 아니하여 발생한 불이익에 대하여 위시켓은 책임을 지지 아니하며, 수정하지 아니하여 발생하는 문제의 책임은 회원에게 있습니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제8조 (개인정보의 보호 및 관리)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`위시켓은 관련 법령이 정하는 바에 따라 계정정보 등을 포함한 회원의 개인정보를 보호하기 위해 노력합니다. 회원의 개인정보의 보호 및 사용에 대해서는 관련 법령 및 위시켓이 별도로 고지하는 개인정보 처리방침이 적용됩니다.`}</li>
          <li>{`위시켓은 회원이 안전하게 서비스를 이용할 수 있도록 개인정보, 신용정보의 보호를 위해 보안시스템을 갖추어야 하며 개인정보 처리방침을 공시하고 준수합니다. 위시켓은 관련 법령, 본 약관 및 개인정보 처리방침에서 정한 경우를 제외하고는 회원의 개인정보가 제3자에게 공개 또는 제공되지 않도록 합니다.`}</li>
          <li>{`위시켓은 회원의 귀책사유로 인하여 노출된 회원의 계정정보를 포함한 타인의 모든 개인정보, 비밀정보에 대해서 일체의 책임을 지지 않습니다.`}</li>
          <li>{`위시켓은 플랫폼 내 서비스를 확장ᆞ추가하는 경우 회원의 개인정보 및 데이터를 이전할 수 있고, 이전한 개인정보 및 데이터를 이용할 수 있습니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제9조 (위시켓의 의무)</SubTitle>
      <Description>
        <OrderedList>
          <li>
            {`위시켓은 관련 법령과 본 약관이 정하는 권리의 행사와 의무의 이행을 신의에 좇아 성실하게 하여야 합니다.`}
          </li>
          <li>
            {`위시켓은 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선을 하던 중 설비에 장애가 생기거나 데이터 등이 멸실된 때에는 천재지변, 비상사태, 해결이 곤란한 기술적 결함 등 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을 다합니다. 다만 협력사의 설비에 장애가 생기거나 협력사의 고의 또는 과실로 인하여 데이터 등이 멸실된 때에는 위시켓에 고의 또는 중과실이 없는 한 위시켓은 면책됩니다.`}
          </li>
          <li>
            {`위시켓이 제공하는 서비스로 인하여 회원에게 손해가 발생한 경우 그러한 손해가 위시켓의 고의나 과실에 기해 발생한 경우에 한하여 책임을 부담하며, 그 책임의 범위는 통상손해에 한합니다.`}
          </li>
          <li>
            {`위시켓은 회원으로부터 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 신속히 처리하여야 합니다. 다만, 신속한 처리가 곤란한 경우에는 회원에게 그 사유와 처리일정을 통보하여야 합니다.`}
          </li>
        </OrderedList>
      </Description>
      <SubTitle>제10조 (회원의 의무)</SubTitle>
      <Description>
        <OrderedList>
          <li>
            {`회원은 다음 행위(또는 이에 준하는 행위를 포함함)를 하여서는 안 됩니다.`}{' '}
            <UnStyledList>
              <CustomOrderLi orderNumber={1}>
                {`비정상적인 시스템 접근`}
                <UnOrderedList>
                  <BulletList>{`악성코드, 바이러스 등의 프로그램을 고의 또는 과실로 설치, 유포하는 행위`}</BulletList>
                  <BulletList>{`프로젝트의 정보 및 파트너의 정보를 수집하기 위해 크롤링을 하는 행위`}</BulletList>
                  <BulletList>{`리버스엔지니어링, 디컴파일, 디스어셈블 및 기타 일체의 가공행위를 통하여 서비스를 복제, 분해 또는 모방 기타 변형하는 행위`}</BulletList>
                </UnOrderedList>
              </CustomOrderLi>
              <CustomOrderLi orderNumber={2}>
                {`비정상적인 서비스 이용 및 타인의 서비스 이용 방해`}
                <UnOrderedList>
                  <BulletList>{`정당한 사유 없이 다수의 계정으로 서비스를 이용(프로젝트 등록, 지원을 포함하며 이에 한하지 않음)하는 행위. 신원 정보 중 일치하는 정보가 있거나, 모/자회사, 대표자가 동일한 등 사유로 위시켓이 실질적 동일인으로 판단하는 경우를 포함`}</BulletList>
                  <BulletList>{`반복적으로 계정을 탈퇴/재가입하는 행위`}</BulletList>
                  <BulletList>{`위시켓의 사전 동의 없이 직거래 제안, 광고 등을 목적으로 다른 회원에게 직접 연락하는 행위`}</BulletList>
                  <BulletList>{`프로젝트 등록 시 실제와 다른 내용(금액, 기간, 요구사항 등 등록이 요구하는 일체의 정보를 말함. 이하 동일)을 입력하거나 다른 내용으로 모집하는 행위`}</BulletList>
                  <BulletList>
                    {`프로젝트 진행 의사 없이 견적 및 구현 가능 여부를 판단하기 위한 목적으로 서비스를 이용하는 행위`}
                    <UnStyledList>
                      <li>
                        {`-  클라이언트가 5회 이상 반복적으로, 프로젝트 등록 후 계약 체결을 하지 않는 경우 이러한 목적이 있는 것으로 간주합니다.`}
                      </li>
                    </UnStyledList>
                  </BulletList>
                  <BulletList>{`모집 중에 정당한 사유 없이 프로젝트의 내용을 변경, 조정 요청하는 행위`}</BulletList>
                  <BulletList>{`위시켓이 비용 부족, 하도급 프로젝트 등 위시켓 정책에 따라 거절한 프로젝트와 동일 또는 유사한 프로젝트를 반복 등록하는 행위`}</BulletList>
                  <BulletList>{`프로젝트 공고와 다른 내용, 관련성이 적은 내용, 불성실한 내용, 중복되는 내용으로 프로젝트에 지원하는 행위`}</BulletList>
                  <BulletList>{`프로젝트 공고에 대한 충분한 검토 없이 지원 후 지원을 포기하거나 지원 내용과 다른 제안을 하는 행위`}</BulletList>
                  <BulletList>{`프로젝트 지원 후 포기를 반복하는 행위`}</BulletList>
                  <BulletList>{`다른 회원에게 지원 결과에 따른 불만을 표현하는 행위`}</BulletList>
                  <BulletList>{`프로젝트 공고와 다른 요구 및 제안을 하는 행위`}</BulletList>
                  <BulletList>{`위시켓의 사전 동의 없이 미팅에 참석하지 않거나 지각하는 행위`}</BulletList>
                  <BulletList>{`위시켓의 사전 동의 없이 미팅 일정을 연기하는 행위`}</BulletList>
                  <BulletList>{`미팅 전 과도한 자료 제출 요청을 반복하는 경우`}</BulletList>
                  <BulletList>{`자료 전달 및 검토 지연, 견적 전달 및 검토 지연, 요청사항 준비 미비, 미팅 준비 미비 등 원활한 미팅 및 지원자 검토를 방해하는 일체의 행위`}</BulletList>
                  <BulletList>{`미팅 후 지원자 선정을 지연하거나, 연락을 받지 않는 행위`}</BulletList>
                  <BulletList>{`계약서 작성을 위해 필요한 정보 제공을 지연 또는 거부하는 행위`}</BulletList>
                  <BulletList>{`이면 계약을 체결 또는 제안하는 행위`}</BulletList>
                  <BulletList>{`선급금이 금지된 계약에서 선급금을 요구하는 행위. 결과물에 비해 과다한 대금을 제안하는 등 선급금을 우회하려는 행위를 포함`}</BulletList>
                  <BulletList>{`구두로 계약 체결을 약속하거나 업무를 착수케 하는 등 상대방으로 하여금 계약 체결에 대한 정당한 기대 내지 신뢰를 하도록 한 후 계약을 체결하지 않는 행위`}</BulletList>
                  <BulletList>{`서비스 내에서 체결한 계약을 이행하지 않거나 지연하거나 불완전하게 이행하는 행위`}</BulletList>
                  <BulletList>{`서비스 내에서 체결한 계약을 정당한 사유 없이 중단시키거나 계약에서 정한 중단 절차를 준수하지 않는 행위(해지, 특약을 포함하며 이에 한하지 않음)`}</BulletList>
                  <BulletList>{`지급 조건을 준수하지 않은 지급을 요청하는 행위`}</BulletList>
                  <BulletList>{`수행 능력을 벗어나는 프로젝트를 진행하는 행위`}</BulletList>
                  <BulletList>{`사전 논의된 것과 다른 조건으로 인력(적은 인력 투입, 낮은 연차 인력 투입 또는 내부 인력이 아닌 하도급 또는 해외 인력 투입을 포함하며 이에 한정하지 않음)을 투입하는 행위`}</BulletList>
                  <BulletList>{`손해배상, 지체상금, 대금 감액 청구 등을 사유로 업무 결과물 전달이나 업무 이행을 거부 또는 지연하는 행위`}</BulletList>
                  <BulletList>{`프로젝트 진행 간 약속한 미팅 등 커뮤니케이션 일정을 준수하지 않는 행위`}</BulletList>
                  <BulletList>{`계약 및 서비스 이용 과정에서 발생하지 않은 별도의 금전을 요구하는 행위`}</BulletList>
                  <BulletList>{`계약 내용과 다른 조건을 요구하는 행위`}</BulletList>
                  <BulletList>{`소송, 중재, 조정 등 분쟁 해결 절차의 결과를 이행하지 않는 행위`}</BulletList>
                  <BulletList>{`분쟁 간 연락이 곤란하거나 필요한 답변을 지연하는 등 분쟁 해결을 위해 성실하게 협조하지 않는 행위`}</BulletList>
                  <BulletList>{`동일 또는 유사한 내용의 게시글을 2회 이상 반복하여 작성하는 행위`}</BulletList>
                  <BulletList>{`프로젝트 댓글에 요구 사항과 관련되지 않은 계약 조건, 회원 개인 등에 관한 내용을 입력하는 행위`}</BulletList>
                  <BulletList>{`상대방의 평가 작성에 영향을 미치는 일체의 행위`}</BulletList>
                  <BulletList>{`기타 위시켓이 구두 또는 서면으로 안내한 정책이나 이용 방법에 따르지 않는 행위`}</BulletList>
                  <BulletList>{`공개 및 비공개 평가에서 반복적으로 낮은 평가를 받는 경우`}</BulletList>
                  <BulletList>{`분쟁 등 사유로 주소지 및 연락처 등을 요청하였으나 이를 신속히 전달하지 않는 경우`}</BulletList>
                  <BulletList>{`의사 표시가 필요한 일체의 사항에 대하여 지정한 기한 내에 의사를 표시하지 않는 등의 부작위로 이용을 방해하는 행위`}</BulletList>
                </UnOrderedList>
              </CustomOrderLi>
              <CustomOrderLi orderNumber={3}>
                {`허위 또는 과장된 정보 입력`}
                <UnOrderedList>
                  <BulletList>{`회원이 자신의 포트폴리오, 이력 등 프로필 정보를 허위(축소, 과장, 변형을 포함하며 이에 한하지 않음. 이하 동일.)로 작성하는 행위`}</BulletList>
                  <BulletList>{`회원이 상대 회원 또는 위시켓에게 허위의 정보를 제공 및 전달하는 행위`}</BulletList>
                  <BulletList>{`기타 여하한 허위 정보를 제공 및 전달하는 행위. 정상 입력 후 변경된 정보를 입력하지 않는 행위를 포함.`}</BulletList>
                </UnOrderedList>
              </CustomOrderLi>
              <CustomOrderLi orderNumber={4}>
                {`직거래 제안 또는 승낙`}
                <UnOrderedList>
                  <BulletList>{`위시켓의 사전 동의 없이 위시켓 플랫폼 내에 상호(성명), 로고 등 상표, 주소, 전화번호, 이메일 주소 등 신원 식별을 용이하게 하는 정보(그러한 정보가 담겨있는 출판물 및 외부 링크 포함)를 요청 및 입력하는 행위`}</BulletList>
                  <BulletList>{`위시켓의 사전 동의 없이 위시켓을 제외하고 직거래를 제안 또는 승낙하는 행위. 제안 또는 승낙은 직접/명시적일 것을 요하지 않으며 직접 거래시의 견적서를 요청하는 등 직거래 의도가 간접적으로 노출되는 경우를 포함.`}</BulletList>
                  <BulletList>{`직거래 제한은 프로젝트를 등록한 날로부터 1년 간 유효`}</BulletList>
                </UnOrderedList>
              </CustomOrderLi>
              <CustomOrderLi orderNumber={5}>
                {`업무 방해`}
                <UnOrderedList>
                  <BulletList>{`도배 또는 스팸`}</BulletList>
                  <BulletList>{`서비스 제공에 필요한 연락 및 요청을 거부 또는 지연하는 행위`}</BulletList>
                  <BulletList>{`위시켓이 1회 이상 안내 또는 거절한 사항에 대하여 반복적으로 문의 또는 요청하는 행위`}</BulletList>
                  <BulletList>{`세금계산서(영수증, 계산서, 거래명세서 등 일체의 거래에 관한 증명서류를 포함) 등 거래에 수반되는 증빙서류를 임의로 발행하거나, 발행하지 않거나 늦게 발행하는 행위`}</BulletList>
                  <BulletList>{`위시켓의 사전 동의 없이 영리, 영업, 광고, 정치활동 등 서비스와 부합하지 않는 행위를 하는 경우`}</BulletList>
                  <BulletList>{`이용요금(유료 서비스 대가나 기타 위시켓에게 지급하여야 할 일체의 대금 지급을 포함함. 이하 동일)을 미납 또는 지연하는 행위`}</BulletList>
                  <BulletList>{`이용요금 지급계약을 거부 또는 지연하는 행위`}</BulletList>
                  <BulletList>{`위시켓이 지정하는 이용요금 지급 방식을 따르지 않는 행위`}</BulletList>
                  <BulletList>{`다른 회원의 계정 정보를 요청하는 행위`}</BulletList>
                </UnOrderedList>
              </CustomOrderLi>
              <CustomOrderLi orderNumber={6}>
                {`공공질서 등 위반`}
                <UnOrderedList>
                  <BulletList>{`성별, 정치, 종교, 장애, 연령, 사회적 신분, 인종, 지역, 직업 등을 차별하거나 이에 대한 편견을 조장하는 행위`}</BulletList>
                  <BulletList>{`비방하거나 명예를 훼손하는 행위`}</BulletList>
                  <BulletList>{`욕설, 폭언, 폭행, 협박을 하는 행위`}</BulletList>
                  <BulletList>{`반말, 외모 평가, 성희롱, 비속어 또는 은어 사용 등 사회 통념에 반하는 행위`}</BulletList>
                  <BulletList>{`과도한 신체 노출이나 음란한 행위를 묘사하거나, 성매매 관련 정보를 공유하거나, 타인에게 성적 수치심이나 불쾌감을 유발할 수 있는 내용을 게시하는 등 미풍양속에 반하는 정보를 위시켓 사이트에 등록하는 행위`}</BulletList>
                  <BulletList>{`민사, 형사, 행정, 진정 요청 등을 한 이후 이유 없음이 결정된 경우(당사자가 취소한 경우를 포함)`}</BulletList>
                  <BulletList>{`기타 관련 법령에서 금지하거나 선량한 풍속 기타 사회통념상 허용되지 않는 행위`}</BulletList>
                </UnOrderedList>
              </CustomOrderLi>
              <CustomOrderLi orderNumber={7}>
                {`타인의 권리 침해`}
                <UnOrderedList>
                  <BulletList>{`위시켓 및 타인(회원을 포함함. 이하 동일)의 특허권, 상표권, 저작권 등 지적재산권을 침해하는 행위 또는 침해할 우려가 있는 행위`}</BulletList>
                  <BulletList>{`타인의 개인정보를 침해하는 행위`}</BulletList>
                  <BulletList>{`기타 웹 해킹 프로그램, 매크로 프로그램, 보이스 피싱을 위한 미러링 사이트 등 타인의 권리를 침해하거나 침해할 우려가 있는 모든 행위`}</BulletList>
                  <BulletList>{`민사, 형사, 행정 등 공적인 이의를 제기한 이후 이유 없음이 밝혀진 때 또는 당사자가 취하한 때`}</BulletList>
                  <BulletList>{`상담, 미팅 등을 통해 얻은 정보(다른 회원의 정보, 위시켓 매니저와의 상담 내용, 회의내용 등)를 대화당사자들의 동의 없이 제3자에게 누설하는 행위`}</BulletList>
                </UnOrderedList>
              </CustomOrderLi>
              <CustomOrderLi orderNumber={8}>
                {`계정 거래, 양도, 대리, 교환 등`}
                <UnOrderedList>
                  <BulletList>{`계정 및 계정 내 콘텐츠를 타인에게 판매, 양도, 대여하거나, 타인에게 그 이용을 허락 또는 이를 시도하는 행위 및 이를 알선하는 행위`}</BulletList>
                </UnOrderedList>
              </CustomOrderLi>
              <CustomOrderLi orderNumber={9}>
                {`명의 사칭, 도용 등`}
                <UnOrderedList>
                  <BulletList>{`다른 회원, 제3자 또는 위시켓 또는 위시켓의 임직원을 사칭하는 행위`}</BulletList>
                </UnOrderedList>
              </CustomOrderLi>
              <CustomOrderLi
                orderNumber={10}
              >{`원활한 서비스 제공에 악영향 및 위시켓, 회원, 제3자, 공공의 이익을 저해할 우려가 있는 행위`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={11}
              >{`본 조를 포함하여 본 약관에서 금지하는 행위 및 그에 준하는 행위와 이를 시도, 제안, 유도, 알선, 승인하는 행위 및 그러한 우려가 있는 일체의 행위`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>{`회원은 본 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, 위시켓이 통지하는 사항 등을 확인하고 준수할 의무가 있습니다.`}</li>
          <li>{`제4조 및 제5조에 따른 서비스 이용계약을 체결하지 않은 자가 부정한 방법으로 위시켓 플랫폼에 접속한 경우에도 본조에서 금지되는 행위를 하여서는 아니되며, 제2조 제1항의 ‘회원’에 해당하지 않음을 이유로 면책된다고 주장할 수 없습니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제11조 (회원의 의무 위반에 대한 제재)</SubTitle>
      <Description>
        <OrderedList>
          <li>
            {`회원이 제10조의 의무를 위반한 경우에 위시켓은 사안의 중요성과 다른 회원, 제3자, 위시켓이 입은 손해를 고려하여 회원에게 다음과 같은 제재를 할 수 있습니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`서면경고\n위시켓은 제10조의 의무를 위반한 회원에게 의무 위반의 내용, 위반 시기 등을 기재하여 서면으로 경고할 수 있습니다.`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`이용의 제한\n위시켓은 일정한 기간을 정하여 프로젝트 등록 및 지원, 미팅과 로그인 등을 제한할 수 있습니다.`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={3}
              >{`영구 정지\n위시켓은 회원의 계정을 영구적으로 정지할 수 있습니다.`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>{`위시켓은 제10조의 의무를 위반하여 제재 대상이 된 회원들에게 등록된 이메일을 통하여 제재 사실을 고지합니다.`}</li>
          <li>{`다른 회원 또는 제3자의 제보만으로도 경고 및 이용 제한 조치를 할 수 있으며, 반복적으로 발생할 경우 해당 회원의 계정을 영구적으로 정지할 수 있습니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제12조 (회원에 대한 정보 제공 서비스)</SubTitle>
      <Description>
        <OrderedList>
          <li>
            {`클라이언트와 파트너는 위시켓 플랫폼을 통하여 다음과 같은 서비스를 제공받을 수 있습니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`클라이언트는 자신이 발주하는 프로젝트를 수행할 파트너를 구하기 위하여 프로젝트를 등록할 수 있습니다.`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`파트너는 프로젝트를 수행하기 위하여 자신의 프로필을 등록할 수 있습니다.`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={3}
              >{`파트너는 자신이 원하는 프로젝트를 수행하기 위하여 등록된 프로젝트에 지원할 수 있습니다.`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={4}
              >{`진행 중인 프로젝트의 관리를 지원받을 수 있습니다.`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={5}
              >{`위시켓은 정당한 사유(위시켓 또는 다른 회원의 권리보호 목적 등) 또는 정부정책, 법원의 명령, 수사기관의 협조 요청 등에 따라 상기 서비스의 전부 또는 일부의 제공을 중단하거나 거부할 수 있으며, 이러한 조치는 별도의 통지 없이 이루어질 수 있습니다.`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>{`위시켓은 미팅 내용 등 회원 간 또는 회원과 위시켓 간 소통 내역을 다른 회원에게 제공할 수 있습니다.`}</li>
          <li>{`위시켓이 제공하는 서비스는 각 회원 또는 프로젝트 및 계약의 조건에 따라 정책적으로 차등 제공될 수 있습니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제13조 (위시켓 회원간 서비스 계약의 체결에 관한 중개)</SubTitle>
      <Description>
        <OrderedList>
          <li>
            {`클라이언트는 제12조 제1항 제3호에 따라 지원한 파트너 중 원하는 파트너가 있는 경우 위시켓에 요청하여 위시켓의 중개 아래 해당 파트너와 미팅 및 계약을 진행하며, 위시켓의 사전 동의가 없는 이상 위시켓의 중개를 벗어나 미팅과 계약을 할 수 없습니다.`}
          </li>
          <li>
            {`미팅 과정을 통해 클라이언트가 프로젝트를 의뢰할 파트너를 결정하면 클라이언트와 파트너는 위시켓이 제공하는 양식을 기초로 프로젝트 수행에 관한 구체적인 내용이 포함된 계약(이하 “위시켓 회원간 서비스 계약”)을 위시켓, 클라이언트, 파트너 상호간의 협의에 따라 결정된 내용으로 체결합니다. 위시켓 회원간 서비스 계약의 당사자는 계약 유형별로 상이할 수 있습니다. 단, 위시켓 및 회원의 합의에 따라 회원 중 일방 당사자가 제공하는 회원 양식을 이용할 수 있으며, 이 경우 회원들은 위시켓의 대금보호시스템, 위시켓 이용요금 등 위시켓이 요구하는 내용을 회원 양식에 추가하거나 별도로 계약을 체결하여야 합니다.`}
          </li>
          <li>
            {`위시켓 회원간 서비스 계약 체결 후 계약에서 정한 사항에 따라 클라이언트는 위시켓에 계약 대금을 지급하고, 위시켓은 이를 위시켓 회원간 서비스 계약에서 정해진 바에 따라 보관 후 파트너에게 대금을 지급합니다.`}
          </li>
          <li>
            {`위시켓 회원간 서비스 계약 및 기타 클라이언트와 파트너, 위시켓 사이에 체결한 개별 계약은 본 약관에 우선합니다. 위시켓 회원간 서비스 계약에 의하여 별도로 정해지지 아니한 사항은 본 약관에 의합니다.`}
          </li>
          <li>
            {`클라이언트와 파트너는 업무를 진행함에 있어 발생하는 다양한 요청 등에 대해 위시켓 회원간 서비스 계약에 특별히 정해진 사항을 제외하고는 공휴일을 포함하여 최대 7일 이내(이하 “확인 기간”)에 의사를 표시할 의무가 있습니다. 이를 지키지 않을 경우 처리되는 모든 사항(환불, 취소, 분쟁 해결 절차 등)에 대한 책임은 본인에게 있습니다.`}
          </li>
          <li>
            {`위시켓은 위시켓 회원간 서비스 계약에 따른 파트너의 업무 수행 과정 및 결과물에 관하여 보증하지 아니하며, 이에 관하여 일체의 책임을 부담하지 아니합니다.`}
          </li>
        </OrderedList>
      </Description>
      <SubTitle>제14조 (유료서비스의 이용조건 등)</SubTitle>
      <Description>
        <OrderedList>
          <li>
            {`도급 계약의 이용요금은 계약 대금의 10%(부가가치세 10% 별도. 이하 동일.)로 파트너가 부담하고, 위임,근로,파견 등 기간제 계약의 이용요금은 계약 대금의 5%로 클라이언트가 부담합니다. 개별 계약 또는 개별 서비스 안내 페이지에서 따로 정하는 바가 있을 경우에는 그에 따릅니다.`}
          </li>
          <li>
            {`이용요금이란 위시켓이 제13조 제1항에 따른 중개를 통해 위시켓 회원간 서비스계약을 체결한 것에 대한 대가이며 위시켓 회원간 서비스계약 체결 후 위시켓이 회원에게 제공하는 서비스 일체는(프로젝트 관리지원 서비스, 대금보호시스템 등)는 유료서비스를 이용한 회원들에게 제공하는 무료서비스입니다. 따라서 위시켓 회원간 서비스 계약 체결 시(계약서 작성 및 서명 · 날인을 지연 또는 거부하는 경우에는 구두 합의 시)에 본조 제1항에 따른 이용요금 전액이 발생하며, 다른 합의가 없는 한 위시켓 회원간 서비스 계약 체결일로부터 1영업일 내에 현금으로 전액 지급하여야 합니다.`}
          </li>
          <li>
            {`프로젝트 등록 후 6개월 내에 클라이언트와 파트너 간 계약이 체결된 경우, 위시켓을 이용하여 계약을 체결한 것으로 보아 이용요금이 부과됩니다.`}
          </li>
          <li>
            {`위시켓은 유료 서비스를 제공함에 있어 위시켓의 정책에 따라 회원에게 유료 서비스의 이용 금액(이용요금 등 그 명칭을 불문함.) 또는 이용 기한 등 이용 조건을 정할 수 있습니다. 유료 서비스의 이용조건은 각 유료 서비스 세부 이용 지침 또는 개별 계약에서 정하거나 각 유료 서비스 결제 화면 등에 게시하여 회원에게 공지합니다.`}
          </li>
          <li>
            {`이용요금은 위시켓이 지정하는 방법(계좌이체, 신용카드 기타 위시켓이 정하는 결제 수단)으로 납부하여야 합니다.`}
          </li>
          <li>
            {`회원은 다음의 사유를 이유로 위시켓에게 이용요금의 지급을 거절하거나 반환을 요구할 수 없습니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`회원 간의 분쟁 또는 합의 등을 원인으로 위시켓 회원간 서비스 계약이 해제 또는 해지된 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`위시켓이 제공하는 무료서비스를 이용하지 않는 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={3}
              >{`위시켓이 제공한 무료서비스에 만족하지 못한 경우`}</CustomOrderLi>
            </UnStyledList>
          </li>
        </OrderedList>
      </Description>
      <SubTitle>제15조 (서비스의 변경 및 내용 수정)</SubTitle>
      <Description>
        {`위시켓은 운영상, 기술상의 필요에 따라 서비스 내용을 수정하거나 변경할 수 있으며, 이 경우 변경 사항을 위시켓 플랫폼에 공지합니다. 이에 대하여 위시켓은 회원 또는 제3자에 어떠한 책임도 부담하지 않습니다.`}
      </Description>
      <SubTitle>제16조 (서비스의 제공 및 중단 등)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`위시켓은 회원의 제5조 및 제6조에 따라 이용 신청을 승낙한 때부터 서비스를 개시합니다. 단 위시켓이 필요한 경우 일부 서비스는 특정 시점부터 개시할 수 있습니다. 이러한 경우 위시켓은 사전 또는 사후에 이를 공지합니다. 회원으로 가입한 이후라도 일부 서비스는 위시켓이 필요한 경우 특정 회원에게만 서비스를 제공할 수 있습니다. 위시켓은 서비스를 일정 범위로 분할하여 각 범위 별로 이용 가능 시간을 별도로 정할 수 있습니다. 이 경우 그 내용을 사전 또는 사후에 이를 사이트에 공시하거나 회원에게 이를 통지합니다.`}</li>
          <li>{`서비스는 연중무휴, 1일 24시간을 원칙으로 합니다. 다만 서비스 성격에 따라 토요일, 일요일, 공휴일과 평일 오후 6시 이후에는 제공이 제한될 수 있습니다.`}</li>
          <li>
            {`제2항에도 불구하고, 다음 각 호의 어느 하나에 해당하는 경우에는 일정한 시간 동안 서비스가 제공되지 아니할 수 있으며, 해당 시간 동안 위시켓은 서비스를 제공할 의무가 없습니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`컴퓨터 등 정보통신설비의 보수점검, 교체, 정기점검 또는 서비스의 수정을 위하여 필요한 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`해킹 등의 전자적 침해사고, 통신 사고, 회원들의 비정상적인 서비스 이용행태, 예상할 수 없는 서비스의 불안정성에 대응하기 위하여 필요한 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={3}
              >{`관련 법령에서 특정 시간 또는 방법으로 서비스 제공을 금지하는 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={4}
              >{`천재지변, 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 제공이 불가능할 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={5}
              >{`분할, 합병, 영업양도, 영업의 폐지, 당해 서비스의 수익 악화 등 위시켓의 경영상 중대한 필요에 의한 경우`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>{`위시켓은 제3항 각호의 경우, 1주일 전에 그 사실을 회원에게 개별 서비스 초기 화면이나 위시켓 플랫폼에 사전 고지하며, 이 기간 동안 회원이 고지 내용을 인지하지 못한데 대하여 위시켓은 책임을 부담하지 아니합니다. 사전에 통지할 수 없는 부득이한 사정(긴급한 시스템 점검ᆞ 증설ᆞ교체, 시스템 관리자의 고의, 과실 없는 디스크 장애ᆞ시스템 다운, 제3자인 PC통신 회사, 기간통신사업자 등의 사정으로 인한 서비스 제공의 불가 등 위시켓이 통제할 수 없는 사정)이 있는 경우에는 그 사정이 종료된 후에 즉시 사후에 통지를 할 수 있습니다.`}</li>
          <li>{`또한 위 서비스 중지에 의하여 본 서비스에 보관되거나 전송된 메시지 및 기타 통신 메시지 등의 내용이 보관되지 못하였거나 삭제, 미전송, 기타 통신 데이터의 손실이 발생할 수 있으므로 회원은 중요한 메시지 등을 별도로 보관하고 서비스 중지 기간 이후 메시지 등의 전송 여부를 확인하여야 합니다. 위시켓의 고의 또는 과실이 없는 한 위시켓은 이에 대하여 책임을 부담하지 않습니다. 위시켓의 사정으로 서비스를 영구적으로 중단하여야 할 경우에 상기항을 준용합니다. 다만 이 경우에는 사전 고지 기간을 1개월로 합니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제17조 (정보 제공 및 광고의 게재)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`위시켓은 회원의 편의를 위하여 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보 및 광고를 배너 게재, 전자우편, 서신우편, 휴대폰 문자메시지, 전화, 어플리케이션 알림 등의 방법으로 회원에게 제공할 수 있습니다. 회원은 이를 원하지 않을 경우에 위시켓이 제공하는 방법에 따라 수신을 거부할 수 있습니다.`}</li>
          <li>{`전항 단서에 따른 수신 거부 회원의 경우라도 이용약관, 개인정보보호정책, 기타 회원의 이익에 영향을 미칠 수 있는 중요한 사항의 변경 등 회원이 반드시 알고 있어야 하는 사항에 대해서는 전항의 방법으로 정보의 제공 등을 할 수 있습니다.`}</li>
          <li>{`위시켓 플랫폼에는 배너와 링크(Link) 등 다양한 형태의 광고가 포함될 수 있으며, 이는 제3자가 제공하는 페이지와 연결될 수 있습니다.`}</li>
          <li>{`제3항에 따라 제3자가 제공하는 페이지로 연결될 경우 해당 페이지는 위시켓의 서비스 영역이 아니므로 위시켓이 신뢰성, 안정성 등을 보장하지 않으며 그로 인한 회원의 손해에 대하여도 위시켓은 책임을 지지 않습니다.`}</li>
          <li>{`위시켓은 서비스 상에 게재되어 있거나 서비스를 통한 제3자와의 판촉활동에 회원이 참여하거나 교신 또는 거래의 결과로서 발생하는 모든 손실 또는 손해에 대해 관련 법에 특별한 규정이 있거나 위시켓의 고의 또는 중과실로 인한 경우가 아닌 한 책임을 지지 않습니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제18조 (회원의 해지)</SubTitle>
      <Description>{`회원은 위시켓 플랫폼을 통하여 언제든지 서비스 이용계약을 해지(이하 “회원탈퇴”라 합니다)할 수 있습니다. 회원이 회원탈퇴를 신청한 경우 위시켓은 회원 본인의 신청인지 여부를 확인할 수 있으며, 위시켓은 확인 완료 후에 회원의 신청에 따른 조치를 취합니다.`}</Description>
      <SubTitle>제19조 (위시켓의 해지)</SubTitle>
      <Description>
        <OrderedList>
          <li>
            {`위시켓은 회원이 본 약관을 위반한 경우 또는 다음 각 호의 경우에는 회원에 대하여 1회 시정할 것을 최고하고 그 기간 동안 시정되지 않을 경우 서비스 이용 계약을 해지할 수 있습니다. 다만, 회원이 현행법 위반 및 고의 또는 중대한 과실로 위시켓에 손해를 입히거나 입히려한 경우에는 사전통보 없이 서비스 이용계약을 해지할 수 있습니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`비 실명 가입, 주민등록번호의 도용 등 회원이 제공한 데이터가 허위임이 판명된 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`범죄적 행위에 관련되는 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={3}
              >{`국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는 실행할 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={4}
              >{`타인의 서비스 아이디 및 비밀번호를 도용한 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={5}
              >{`타인의 명예를 손상시키거나 불이익을 주는 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={6}
              >{`같은 사용자가 다른 아이디로 이중 등록을 한 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={7}
              >{`서비스에 위해를 가하는 등 서비스의 건전한 이용을 저해하는 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={8}
              >{`기타 관련법령이나 위시켓이 정한 이용조건에 위배되는 경우`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>{`제1항에 의하여 계약이 해지되는 경우 회원에게 제공되는 서비스는 모두 중단되며, 회원의 활동과 관련하여 누적된 사항은 모두 비공개 처리됩니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제20조 (위시켓의 회원에 대한 제재)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`회원이 본 약관의 내용에 위배되는 행동을 한 경우, 임의로 서비스 사용을 제한 및 중지할 수 있습니다. 이 경우 위시켓은 회원의 접속을 금지할 수 있으며, 회원이 게시한 내용의 전부 또는 일부를 임의로 삭제할 수 있습니다.`}</li>
          <li>{`위시켓은 다른 회원 또는 제3자의 제보, 위시켓의 판단에 따라 제재 대상자의 의견 청취 없이 우선 제재 조치를 취할 수 있으며, 제재 대상 회원은 추후 이의를 제기할 수 있습니다.`}</li>
          <li>{`위시켓은 이용약관 위배 및 제재 상황이나 이력을 제3자 또는 다른 회원에게 공개하거나 위시켓 플랫폼 내 게시할 수 있습니다.`}</li>
          <li>{`회원이 제공하는 정보의 내용이 허위인 것으로 판명되거나, 허위가 있다고 의심할 만한 합리적인 사유가 발생할 경우 회원의 본 서비스 사용을 일부 또는 전부 이용 정지할 수 있으며, 이로 인해 발생하는 불이익에 대해 위시켓은 책임을 부담하지 아니합니다.`}</li>
          <li>{`제재의 대상은 법률상 동일인일 것을 요하지 않으며 신원 정보 중 일치하는 정보가 있거나, 모/자회사, 대표자가 동일한 등 사유로 위시켓이 실질적 동일인으로 판단하는 대상을 포함합니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제21조 (양도 금지)</SubTitle>
      <Description>
        {`회원은 본 약관에 의한 서비스의 이용권한, 위시켓 회원간 서비스 계약 등 기타 이용계약 상의 권리, 지위 등을 타인에게 양도 및 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.`}
      </Description>
      <SubTitle>제22조 (면책)</SubTitle>
      <Description>
        <OrderedList>
          <li>
            {`위시켓은 다음 각 호의 경우에는 면책됩니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`전시, 사변, 천재지변, 국가비상사태, 해결이 곤란한 기술적 결함 기타 불가항력적 사유로 서비스를 제공할 수 없는 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`회원의 귀책사유로 인한 서비스의 중지, 이용 장애 및 서비스 이용계약이 해지된 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={3}
              >{`기간통신 사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지 아니한 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={4}
              >{`사전에 공지된 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 서비스가 중지되거나 장애가 발생한 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={5}
              >{`회원의 컴퓨터 환경으로 인하여 발생하는 제반 문제 또는 위시켓의 고의 또는 중대한 과실이 없는 네트워크 환경으로 인하여 발생하는 문제로 인한 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={6}
              >{`위시켓의 고의 또는 중과실 없이 회원 상호간 또는 회원과 제3자간에 서비스를 매개로 분쟁이 발생한 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={7}
              >{`무료서비스와 관련하여 위시켓의 고의 또는 중대한 과실이 없는 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={8}
              >{`위시켓이 아닌 다른 사업자가 제공하는 서비스와 관련하여 위시켓의 고의 또는 중대한 과실이 없는 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={9}
              >{`서비스가 정상적으로 제공되었음에도 불구하고 회원이 주관적으로 기대하는 이익, 결과를 얻지 못하거나 상실한 경우 또는 회원의 취사선택으로 발생한 손해의 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={10}
              >{`회원의 컴퓨터 오류에 의한 손해가 발생한 경우 또는 신상정보 및 전자우편주소를 부정확하게 기재하거나 미 기재하여 손해가 발생한 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={11}
              >{`관련 법령, 정부 정책 등에 따라 서비스를 제공할 수 없는 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={12}
              >{`회원 또는 제3자가 서비스 내 또는 웹사이트 상에 게시 또는 전송한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 대해서 위시켓의 고의 또는 중대한 과실이 없는 경우`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>{`위시켓 플랫폼에 게시된 프로필 등 회원 기재사항은 클라이언트와 파트너가 직접 기재한 것으로 위시켓은 그에 대한 검증이나 진실성을 보증하지 아니하며, 이에 관하여 일체 책임을 부담하지 아니합니다.`}</li>
          <li>{`회원은 자기의 권한 및 책임으로 위시켓 플랫폼에 정보 및 의견을 게시하며 위시켓은 서비스 과정에서 회원이나 제3자에 의해 표시된 정보 및 의견의 사실 여부를 검증하거나 이를 승인할 의무가 없습니다. 위시켓은 회원 또는 기타 유관기관이 서비스에 게재한 정보(회원의 경력, 프로필, 평가 등 게시물을 포함하며 이에 제한되지 아니합니다) 및 의견의 진실성, 정확성, 신뢰도를 보장하지 않습니다. 따라서 위시켓은 회원이 위 정보 및 의견을 신뢰함으로 인하여 입게 된 모든 종류의 손실이나 손해에 대하여 책임을 부담하지 아니합니다.`}</li>
          <li>{`회원의 불법적인 행위 또는 본 약관에 위반되는 행위로 인하여 위시켓에 손해가 발생하거나 수사기관, 행정청으로부터 형사처벌 또는 제재를 받은 경우 회원은 회원의 비용(손해배상금, 소송비용, 변호사 비용 등을 포함하되 이에 한정하지 아니함)으로 손해를 전보하고 위시켓을 면책하여야 합니다.`}</li>
          <li>{`위시켓은 제13조에 의한 위시켓 회원간 서비스 계약 체결 후 파트너의 업무 수행으로 인한 결과물에 대하여 검수하거나 검사할 의무가 없습니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제23조 (손해배상)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`위시켓이 고의 또는 중과실에 의하여 회원에게 손해를 입힌 경우, 그 손해를 배상합니다.`}</li>
          <li>{`회원이 이용요금 등 유료서비스의 대가나 위시켓에 지급하여야 할 일체의 대금 지급을 지연하거나 지급하지 않는 경우 원 금액과 별도로 연 20%의 지연이자와 원 금액의 2배액을 위약벌로 지급하여야 합니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제24조 (재판관할)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`위시켓의 서비스 제공과 관련하여 위시켓과 회원 사이에 분쟁이 발생한 경우, 위시켓과 회원은 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다.`}</li>
          <li>{`발생한 분쟁에 대하여 소송이 제기될 경우에는 당사자의 주소지(주소가 없는 경우에은 거소지)를 관할하는 법원을 관할 법원으로 하며, 제소 당시 당사자의 주소 또는 거소가 분명하지 않거나 외국거주자의 경우에는 민사소송법 상 관할법원에 제기합니다. 다만 본 조의 규정은 위시켓 회원간 서비스 계약에 의하여 중재로 해결하는 경우에는 적용되지 아니합니다.`}</li>
          <li>{`위시켓과 회원 간에 제기된 소송에는 대한민국법을 적용합니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제25조 (위시켓 플랫폼 컨텐츠)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`회원이 직접 게시한 모든 콘텐츠(게시물, 댓글 등)의 저작권은 회원이 소유하며, 위시켓은 이에 대한 2차적 저작물 작성권을 갖습니다.`}</li>
          <li>{`탈퇴한 회원의 콘텐츠는 탈퇴 회원이 요청하기 전까지 위시켓의 선택에 따라 이를 삭제하거나 유지할 수 있습니다.`}</li>
          <li>
            {`위시켓은 다음 각 호에 해당하는 콘텐츠의 게시를 금지합니다. 이를 위반한 경우, 위시켓은 해당 콘텐츠에 대해 사전통지 없이 삭제, 이동 또는 등록 거부의 조치를 취할 수 있습니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`서비스 이용 목적에 부합하지 않는 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`일치하거나 유사한 내용이 있어 중복되는 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={3}
              >{`이메일 주소, 핸드폰, 전화번호, 주소, URL 등의 내용을 직접 기재한 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={4}
              >{`상용 또는 불법, 음란, 저속하다고 판단되는 내용인 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={5}
              >{`다른 회원 또는 제 3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={6}
              >{`공공질서 및 미풍양속에 위반되는 내용인 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={7}
              >{`범죄적 행위에 결부된다고 인정되는 내용일 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={8}
              >{`제3자의 초상권, 저작권 등 기타 권리를 침해하는 내용인 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={9}
              >{`서비스 성격에 부합하지 않는 정보의 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={10}
              >{`본 약관에 위배되는 경우`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={11}
              >{`기타 관계 법령에 위배되는 경우`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>{`위시켓은 평가 작성자의 요청이 있더라도, 피평가자의 영향력이 행사되었다고 판단되는 경우에는 삭제를 거부할 수 있습니다.`}</li>
          <li>{`평가 작성자는 평가 작성일로부터 1영업일 내에 1회에 한하여 평가를 수정할 수 있습니다.`}</li>
          <li>{`계약 이력 등 서비스 이용 히스토리와 관련된 정보 일체는 삭제할 수 없습니다.`}</li>
          <li>{`개별 페이지에서 삭제의 가부 등 삭제 조건을 정하고 있는 경우, 해당 페이지에 콘텐츠를 작성 및 게시함으로써 그 조건에 동의하는 것으로 간주합니다.`}</li>
          <li>{`위시켓은 컨텐츠가 게시된 지 상당한 기간 이상 경과되어, 컨텐츠로서의 효용성이 없어진 경우 해당 콘텐츠를 이동 또는 삭제할 수 있습니다.`}</li>
          <li>{`위시켓은 타인의 저작권을 침해하는 콘텐츠에 대하여 저작권법에 정해진 절차에 의하여 저작권자 또는 회원의 요청에 의해 해당 콘텐츠를 삭제하거나 다시 게시할 수 있으며, 이 경우 위시켓은 면책됩니다. 회원의 컨텐츠가 타인의 저작권을 침해함으로써 발생하는 민, 형사상의 책임은 전적으로 회원이 부담하여야 합니다.`}</li>
          <li>{`회원은 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등 플랫폼에 게재된 컨텐츠를 상업적으로 사용할 수 없습니다.`}</li>
          <li>{`위시켓이 제공하는 서비스, 그에 필요한 소프트웨어, 이미지, 마크, 로고, 디자인, 서비스 명칭, 정보 및 상표 등과 관련된 지적재산권 및 기타 권리는 위시켓에게 있습니다. 회원은 위시켓이 명시적으로 승인한 경우를 제외하고는 상기 항에 기재한 소정의 각 재산에 대한 전부 또는 일부의 수정, 대여, 대출, 판매, 배포, 제작, 양도, 재라이센스, 담보권설정행위, 상업적 이용행위를 할 수 없으며, 제3자로 하여금 이와 같은 행위를 하도록 허락할 수 없습니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제26조 (지적재산권)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`파트너는 위시켓 회원간 서비스 계약을 체결한 후 계약에 따른 업무를 수행함에 있어서 특허권, 상표권, 저작권 등 제3자의 지적재산권을 침해하여서는 아니 됩니다. 만일 업무에 따른 결과물이 제3자의 지적재산권을 침해하여 클라이언트 또는 제3자에게 손해를 끼칠 경우에 파트너는 민ᆞ형사상의 모든 책임을 지게 됩니다.`}</li>
          <li>{`제1항의 경우 클라이언트 또는 위시켓이 제3자로부터 이의제기, 경고, 민ᆞ형사상 소송, 행정 구제 절차 등의 청구를 받은 경우 파트너는 자신의 비용으로 지적재산권을 침해당한 제3자에게 배상하여야 하며, 클라이언트 또는 위시켓을 면책(손해배상금, 소송비용, 변호사 비용 등을 포함하되 이에 한정하지 아니함)하여야 합니다.`}</li>
          <li>{`위시켓은 파트너의 결과물이 제3자의 지적재산권을 침해하는지 여부에 대해 조사하지 않으며, 이에 대해서는 클라이언트가 직접 도용이나 침해 여부를 조사하여야 합니다.`}</li>
        </OrderedList>
      </Description>
      <Title id="wishket_escrow">제2장 대금보호시스템</Title>
      <Description>
        {`대금보호시스템은 클라이언트가 파트너의 용역 제공의 대가를 용역을 공급받기 전에 위시켓에게 예치(이하 “결제”)하고 위시켓은 클라이언트의 지급 승인에 따라 파트너에게 지급하는 서비스를 의미하며, 제13조에 의하여 위시켓 회원간 서비스 계약서를 체결한 후 제공 받을 수 있는 서비스 입니다.\n위시켓은 기본적으로 서비스에 대한 신뢰를 위해 모든 거래에 대해 그 대가(이하 “계약 대금”)의 전부 또는 일부를 미리 지급 받아 회원의 거래의 결과에 대한 상호 확인 시점까지 계약 대금을 예치하는 것을 원칙으로 하고 있습니다.\n이러한 대금보호시스템을 통해 상호 거래에 대한 신뢰를 향상하고 대금 분쟁을 미연에 방지하고자 하는 바 파트너는 업무 착수와 함께(또는 그에 근접하여) 수수하는 이른바 착수금(계약금, 선금 등 그 명칭을 불문함)을 지급받을 수 없습니다.`}
      </Description>
      <SubTitle>제27조 (대금의 상태 공개)</SubTitle>
      <Description>{`위시켓 회원간 서비스 계약을 체결한 회원은 대금의 결제, 예치, 지급 등의 상태를 위시켓을 통해서 확인할 수 있습니다. 위시켓은 대금의 상태가 변하는 경우 최대 7일 이내에 그 변경된 상태를 적용해야 합니다.`}</Description>
      <SubTitle>제28조 (결제 내용의 확인)</SubTitle>
      <Description>
        {`위시켓은 회원의 요청이 있는 경우 회원의 결제 내역을 확인할 수 있도록 하며, 회원의 요청이 있는 경우에는 요청을 받은 날로부터 15일 이내에 결제 내역을 이메일 등으로 고지합니다. 회원은 다음의 주소 및 전화번호로 결제 내역 확인을 요청할 수 있습니다.`}
        <UnOrderedList className="pl-5">
          <BulletList>{`주소 : 서울특별시 강남구 테헤란로 211 한국고등교육재단빌딩 3층 (주)위시켓`}</BulletList>
          <BulletList>{`이메일 : help@wishket.com`}</BulletList>
          <BulletList>{`전화번호: (02) 6925-4849`}</BulletList>
        </UnOrderedList>
      </Description>
      <SubTitle>제29조 (이자 수익 및 수수료)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`회원은 대금보호시스템을 이용함에 있어 예치된 대금에 대해 이자 또는 별도의 수입을 받지 않는 것에 동의합니다. 위시켓은 예치된 대금에 대한 이자 또는 별도의 수입을 생성할 수 있습니다.`}</li>
          <li>{`위시켓은 대금보호시스템을 통한 예치 계좌의 입출금에 대해 수수료가 발생할 경우(해외 송금, 환전 등)에는 이에 대한 비용을 별도로 회원에게 청구할 수 있습니다.`}</li>
          <li>{`본 조는 제14조 유료 서비스의 이용 금액(이용요금 등 그 명칭을 불문함.)에 준용합니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제30조 (계약 대금의 지급)</SubTitle>
      <Description>{`위시켓은 예치된 계약 대금에 대해 위시켓 회원간 서비스 계약에 따라 대금을 지급하거나 환불합니다. 대금의 지급 및 환불 등 대금 관련 업무는 평일 오후 6시 이전에 이루어지는 것을 원칙으로 합니다. 본 조는 제14조 유료 서비스의 이용 금액(이용요금 등 그 명칭을 불문함.)에 준용합니다.`}</Description>
      <Title id="site_policy">제3장 안심중재솔루션</Title>
      <Description>
        {`안심중재솔루션은 회원 및 위시켓이 제13조에 의한 위시켓 회원간 서비스 계약을 체결한 후 분쟁이 발생할 경우, 이를 원만하고 신속하게 해결할 수 있도록 위시켓이 제공하는 분쟁해결 시스템입니다.`}
      </Description>
      <SubTitle>제31조 (안심중재솔루션)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`안심중재솔루션은 분쟁 발생시 위시켓이 선택적으로 제공하는 서비스로써 회원은 이 절차를 따라야 하며, 절차는 다음과 같은 3단계 과정으로 이루어지게 됩니다.`}</li>
          <InformationBox>
            <UnOrderedList className="gap-3">
              <BulletList>
                <Box className="flex flex-col">
                  <Typography variant="body1" className="font-medium">
                    1단계: 양자 협의
                  </Typography>
                  {`양자협의는 프로젝트 진행 도중 문제 상황이 발생했을 때 클라이언트와 파트너 간의 적극적인 의사소통을 통해 합의점을 찾는 단계입니다.`}
                </Box>
              </BulletList>
              <BulletList>
                <Box className="flex flex-col">
                  <Typography variant="body1" className="font-medium">
                    2단계: 위시켓 지원 미팅
                  </Typography>
                  {`위시켓 지원 미팅은 양자협의 단계에서 합의점을 찾지 못했을 경우, 위시켓 매니저의 중립적인 도움을 받는 단계입니다.`}
                </Box>
              </BulletList>
              <BulletList>
                <Box className="flex flex-col">
                  <Typography variant="body1" className="font-medium">
                    3단계: 기관중재
                  </Typography>
                  {`앞의 두 단계로도 합의점을 찾지 못한 경우, 대한상사중재원(중재법에 따라 설립된 상설 법정 기관)의 중재판정에 의해 분쟁을 해결합니다.`}
                </Box>
              </BulletList>
            </UnOrderedList>
          </InformationBox>
          <li>{`클라이언트와 파트너가 부적절하게 위시켓의 분쟁 해결 절차를 악용하거나 과도하게 요구하는 경우 위시켓은 이를 거부할 수 있고, 회원과의 서비스 이용계약 및/또는 위시켓 회원간 서비스 계약을 해제 또는 해지할 수 있으며, 위시켓에 손해가 발생하는 경우 손해배상을 청구할 수 있습니다.`}</li>
          <li>{`위시켓은 분쟁 상황에 있는 회원의 서비스 이용을 제한할 수 있습니다.`}</li>
          <li>{`위시켓은 분쟁 상황이나 이력을 제3자 또는 다른 회원에게 공개하거나 위시켓 플랫폼에 게시할 수 있습니다.`}</li>
        </OrderedList>
      </Description>

      <Title id="addendum">부칙</Title>
      <Description>
        <OrderedList>
          <li>{`본 약관은 2024년 03월 25일부터 시행됩니다.`}</li>
          <li>{`2023년 01월 06일부터 시행되던 종전의 약관은 본 약관으로 대체됩니다.`}</li>
        </OrderedList>
      </Description>
    </Box>
  );
};

export default WishketServiceTerms;
