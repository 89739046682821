import { Box, GridLayout } from '@wishket/design-system';

import { CompanyInfo, ContactUs, LinkSet, ServiceSet, SnsSet } from './parts';

const Footer = () => {
  return (
    <Box className="w-full bg-w-gray-50" data-testid="footer">
      <GridLayout gridTemplateAreaClass="mx-auto grid w-full max-w-[1280px] auto-cols-1fr gap-x-6 grid-areas-footer-mobile tablet:grid-areas-footer-tablet desktop:gap-x-0 desktop:grid-areas-footer-desktop">
        <ServiceSet />
        <SnsSet />
        <ContactUs />
        <LinkSet />
        <CompanyInfo />
      </GridLayout>
    </Box>
  );
};

export default Footer;
