'use client';

import { Box, Typography } from '@wishket/design-system';

import { MainTitle } from './Main.parts';

import { MainLinkCard } from '@/features';
import PATH from '@/shared/constants/path';
import { useProfile } from '@/entities/profile';
import { WithSkeleton } from '@/shared/Components';
import { useNextUrl } from '@/shared/hooks';
import { withComponentLogger } from '@/shared/hocs';

const Main = () => {
  const { username } = useProfile();
  const { withNextUrl } = useNextUrl();

  return (
    <Box
      className="flex h-full w-full flex-col gap-12 desktop:min-h-[60vh]"
      data-testid="main-widget"
    >
      <Box className="flex flex-col gap-2">
        <WithSkeleton isLoading={!username} isFit>
          <MainTitle text={`${username}님, 안녕하세요!`} />
          <Typography variant="body1" className="text-w-gray-700">
            위시켓 통합회원의 정보를 안전하게 설정해 보세요.
          </Typography>
        </WithSkeleton>
      </Box>
      <Box className="flex w-full flex-col gap-6 desktop:flex-row">
        <MainLinkCard
          link={withNextUrl(PATH.ACCOUNT)}
          iconName="person_smile"
          title="계정 정보 · 소셜 연동"
          description="계정의 기본 정보들을 확인할 수 있습니다."
        />
        <MainLinkCard
          link={withNextUrl(PATH.PASSWORD_CHANGE)}
          iconName="lock"
          title="비밀번호 변경"
          description="비밀번호를 변경하고 계정을 안전하게 보호하세요."
        />
        <MainLinkCard
          link={withNextUrl(PATH.WITHDRAWAL)}
          title="회원 탈퇴"
          description="회원 탈퇴를 신청할 수 있습니다."
          iconName="logout"
        />
      </Box>
    </Box>
  );
};

export default withComponentLogger(Main);
