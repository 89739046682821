import { Box, Button, Typography } from '@wishket/design-system';
import Link from 'next/link';

import { Icon } from '@/shared/Components';
import { ServiceType } from '@/shared/types';

interface SignUpCompleteContentsProps {
  redirectService: ServiceType;
}

const SignUpCompleteContents = ({
  redirectService,
}: SignUpCompleteContentsProps) => {
  const serviceName = redirectService === 'yozmit' ? '요즘IT' : '위시켓 잡스';

  return (
    <Box className="flex w-full flex-col items-center justify-center gap-8 rounded-2xl desktop:w-[558px] desktop:p-8 desktop:shadow-contents-container">
      <Box className="flex flex-col items-center">
        <Icon name="sign_up_complete" />
        <Typography
          variant="subTitle2"
          className="mt-6 font-medium text-w-gray-900"
        >
          위시켓 통합회원가입이 완료되었어요!
        </Typography>
        <Typography variant="body1" className="mt-3 text-w-gray-700">
          {redirectService === 'yozmit'
            ? '요즘IT에서 매일 새로운 콘텐츠를 읽어보세요.'
            : '위시켓 잡스에서 내 기술 스택에 딱 맞는 포지션을 찾아보세요.'}
        </Typography>
        <Typography
          variant="body1"
          className="mt-4 whitespace-pre text-center text-w-gray-700"
        >
          {`잠시 후, ${serviceName}로 이동합니다.\n바로 이동을 원하시면 아래 버튼을 눌러주세요.`}
        </Typography>
      </Box>
      <Link
        className="w-full"
        href={
          redirectService === 'yozmit'
            ? 'https://yozm.wishket.com/magazine/'
            : 'https://jobs.wishket.com/superstack/'
        }
      >
        <Button variant="solid" className="w-full">
          {`${serviceName}로 이동하기`}
        </Button>
      </Link>
    </Box>
  );
};

export default SignUpCompleteContents;
