import { Box, Typography } from '@wishket/design-system';

import {
  BulletList,
  CustomOrderLi,
  Description,
  InformationBox,
  OrderedList,
  SubTitle,
  TableBody,
  TableHead,
  Title,
  UnOrderedList,
  UnStyledList,
} from '../Terms.parts';

const WishketPrivacy = () => {
  return (
    <Box className="flex w-full flex-col gap-8 break-all pt-8">
      <Title>위시켓 개인정보 처리방침</Title>
      <Description>{`위시켓은 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리 방침을 두고 있습니다. 위시켓은 개인정보 처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.`}</Description>
      <SubTitle>제1조 (기본 원칙)</SubTitle>
      <Description>{`주식회사 위시켓(이하 “위시켓”)은 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 통신비밀보호법, 전기통신사업법, 개인정보보호법 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보 처리방침을 정하여 회원 권익 보호에 최선을 다하고 있습니다.`}</Description>
      <SubTitle>제2조 (최초 회원가입 시 수집하는 개인정보)</SubTitle>
      <Description>
        {`위시켓은 회원가입 및 관리, 원활한 고충처리, 각종 서비스의 제공을 위해 최초 회원가입 당시 회원으로부터 다음의 개인정보를 수집하고 있습니다.`}
        <BulletList className="pl-5">{`필수항목: 이메일 주소, 아이디, 비밀번호, 이용 목적`}</BulletList>
      </Description>
      <SubTitle>제3조 (서비스 이용 시 수집하는 개인정보)</SubTitle>
      <Description>
        <OrderedList>
          <li>
            {`위시켓은 가입 후 원활하고 신뢰할 수 있는 서비스의 제공을 위해 다음의 개인정보를 수집하고 있습니다.`}
            <UnStyledList>
              <CustomOrderLi orderNumber={1}>
                {`필수`}
                <ul>
                  <BulletList>
                    <Box className="flex flex-col">
                      <b>{`개인\n`}</b>
                      {`이름, 성별, 생년월일, 주소지, 형태(팀/개인), 내/외국인 여부, 핸드폰 및 유선 전화 번호, 직군, 계좌 정보, 미성년자의 경우 법정대리인 정보(이름, 성별, 생년월일, 내/외국인 여부, 핸드폰 및 유선 전화 번호, CI, DI), 자기소개, 보유 기술, 포트폴리오, CI, DI, 주민등록번호`}
                    </Box>
                  </BulletList>
                  <BulletList>
                    <Box className="flex flex-col">
                      <b>{`사업자\n`}</b>
                      {`소재지, 사업자/단체명, 기업 형태(법인/개인사업자), 핸드폰 및 유선 전화 번호, 계좌 정보, 대표자 및 담당자 정보(이름, 성별, 생년월일, 내/외국인 여부, CI, DI), 대표자 및 담당자 연락처(이메일 주소, 핸드폰 및 유선 전화 번호), 미성년자의 경우 법정대리인 정보(이름, 성별, 생년월일, 내/외국인 여부, 핸드폰 및 유선 전화 번호, CI, DI), 자기소개, 보유 기술, 포트폴리오, CI, DI, 개인사업자의 경우 대표자 주민등록번호, 법인사업자의 경우 법인등록번호, 사업자등록번호`}
                    </Box>
                  </BulletList>
                </ul>
              </CustomOrderLi>
              <CustomOrderLi orderNumber={2}>
                {`선택`}
                <UnOrderedList>
                  <BulletList>{`팩스번호, 신용카드 정보, 프로필 사진, 프로젝트 선호 조건, 회사 연혁, 협력업체 및 기관, 경력, 학력, 자격증, 수상이력, 서명 및 날인 정보`}</BulletList>
                </UnOrderedList>
              </CustomOrderLi>
            </UnStyledList>
          </li>
          <li>
            {`위시켓은 서비스 이용과정에서 추가로 생성되는 다음 각 호의 회원의 정보들을 수집할 수 있습니다.`}
            <ul>
              <CustomOrderLi
                orderNumber={1}
              >{`IP주소, 쿠키, 기기식별번호(MAC주소 등), 기기정보, 위치정보, 서비스 이용 기록, 방문 기록, 접속 로그, 불량 이용 기록 등 정보`}</CustomOrderLi>
            </ul>
          </li>
          <li>
            {`위시켓은 서비스 이용과정에서 추가로 생성되는 다음 각 호의 회원의 정보들을 수집할 수 있습니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`본적으로 수집되는 정보: IP주소, 쿠키, 기기식별번호(MAC주소 등), 서비스 이용 기록, 방문 기록, 접속 로그, 불량 이용 기록 등 정보`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`서비스 이용 시 수집되는 정보: 휴대전화 전화번호, 신용카드 정보, 은행계좌 정보, 결제 기록 등 정보`}</CustomOrderLi>
            </UnStyledList>
          </li>
        </OrderedList>
      </Description>
      <SubTitle>제3조의 1 (가명정보의 처리)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`가명정보는 개인정보의 일부를 삭제하거나 일부 또는 전부를 대체하는 등의 방법으로 추가 정보가 없이는 특정 개인을 알아볼 수 없도록 처리한 정보를 말합니다.`}</li>
          <li>{`위시켓은 가명정보를 통계작성, 과학적 연구 등의 목적으로 처리합니다.`}</li>
          <li>{`위시켓은 가명정보를 이용할 때 처리 및 보유 기간을 정하고 있으며, 해당 기간 경과 시 더 이상 이용하지 않으며 해당 정보를 파기하고 있습니다.`}</li>
          <li>{`위시켓은 가명정보를 제3자에게 제공하는 경우, 업무 처리 목적에 필요한 최소한의 가명정보를 제공합니다. 이 경우 가명정보 재식별 금지, 가명정보 및 추가정보의 분리 보관, 임직원 교육 등 안전성 확보를 위한 준수사항이 반영된 계약서를 사용하여 계약을 체결하고 있습니다.`}</li>
          <li>{`위시켓은 가명정보 처리 업무를 위탁하는 경우, 고객님이 쉽게 확인할 수 있도록 홈페이지에 공개합니다. 위탁하는 업무의 내용이나 수탁자가 변경된 경우에도 홈페이지를 통하여 고객에게 알려드립니다. 위탁계약 체결 시 위탁 목적 외 가명처리 금지, 재식별 금지 등 안전한 관리를 위해 필요한 준수사항이 반영된 계약을 체결하고 있으며, 수탁자가 안전하게 가명정보를 처리하고 있는 지에 대해 관리ㆍ감독 및 교육을 실시하고 있습니다.`}</li>
          <li>{`위시켓은 가명정보 처리 시마다 제3조의 개인정보 중에서 이용 목적에 필요한 최소한의 항목을 가명처리하고 있습니다.`}</li>
          <li>
            {`위시켓은 가명정보의 안전한 이용 및 보호를 위해 다음의 사항을 준수하고 있습니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`가명정보, 추가정보의 안전한 관리를 위한 내부관리 계획 수립ㆍ운영`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`가명정보와 추가정보는 별도 분리 보관`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={3}
              >{`가명정보 및 추가정보에 접근할 수 있는 권한자 지정 및 최소화, 인사이동 발생 시 지체없이 권한 변경/말소, 필요한 접근통제 조치 적용, 접근권한 부여/변경 등 기록을 최소 3년간 보관`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={4}
              >{`가명정보 처리를 위탁하는 경우, 위탁 목적 외 가명처리 금지, 재식별 금지 등 안전한 관리를 위한 준수사항이 반영된 계약 체결, 수탁자가 안전하게 처리하고 있는 지에 대한 관리ㆍ감독 및 교육 실시`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={5}
              >{`가명정보처리자에 대해 연 1회 이상 교육 실시`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={6}
              >{`가명정보 처리 기록 작성 및 그 기록을 최소 3년간 보관`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={7}
              >{`가명정보의 재식별 금지하고 있으며, 가명정보 처리 과정에 재식별을 하게 된 경우 즉시 개인정보 보호책임자 및 관리부서에 통지하고 해당 정보는 즉시 삭제`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={8}
              >{`기타 법규 상 필요한 안전성 확보 조치`}</CustomOrderLi>
            </UnStyledList>
          </li>
        </OrderedList>
      </Description>
      <Box className="w-full overflow-x-scroll desktop:scrollbar-hide">
        <table className="w-max desktop:w-full">
          <caption className="mb-3 text-left">
            <Typography variant="body2" className="font-medium text-w-gray-900">
              가명정보 제공(송·수신) 주체
            </Typography>
          </caption>
          <TableHead>
            <td className="box-border py-3 pr-3">정보제공자(송신업체)</td>
            <td className="box-border p-3">정보수령자(수신업체)</td>
            <td className="box-border p-3">제공·이용 목적</td>
            <td className="box-border p-3">가명정보 항목</td>
            <td className="box-border py-3 pl-3">보유 및 이용 기간</td>
          </TableHead>
          <TableBody>
            <tr>
              <td className="py-3 pr-3">주식회사 위시켓</td>
              <td className="p-3">삼성카드 주식회사</td>
              <td className="max-w-[240px] break-keep p-3">
                사업자신용평가모형의변별력 향상을 위한 대안정보 분석 연구
              </td>
              <td className="box-border flex gap-2 break-keep p-3 leading-normal">
                <div className="flex flex-col gap-2">
                  <BulletList>기준년월</BulletList>
                  <BulletList>회원가입일</BulletList>
                  <BulletList>지역</BulletList>
                  <BulletList>기업형태</BulletList>
                  <BulletList>직군</BulletList>
                  <BulletList>프로젝트 진행방식</BulletList>
                  <BulletList>현재 활동 여부</BulletList>
                  <BulletList>로그인 횟수</BulletList>
                </div>
                <div className="flex flex-col gap-2">
                  <BulletList>지원 수</BulletList>
                  <BulletList>미팅 수</BulletList>
                  <BulletList>계약 수</BulletList>
                  <BulletList>계약 금액</BulletList>
                  <BulletList>경고 수</BulletList>
                  <BulletList>지원요청 수</BulletList>
                  <BulletList>평가 수</BulletList>
                  <BulletList>평점</BulletList>
                </div>
              </td>
              <td className="py-3 pl-3">제공 후 6개월</td>
            </tr>
          </TableBody>
        </table>
      </Box>
      <SubTitle>제4조 (민감한 개인정보의 수집 금지 등)</SubTitle>
      <Description>{`위시켓은 회원의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종, 사상, 신조, 정치적 성향, 범죄기록, 의료정보 등)는 수집하지 않으며, 회원이 선택 항목의 정보를 입력하지 않은 경우에도 위시켓이 제공하는 서비스 이용에 제한은 없습니다.`}</Description>
      <SubTitle>제5조 (개인정보 수집 방법)</SubTitle>
      <Description>
        {`위시켓은 다음 각 호의 방법으로 개인정보를 수집합니다.`}
        <UnStyledList>
          <CustomOrderLi
            orderNumber={1}
          >{`위시켓이 제공하는 플랫폼, 서면 양식, 전화/팩스 등 서비스 가입이나 사용 중 회원의 자발적 제공을 통한 수집`}</CustomOrderLi>
          <CustomOrderLi
            orderNumber={2}
          >{`생성 정보 수집 툴을 통한 수집`}</CustomOrderLi>
        </UnStyledList>
      </Description>
      <SubTitle>제6조 (개인정보 수집 및 이용 목적)</SubTitle>
      <Description>
        {`위시켓은 다음 각 호의 목적으로 회원의 개인정보를 수집 및 이용합니다. 수집한 개인정보는 다음의 목적 이외의 용도로 사용되지 않으며 이용 목적이 변경될 시에는 별도의 사전동의를 구합니다.`}
        <UnStyledList>
          <CustomOrderLi
            orderNumber={1}
          >{`회원가입 의사의 확인, 연령 확인 및 법정대리인 동의 진행, 이용자 및 법정대리인의 본인 확인, 이용자 식별, 회원탈퇴 의사의 확인 등 회원관리를 위하여 개인정보를 이용합니다.`}</CustomOrderLi>
          <CustomOrderLi
            orderNumber={2}
          >{`콘텐츠 등 기존 서비스 제공(광고 포함)에 더하여, 인구통계학적 분석, 서비스 방문 및 이용기록의 분석, 개인정보 및 관심에 기반한 이용자간 관계의 형성, 지인 및 관심사 등에 기반한 맞춤형 서비스 제공 등 신규 서비스 요소의 발굴 및 기존 서비스 개선을 위하여 개인정보를 이용합니다.`}</CustomOrderLi>
          <CustomOrderLi
            orderNumber={3}
          >{`법령 및 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재, 계정도용 및 부정거래 방지, 약관 개정 등의 고지사항 전달, 분쟁조정을 위한 기록 보존, 민원처리 등 이용자 보호 및 서비스 운영을 위하여 개인정보를 이용합니다.`}</CustomOrderLi>
          <CustomOrderLi
            orderNumber={4}
          >{`유료 서비스 제공에 따르는 본인인증, 구매 및 요금 결제, 상품 및 서비스의 배송을 위하여 개인정보를 이용합니다.`}</CustomOrderLi>
          <CustomOrderLi
            orderNumber={5}
          >{`이벤트 정보 및 참여기회 제공, 광고성 정보 제공 등 마케팅 및 프로모션 목적으로 개인정보를 이용합니다.`}</CustomOrderLi>
          <CustomOrderLi
            orderNumber={6}
          >{`서비스 이용기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 서비스 분석 및 통계에 따른 맞춤 서비스 제공 및 광고 게재 등에 개인정보를 이용합니다.`}</CustomOrderLi>
          <CustomOrderLi
            orderNumber={7}
          >{`보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는 서비스 이용환경 구축을 위해 개인정보를 이용합니다.`}</CustomOrderLi>
        </UnStyledList>
      </Description>
      <SubTitle>제7조 (개인정보 제3자 제공의 기본 원칙)</SubTitle>
      <Description>{`위시켓은 회원들의 개인정보를 제6조에 고지한 범위 내에서 사용하며, 회원의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 회원의 개인정보를 제3자에 제공하거나 외부에 공개하지 않습니다.`}</Description>
      <SubTitle>제8조 (예외적 개인정보 제3자 제공)</SubTitle>
      <Description>
        {`위시켓은 법령에 근거가 있는 등의 예외적인 경우를 제외하고 회원의 동의 없이 개인정보를 제3자에게 제공하거나 외부에 공개하고 있지 않습니다. 단, 다음 각호의 경우는 예외로 합니다.`}
        <UnStyledList>
          <CustomOrderLi
            orderNumber={1}
          >{`회원들이 사전에 공개에 동의한 경우`}</CustomOrderLi>
          <CustomOrderLi
            orderNumber={2}
          >{`관련 법령에 근거하여 국가, 공공기관 등이 요청하거나, 수사기관이 수사목적으로 법령에 정해진 절차와 방법에 따라 요구하는 경우`}</CustomOrderLi>
          <CustomOrderLi
            orderNumber={3}
          >{`위시켓이 제공하는 서비스를 통해 거래가 성사되었을 때 쌍방 당사자 간의 계약과 관련한 정보를 필요한 범위 내에서 쌍방당사자에게 제공하는 경우`}</CustomOrderLi>
          <CustomOrderLi
            orderNumber={4}
          >{`통계작성 및 학술연구 등의 목적을 위하여 필요한 경우 특정 개인을 알아볼 수 없는 형태로 개인정보를 제공하는 경우`}</CustomOrderLi>
        </UnStyledList>
      </Description>
      <SubTitle>제8조의 1 (개인정보의 국외이전)</SubTitle>
      <Description>
        <Box className="flex flex-col gap-8">
          {`위시켓은 아래와 같은 목적으로 국외 업체에게 업무를 위탁하고 있으며 회원의 개인정보가 국외로 이전됩니다. 위시켓은 개인정보를 위탁 받은 국외 업체가 국내 개인정보 관련 법령 및 가이드라인을 준수하여 관리하도록 관리하고 있습니다. 회원은 위시켓 또는 해당 업체의 개인정보보호책임자 및 담당부서를 통해 개인정보의 국외이전을 거부할 수 있으며, 이 경우 해당 회원의 개인정보를 국외 이전 대상에서 제외합니다. 다만, 이 경우 위시켓의 서비스 중 개인정보 국외 이전이 필수적으로 수반되는 서비스 이용이 제한될 수 있습니다.`}
          <Box className="w-full overflow-x-scroll desktop:scrollbar-hide">
            <table className="w-max desktop:w-full">
              <TableHead>
                <td className="box-border w-[182px] py-2 pr-2">
                  개인정보를 이전 받는 자
                </td>
                <td className="box-border w-[194px] whitespace-pre p-2">
                  {`이전 받는 자의\n정보관리책임자 연락처`}
                </td>
                <td className="box-border w-[194px] whitespace-pre p-2">
                  {`이전 받는 자의\n개인정보 이용목적`}
                </td>
                <td className="box-border w-[194px] p-2">
                  이전하는 개인정보 항목
                </td>
                <td className="box-border w-[194px] whitespace-pre p-2">
                  {`이전되는 국가, 이전 일시,\n이전 방법`}
                </td>
                <td className="box-border py-2 pl-2">
                  개인정보 보유 및 이용기간
                </td>
              </TableHead>
              <TableBody>
                <tr>
                  <td className="box-border py-2 pr-2">INFOBIP LLC</td>
                  <td className="box-border p-2">
                    Data-protection-officer@infobip.com
                  </td>
                  <td className="box-border p-2">SMS 및 카카오 알림톡 발송</td>
                  <td className="box-border p-2">성명, 휴대전화번호, 이메일</td>
                  <td className="box-border p-2">
                    독일, 서비스 이용 시, 공용 네트워크 이용 전송(VPN, 보안
                    프로토콜 처리)
                  </td>
                  <td className="box-border py-2 pl-2">
                    개인정보 이전 후 5년 간 혹은 위탁 계약 종료 시
                  </td>
                </tr>
                <tr>
                  <td className="box-border py-2 pr-2">Google LLC</td>
                  <td className="box-border p-2">googlekrsupport@google.com</td>
                  <td className="box-border p-2">
                    회원의 Google 미디어 및 플랫폼 내 활동 통계자료를 통해 신규
                    서비스 발굴 및 기존 서비스 개선
                  </td>
                  <td className="box-border p-2">
                    휴대전화번호, 이메일(SHA256 암호화)
                  </td>
                  <td className="box-border p-2">
                    미국, 서비스 이용 시, 공용 네트워크 이용 전송(VPN, 보안
                    프로토콜 처리)
                  </td>
                  <td className="box-border py-2 pl-2">
                    회원 탈퇴 또는 동의 철회 시
                  </td>
                </tr>
              </TableBody>
            </table>
          </Box>
        </Box>
      </Description>
      <SubTitle>제9조 (개인정보의 취급 위탁)</SubTitle>
      <Description>{`위시켓은 개인정보의 처리와 관련하여 아래와 같이 업무를 위탁하고 있으며, 관계 법령에 따라 위탁 처리되는 개인정보가 안전하게 관리될 수 있도록 필요한 조치를 다하고 있습니다. 위탁 처리하는 정보는 서비스 제공에 필요한 최소한의 범위에 국한되고, 회사에서 위탁처리 되고 있는 업무와 수탁업체는 아래와 같습니다. 위탁사항이 변경되는 경우 해당 사실을 알려드리겠습니다.`}</Description>
      <SubTitle>제10조 (개인정보 보유 및 이용기간의 기본 원칙)</SubTitle>
      <Description>{`원칙적으로 회원의 개인정보는 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기됩니다.`}</Description>
      <SubTitle>제11조 (위시켓 내부 방침에 의한 개인정보의 보유)</SubTitle>
      <Description>
        {`제10조에도 불구하고, 위시켓은 거래 관련 분쟁 방지를 위하여 위시켓 내부 방침에 따라 회원 가입 및 관리 관련 정보를 다음 각 호의 기간 동안 보존합니다.`}
        <UnStyledList className="pl-8">
          <CustomOrderLi
            orderNumber={1}
          >{`원칙: 회원 탈퇴 시까지`}</CustomOrderLi>
          <CustomOrderLi
            orderNumber={2}
          >{`회원에 대하여 관계 법령 위반에 따른 수사·조사 등이 진행중인 경우: 해당 수사·조사 종료 시까지`}</CustomOrderLi>
          <CustomOrderLi
            orderNumber={3}
          >{`위시켓과 회원 사이에 채권·채무관계가 잔존하는 경우: 해당 채권·채무관계 정산 완료 시까지`}</CustomOrderLi>
          <CustomOrderLi
            orderNumber={4}
          >{`회원에서 탈퇴한 후 회원 재가입, 임의 해지 등을 반복적으로 행하여 정당하지 않은 경제상의 이익을 취하거나 이 과정에서 명의를 무단으로 사용하는 편법과 불법행위를 하는 회원을 차단 하고자 회원 탈퇴 후 24개월 동안 회원의 성명, 상호, 유선전화 번호, 휴대폰 번호, 사업자등록번호, 법인등록번호, 주민등록번호(또는 CI), DI, 아이디(ID), 비밀번호(Password), 이메일(E-mail)정보를 보관 하며, 로그기록, 접속 아이피(IP)정보는 36개월간 보관합니다.`}</CustomOrderLi>
        </UnStyledList>
      </Description>
      <SubTitle>제12조 (관련 법령에 의한 개인정보의 보유)</SubTitle>
      <Description>
        {`위시켓은 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 다음 각 호에 따라 회원의 해당 개인정보를 보관하며, 그 목적의 범위 내에서만 이를 이용합니다. 단, 위시켓 이용계약(이용약관)에 의해 이용계약이 존속 중인 회원(탈퇴하지 아니한 회원)의 경우 보관기간은 보존의무기간 이상 보관할 수 있으며, 해당 기간이 경과된 기록에 대해서는 회원의 삭제 요청이 있는 경우 파기합니다.`}
        <UnStyledList className="pl-8">
          <CustomOrderLi
            orderNumber={1}
          >{`계약 또는 청약철회 등에 관한 기록: 전자상거래 등에서의 소비자보호에 관한 법률에 따라 5년간 보존`}</CustomOrderLi>
          <CustomOrderLi
            orderNumber={2}
          >{`대금결제 및 재화 등의 공급에 관한 기록: 전자상거래 등에서의 소비자보호에 관한 법률에 따라 5년간 보존`}</CustomOrderLi>
          <CustomOrderLi
            orderNumber={3}
          >{`소비자의 불만 또는 분쟁 처리에 관한 기록: 전자상거래 등에서의 소비자보호에 관한 법률에 따라 3년간 보존`}</CustomOrderLi>
          <CustomOrderLi
            orderNumber={4}
          >{`표시·광고에 관한 기록: 전자상거래 등에서의 소비자보호에 관한 법률에 따라 6개월간 보존`}</CustomOrderLi>
          <CustomOrderLi
            orderNumber={5}
          >{`본인확인에 관한 기록: 정보통신망 이용촉진 및 정보보호 등에 관한 법률에 따라 6개월간 보존`}</CustomOrderLi>
          <CustomOrderLi
            orderNumber={6}
          >{`전기통신일시, 개시·종료시간, 가입자 번호, 사용 도수, 발신 기지국 위치추적자료에 관한 기록: 통신비밀보호법에 따라 1년간 보존`}</CustomOrderLi>
          <CustomOrderLi
            orderNumber={7}
          >{`컴퓨터통신, 인터넷 로그기록자료, 접속지 추적 자료: 통신비밀보호법에 따라 3개월간 보존`}</CustomOrderLi>
          <CustomOrderLi
            orderNumber={8}
          >{`신용정보의 수집/처리 및 이용 등에 관한 기록: 3년`}</CustomOrderLi>
        </UnStyledList>
      </Description>
      <SubTitle>제13조 (개인정보의 파기 절차 및 방법)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`위시켓은 원칙적으로 개인정보 이용 목적이 달성된 경우에는 파기 사유가 발생한 개인정보를 선정하고, 위시켓의 개인정보 보호책임자의 승인을 받아 지체 없이 해당 개인정보를 파기합니다.`}</li>
          <li>
            {`파기의 절차, 기한 및 방법은 다음과 같습니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`파기 절차: 이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`파기 기한: 이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 이용 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={3}
              >{`파기 방법: 종이에 기록, 저장된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하며, 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없도록 기술적 방법을 사용하여 삭제합니다.`}</CustomOrderLi>
            </UnStyledList>
          </li>
        </OrderedList>
      </Description>
      <SubTitle>제14조 (개인정보의 수집, 이용, 제공에 대한 동의 철회)</SubTitle>
      <Description>{`회원은 회원 가입 등을 통해 개인정보의 수집, 이용, 제공에 대하여 동의한 내용을 언제든지 철회할 수 있습니다.`}</Description>
      <SubTitle>제15조 (회원의 권리와 행사방법)</SubTitle>
      <Description>
        <OrderedList>
          <li>
            {`회원은 위시켓에 대해 언제든지 다음과 각 호와 같은 권리를 행사할 수 있습니다.`}
            <UnStyledList>
              <CustomOrderLi
                orderNumber={1}
              >{`개인정보 열람 요구`}</CustomOrderLi>
              <CustomOrderLi
                orderNumber={2}
              >{`오류 등이 있을 경우 정정 요구`}</CustomOrderLi>
              <CustomOrderLi orderNumber={3}>{`삭제 요구`}</CustomOrderLi>
              <CustomOrderLi orderNumber={4}>{`처리 정지 요구`}</CustomOrderLi>
            </UnStyledList>
          </li>
          <li>{`제1항에 따른 권리 행사는 위시켓에 대해 개인정보 보호법 시행규칙 별지 제8호 서식에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 위시켓은 본인 확인을 위한 요청인의 신분증 사본 등의 증표를 제시 받아 해당 요구가 진정한 본인의 의사인지 여부를 확인할 수 있으며, 본인으로 확인되고 개인정보에 오류가 있거나 보존기간을 경과한 것이 판명되는 등 정정 또는 삭제할 필요가 있다고 인정되는 경우 지체 없이 그에 따른 조치를 취합니다.`}</li>
          <li>{`회원이 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 위시켓은 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다. 제1항에 따른 권리 행사는 회원의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 ”개인정보 처리 방법에 관한 고시(제2020호-7호)” 별지 제11호 서식에 따른 위임장을 제출하여야 합니다.`}</li>
          <li>{`개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 회원의 권리가 제한될 수 있습니다.`}</li>
          <li>{`개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.`}</li>
          <li>{`위시켓은 정보주체 권리에 따른 열람의 요구, 정정 · 삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제16조 (아동의 개인정보 보호)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`위시켓은 만 14세 미만 아동에 대한 개인정보를 수집할 때 법정대리인의 동의를 얻어 해당 서비스 수행에 필요한 최소한의 개인정보를 수집합니다. 단, 법정대리인의 성명 및 연락처에 관한 정보는 법정대리인의 동의 없이 해당 아동으로부터 직접 수집할 수 있습니다.`}</li>
          <li>
            {`위시켓은 다음 중 하나의 방법으로 제1항에 따라 법정대리인이 동의하였는지를 확인합니다.`}
            <UnOrderedList>
              <BulletList>{`동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고 위시켓이 그 동의 표시를 확인했음을 법정대리인의 휴대전화 문자메시지로 알리는 방법`}</BulletList>
              <BulletList>{`동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고 법정대리인의 신용카드 · 직불카드 등의 카드정보를 제공받는 방법`}</BulletList>
              <BulletList>{`동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고 법정대리인의 휴대전화 본인인증 등을 통하여 본인 여부를 확인하는 하는 방법`}</BulletList>
              <BulletList>{`동의 내용이 적힌 서면을 법정대리인에게 직접 발급하거나 우편 또는 팩스를 통하여 전달하고, 법정대리인이 동의 내용에 대하여 서명날인 후 제출하도록 하는 방법`}</BulletList>
              <BulletList>{`동의 내용이 적힌 전자우편을 발송하고 법정대리인으로부터 동의의 의사표시가 적힌 전자우편을 전송 받는 방법`}</BulletList>
              <BulletList>{`전화를 통하여 동의 내용을 법정대리인에게 알리고 동의를 받거나 인터넷 주소 등 동의 내용을 확인할 수 있는 방법을 안내하고 재차 전화 통화를 통하여 동의를 받는 방법`}</BulletList>
              <BulletList>{`그 밖에 위와 준하는 방법으로서 법정대리인에게 동의 내용을 알리고 동의의 의사표시를 확인하는 방법`}</BulletList>
            </UnOrderedList>
          </li>
          <li>{`법정대리인은 만 14세 미만 아동의 개인정보 수집 · 이용 또는 제공에 대한 동의를 철회할 수 있으며, 해당 아동이 제공한 개인정보에 대한 열람 또는 오류의 정정을 요구할 수 있습니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제17조 (위시켓의 개인정보 열람 및 이용 제한)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`회원 또는 법정대리인의 요청에 의해 해지 또는 삭제, 정정된 개인정보는 제10조 내지 제12조에 명시된 바에 따라 처리되고, 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.`}</li>
          <li>{`회원 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입 해지를 요청할 수도 있습니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제18조 (쿠키의 운용)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`위시켓은 회원에게 특화된 맞춤서비스를 제공하기 위해서 회원들의 정보를 수시로 저장하고 찾아내는 '쿠키(cookie)' 등을 운용합니다. 쿠키란 웹사이트를 운영하는 데 이용되는 서버가 회원의 브라우저에 보내는 아주 작은 텍스트 파일로서 회원의 컴퓨터 하드디스크에 저장되기도 합니다.`}</li>
          <li>{`위시켓은 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 회원의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 횟수 파악 등을 통한 타깃 마케팅 및 개인 맞춤 서비스 제공 등의 목적으로 쿠키를 사용합니다.`}</li>
          <li>{`회원은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 회원은 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.`}</li>
          <li>
            {`제3항에 따라 쿠키 설정을 거부하는 방법으로, 회원은 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.`}
            <UnOrderedList>
              <BulletList>{`Chrome : 우측 상단 메뉴 → 설정 → 개인 정보 보호 및 보안 → 쿠키 및 기타 사이트 데이터 → 설정`}</BulletList>
              <BulletList>{`Safari : 환경설정 → "크로스 사이트 추적 방지" 및 "모든 쿠키 차단"`}</BulletList>
              <BulletList>{`Microsoft Edge : 설정 → 쿠키및 사이트 권한 → 쿠키 설정`}</BulletList>
            </UnOrderedList>
          </li>
          <li>{`회원이 쿠키 설치를 거부하는 경우 로그인이 필요한 일부 서비스 이용에 어려움이 있을 수 있습니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>
        제18조의 1 (행태정보의 수집 · 이용 · 제공 및 거부 등에 관한 사항)
      </SubTitle>
      <Description>
        <OrderedList>
          <li>{`위시켓은 서비스 이용과정에서 정보주체에게 최적화된 맞춤형 서비스 및 혜택, 온라인 맞춤형 광고 등을 제공하기 위하여 행태정보를 수집 · 이용하고 있습니다.`}</li>
          <li>
            {`위시켓은 다음과 같이 행태정보를 수집합니다.`}
            <Box className="my-8 w-full overflow-x-scroll desktop:scrollbar-hide">
              <table className="w-max desktop:w-full">
                <TableHead>
                  <td className="box-border w-[240px] py-2 pr-2">
                    수집하는 행태정보의 항목
                  </td>
                  <td className="box-border w-[240px] p-2">
                    행태정보의 수집 방법
                  </td>
                  <td className="box-border w-[300px] p-2">
                    행태정보의 수집 목적
                  </td>
                  <td className="box-border w-[240px] py-2 pl-2">
                    보유 · 이용기간 및 이후 정보처리 방법
                  </td>
                </TableHead>
                <TableBody>
                  <tr>
                    <td className="box-border w-[240px] py-2 pr-2">
                      이용자의 웹사이트/앱 서비스 방문이력, 검색이력, 구매이력
                    </td>
                    <td className="box-border w-[240px] p-2">
                      이용자의 웹사이트 및 앱 방문/실행시 자동 수집
                    </td>
                    <td className="box-border w-[300px] p-2">
                      이용자의 관심, 성향에 기반한 개인 맞춤형
                      상품추천서비스(광고포함)를 제공
                    </td>
                    <td className="box-border w-[240px] py-2 pl-2">
                      수집일로부터 최대 1년간 보유 · 이용되며 이후 지체없이 삭제
                    </td>
                  </tr>
                </TableBody>
              </table>
            </Box>
          </li>
          <li>
            {`위시켓은 다음과 같이 온라인 맞춤형 광고 사업자가 행태정보를 수집 · 처리하도록 허용하고 있습니다. 각 서비스 명칭 및 정책 등은 온라인 맞춤형 광고 사업자의 사정에 따라 달라질 수 있습니다.`}
            <UnStyledList>
              <li>
                {`가.  Google Analytics`}
                <UnOrderedList className="pl-8">
                  <BulletList>{`개요 : 웹 사이트 트래픽을 추적하고 보고할 수 있는 웹 분석 서비스`}</BulletList>
                  <BulletList>{`행태정보 수집방법 : 이용자가 위시켓의 웹사이트를 방문하거나 앱을 실행할 때 자동 수집 및 전송`}</BulletList>
                  <BulletList>{`수집 · 처리되는 행태정보 항목 : 웹사이트 방문이력, 검색 이력, 프로젝트 등록 이력, 회원가입 여부`}</BulletList>
                  <BulletList>{`보유 · 이용기간 : 온라인 맞춤형 광고 사업자의 서비스 정책 및 개인정보 처리방침에 따름`}</BulletList>
                  <BulletList>{`서비스 정책 : https://support.google.com/analytics/answer/6004245?hl=ko`}</BulletList>
                  <BulletList>{`수집 거부 : https://support.google.com/analytics/answer/9019185?hl=ko`}</BulletList>
                </UnOrderedList>
              </li>
              <li>
                {`나.  Google Ads`}
                <UnOrderedList className="pl-8">
                  <BulletList>{`개요 : 사용자가 웹사이트에서 취한 행동을 파악하여 맞춤형 광고를 제공하는 도구`}</BulletList>
                  <BulletList>{`행태정보 수집방법 : 이용자가 위시켓의 웹사이트를 방문하거나 앱을 실행할 때 자동 수집 및 전송`}</BulletList>
                  <BulletList>{`수집 · 처리되는 행태정보 항목 : 웹사이트 방문이력, 검색 이력, 프로젝트 등록 이력, 회원가입 여부`}</BulletList>
                  <BulletList>{`보유 · 이용기간 : 온라인 맞춤형 광고 사업자의 서비스 정책 및 개인정보 처리방침에 따름`}</BulletList>
                  <BulletList className="break-all">{`서비스 정책 : https://policies.google.com/terms?hl=ko`}</BulletList>
                  <BulletList className="break-all">{`수집 거부 : https://policies.google.com/privacy/additional?hl=ko`}</BulletList>
                </UnOrderedList>
              </li>
              <li>
                {`다.  Meta Pixel`}
                <UnOrderedList className="pl-8">
                  <BulletList>{`개요 : 사용자가 웹사이트에서 취한 행동을 파악하여 광고의 성과를 측정하는 분석 도구`}</BulletList>
                  <BulletList>{`행태정보 수집방법 : 이용자가 위시켓의 웹사이트를 방문하거나 앱을 실행할 때 자동 수집 및 전송`}</BulletList>
                  <BulletList>{`수집 · 처리되는 행태정보 항목 : 웹사이트 방문이력, 검색 이력, 프로젝트 등록 이력, 회원가입 여부`}</BulletList>
                  <BulletList>{`보유 · 이용기간 : 온라인 맞춤형 광고 사업자의 서비스 정책 및 개인정보 처리방침에 따름`}</BulletList>
                  <BulletList className="break-all">{`서비스 정책 : www.facebook.com/about/privacy/`}</BulletList>
                  <BulletList className="break-all">{`수집 거부 : https://www.facebook.com/settings/?tab=ads`}</BulletList>
                </UnOrderedList>
              </li>
              <li>
                {`라.  네이버 애널리틱스`}
                <UnOrderedList className="pl-8">
                  <BulletList>{`개요 : 사용자가 웹사이트에서 취한 행동을 파악하여 광고의 성과를 측정하는 분석 도구`}</BulletList>
                  <BulletList>{`행태정보 수집방법 : 이용자가 위시켓의 웹사이트를 방문하거나 앱을 실행할 때 자동 수집 및 전송`}</BulletList>
                  <BulletList>{`수집 · 처리되는 행태정보 항목 : 웹사이트 방문이력, 검색 이력, 프로젝트 등록 이력, 회원가입 여부`}</BulletList>
                  <BulletList>{`보유 · 이용기간 : 온라인 맞춤형 광고 사업자의 서비스 정책 및 개인정보 처리방침에 따름`}</BulletList>
                  <BulletList className="break-all">{`서비스 정책 : https://analytics.naver.com/popup/termofuse.html`}</BulletList>
                  <BulletList className="break-all">{`수집 거부 : https://privacy.naver.com/knowledge/cookie?menu=knowledge_info_relation_cookie`}</BulletList>
                </UnOrderedList>
              </li>
            </UnStyledList>
          </li>
        </OrderedList>
      </Description>
      <SubTitle>제19조 (개인정보의 안전성 확보조치)</SubTitle>
      <Description>
        {`위시켓은 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.`}
        <UnOrderedList className="pl-8">
          <BulletList>{`개인정보 취급 직원의 최소화 및 교육: 개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.`}</BulletList>
          <BulletList>{`정기적인 자체 감사 실시: 개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.`}</BulletList>
          <BulletList>{`내부관리계획의 수립 및 시행: 개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.`}</BulletList>
          <BulletList>{`개인정보의 암호화: 이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.`}</BulletList>
          <BulletList>{`해킹 등에 대비한 기술적 대책: 위시켓은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.`}</BulletList>
          <BulletList>{`개인정보에 대한 접근 제한: 개인정보를 처리하는 데이터베이스시스템에 대한 접근 권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.`}</BulletList>
          <BulletList>{`접속 기록의 보관 및 위변조 방지: 개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.`}</BulletList>
          <BulletList>{`문서보안을 위한 잠금 장치 사용: 개인정보가 포함된 서류, 보조저장매체 등을 잠금 장치가 있는 안전한 장소에 보관하고 있습니다.`}</BulletList>
          <BulletList>{`비인가자에 대한 출입 통제: 개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.`}</BulletList>
          <BulletList>{`기타: 그 외 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.`}</BulletList>
        </UnOrderedList>
      </Description>
      <SubTitle>제20조 (취급 직원의 최소화 및 교육)</SubTitle>
      <Description>{`위시켓의 개인정보 관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 위시켓은 개인정보 처리방침의 준수를 항상 강조하고 있습니다.`}</Description>
      <SubTitle>제21조 (개인정보보호전담기구의 운영)</SubTitle>
      <Description>{`위시켓은 사내 개인정보보호전담기구 등을 통하여 개인정보 처리방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다. 단, 회원 본인의 부주의나 인터넷상의 문제로 개인 정보가 유출되어 발생한 문제에 대해 위시켓은 일체의 책임을 지지 않습니다.`}</Description>
      <SubTitle>제22조 (개인정보관리 책임자 및 담당자)</SubTitle>
      <Description>
        {`회원은 위시켓의 서비스를 이용하시며 발생하는 모든 개인정보보호에 대한 문의, 불만처리, 피해구제 등 관련 민원을 다음 각 호의 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 위시켓은 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.`}
        <UnStyledList className="mt-4 pl-8">
          <CustomOrderLi orderNumber={1}>
            {`개인정보 보호 책임자`}
          </CustomOrderLi>
          <InformationBox>
            <UnOrderedList>
              <BulletList>{`성명: 박우범`}</BulletList>
              <BulletList>{`직책: 대표이사`}</BulletList>
              <BulletList>{`연락처: (02) 6925-4849, parkwoobeom@wishket.com`}</BulletList>
            </UnOrderedList>
            <div className="ml-3 break-all">
              ※ 개인정보 보호 담당부서로 연결됩니다.
            </div>
          </InformationBox>
          <CustomOrderLi orderNumber={2}>
            {`개인정보 보호 담당부서`}
          </CustomOrderLi>
          <InformationBox className="mb-0">
            <UnOrderedList>
              <BulletList>{`부서명: 관리팀`}</BulletList>
              <BulletList>{`담당자: 박우범`}</BulletList>
              <BulletList>{`연락처: (02) 6925-4849, parkwoobeom@wishket.com`}</BulletList>
            </UnOrderedList>
          </InformationBox>
        </UnStyledList>
      </Description>
      <SubTitle>제23조 (개인정보 열람 창구)</SubTitle>
      <Description>
        {`회원은 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 위시켓은 회원의 개인정보 열람 청구가 신속하게 처리되도록 노력하겠습니다.`}
        <InformationBox className="mb-0">
          <UnOrderedList>
            <BulletList>{`부서명: 관리팀`}</BulletList>
            <BulletList>{`담당자: 박우범`}</BulletList>
            <BulletList>{`연락처: (02) 6925-4849, parkwoobeom@wishket.com`}</BulletList>
          </UnOrderedList>
        </InformationBox>
      </Description>
      <SubTitle>제24조 (개인정보 처리방침 관련 고지 및 통지 방법)</SubTitle>
      <Description>
        <OrderedList>
          <li>{`위시켓은 현행 개인정보 처리방침에 대한 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 플랫폼을 통해 변경 이유 및 그 내용을 고지합니다. 다만, 개인정보의 수집 및 활용 등에 있어 이용자 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 고지합니다.`}</li>
          <li>{`위시켓은 회원이 동의한 범위를 넘어 회원의 개인정보를 이용하거나 제3자에게 취급위탁하기 위해 회원의 추가적인 동의를 얻고자 하는 때에는 미리 회원에게 개별적으로 서면, 전자우편, 전화 등으로 해당사항을 고지합니다.`}</li>
          <li>{`위시켓은 개인정보의 수집, 보관, 처리, 이용, 제공, 관리, 파기 등을 제3자에게 위탁하는 경우에는 개인정보 처리방침 등을 통하여 그 사실을 회원에게 고지합니다.`}</li>
          <li>{`만 14세 미만 아동의 개인정보를 수집, 이용하거나 제3자에게 제공하기 위하여 법정대리인의 동의를 받고자 하는 경우에는 전화, 팩스, 우편, 아동으로 하여금 통지 내용을 법정대리인에게 제출하게 하는 방법, 법정대리인에게 이메일을 보내 본 개인정보 처리방침을 볼 수 있도록 연결(하이퍼링크)시키는 방법 또는 기타 법정대리인에게 고지 내용이 도달할 수 있는 합리적인 방법을 통하여 법정대리인에게 고지합니다.`}</li>
        </OrderedList>
      </Description>
      <SubTitle>제25조 (권익침해 구제방법)</SubTitle>
      <Description>
        <OrderedList>
          <li>
            {`회원은 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.`}
            <UnOrderedList className="pl-6">
              <BulletList>{`개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)`}</BulletList>
              <BulletList>{`개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)`}</BulletList>
              <BulletList>{`대검찰청 : (국번없이) 1301 (www.spo.go.kr)`}</BulletList>
              <BulletList>{`경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)`}</BulletList>
            </UnOrderedList>
          </li>
          <li>
            {`위시켓은 회원의 개인정보자기결정권을 보장하고, 개인정보침해로 인한 상담 및 피해 구제를 위해 노력하고 있으며, 신고나 상담이 필요한 경우 아래의 담당부서로 연락해주시기 바랍니다.`}
          </li>
          <InformationBox>
            <UnOrderedList>
              <BulletList>{`부서명: 관리팀`}</BulletList>
              <BulletList>{`담당자: 박우범`}</BulletList>
              <BulletList>{`연락처: (02) 6925-4849, parkwoobeom@wishket.com`}</BulletList>
            </UnOrderedList>
          </InformationBox>
          <li>
            {`「개인정보보호법」 제35조(개인정보의 열람), 제36조(개인정보의 정정 · 삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.`}
            <UnOrderedList>
              <BulletList className="pl-6">{`중앙행정심판위원회 : (국번없이) 110 (www.simpan.go.kr)`}</BulletList>
            </UnOrderedList>
          </li>
        </OrderedList>
      </Description>
      <Title>부칙</Title>
      <Description>
        본 개인정보 처리방침은 2024년 3월 25일부터 시행됩니다.
      </Description>
    </Box>
  );
};

export default WishketPrivacy;
