import { Box, Button, Typography } from '@wishket/design-system';

import { ContentsContainer, Icon } from '@/shared/Components';

interface PasswordFindCompleteProps {
  email: string;
  reset: () => void;
}

export const PasswordFindComplete = ({
  email,
  reset,
}: PasswordFindCompleteProps) => {
  return (
    <ContentsContainer
      title="비밀번호 찾기"
      isDivide
      testId="feature-password-find-complete"
    >
      <Box className="flex flex-col items-center gap-6">
        <Icon name="mail_send" />
        <Box className="flex w-full flex-col gap-3 text-center">
          <Typography
            variant="subTitle2"
            className="font-medium text-w-gray-900"
          >
            비밀번호 재설정 메일이 발송되었어요!
          </Typography>
          <Typography
            variant="body1"
            className="whitespace-break-spaces break-keep text-w-gray-700"
          >
            {`${email}로\n비밀번호 재설정 메일이 발송되었습니다.`}
          </Typography>
        </Box>
      </Box>
      <Box className="flex w-full flex-col gap-1.5 rounded-2xl bg-w-gray-10 px-6 py-5 text-w-gray-600">
        <Typography variant="body2" className="font-medium">
          {'이메일을 받지 못하셨나요?'}
        </Typography>
        <Typography variant="body2">
          {'메일을 받지 못했다면 스팸함을 확인해주세요'}
        </Typography>
      </Box>
      <Button className="w-full" size="md" variant="solid" onClick={reset}>
        비밀번호 재설정 메일 다시 받기
      </Button>
    </ContentsContainer>
  );
};
