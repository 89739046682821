'use client';

import { ChangeEvent, useState } from 'react';

import { LoginInputErrorType, LoginInputValueType } from '@/shared/types';
import { validateInput } from '@/shared/utils';

const useLoginForm = () => {
  const [inputError, setInputError] = useState<LoginInputErrorType>({
    emailOrId: { valid: true, errorMessage: '' },
    password: { valid: true, errorMessage: '' },
  });

  const [inputValue, setInputValue] = useState<LoginInputValueType>({
    emailOrId: '',
    password: '',
    remember: false,
  });

  const changeEmailOrIdInput = (e: ChangeEvent<HTMLInputElement>) => {
    setInputError({
      ...inputError,
      emailOrId: {
        valid: true,
        errorMessage: '',
      },
    });

    setInputValue((prev) => ({
      ...prev,
      emailOrId: e.target.value.trim(),
    }));
  };

  const changePasswordInput = (e: ChangeEvent<HTMLInputElement>) => {
    setInputError({
      ...inputError,
      password: {
        valid: true,
        errorMessage: '',
      },
    });

    setInputValue((prev) => ({
      ...prev,
      password: e.target.value,
    }));
  };

  const changeRememberInput = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue((prev) => ({
      ...prev,
      remember: e.target.checked,
    }));
  };

  const validForm = () => {
    const { emailOrId, password } = inputValue;
    const emailOrIdValidation = validateInput.emailOrId(emailOrId);
    const passwordValidation = validateInput.password(password);
    setInputError({
      emailOrId: emailOrIdValidation,
      password: passwordValidation,
    });

    return emailOrIdValidation.valid && passwordValidation.valid;
  };

  return {
    inputError,
    inputValue,
    changeEmailOrIdInput,
    changePasswordInput,
    changeRememberInput,
    validForm,
  };
};

export default useLoginForm;
