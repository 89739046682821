'use client';

import { useState } from 'react';
import { isAxiosError } from 'axios';

import { SignUpApiParams } from '@/shared/types';
import { clientAxios } from '@/shared/api';
import { ClientAuth } from '@/shared/auth';

interface SignUpError {
  id?: string;
  email?: string;
}

const EMAIL_ERROR_MESSAGES: { [key: string]: string } = {
  'This email is already in use but not confirmed. Please check your email for verification steps.':
    '입력한 이메일은 이미 사용 중입니다. 다른 이메일을 입력해 주세요.',
  'This email is already in use. Please supply a different email.':
    '입력한 이메일은 이미 사용 중입니다. 다른 이메일을 입력해 주세요.',
  'This email is already taken.':
    '입력한 아이디는 이미 사용 중입니다. 다른 이메일을 입력해 주세요.',
  default: '입력한 아이디는 이미 사용 중입니다. 다른 이메일을 입력해 주세요.',
};

const USERNAME_ERROR_MESSAGES: { [key: string]: string } = {
  'This username is already taken but not confirmed. Please check your email for verification steps.':
    '입력한 아이디는 이미 사용 중입니다. 다른 아이디를 입력해 주세요.',
  'This username is not allowed.':
    '입력한 아이디는 허용되지 않는 아이디 입니다.',
  'This username is already taken.':
    '입력한 아이디는 이미 사용 중입니다. 다른 아이디를 입력해 주세요.',
  default: '입력한 아이디는 이미 사용 중입니다. 다른 아이디를 입력해 주세요.',
};

const getEmailErrorMessage = (error?: string) => {
  return (
    EMAIL_ERROR_MESSAGES[error as keyof typeof EMAIL_ERROR_MESSAGES] ||
    EMAIL_ERROR_MESSAGES.default
  );
};

const getUsernameErrorMessage = (error?: string) => {
  return (
    USERNAME_ERROR_MESSAGES[error as keyof typeof USERNAME_ERROR_MESSAGES] ||
    USERNAME_ERROR_MESSAGES.default
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleSignUpError = (errorData: any): SignUpError | undefined => {
  const emailError = errorData.email?.[0];
  const usernameError = errorData.username?.[0];

  if (!emailError && !usernameError) return;

  return {
    email: emailError ? getEmailErrorMessage(emailError) : undefined,
    id: usernameError ? getUsernameErrorMessage(usernameError) : undefined,
  };
};

const useSignUp = () => {
  const [error, setError] = useState<SignUpError | undefined>(undefined);
  const [isSigned, setIsSigned] = useState<boolean | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const resetSignStatus = () => {
    setIsSigned(undefined);
  };

  const fetchSignUp = async ({
    signup_service,
    username,
    email,
    password1,
    password2,
    privacyInfo,
    privacyMarketing,
    receiveMarketingInfo,
  }: SignUpApiParams) => {
    setIsLoading(true);

    try {
      const { data } = await clientAxios.post('/signUpApi', {
        signup_service,
        username,
        email,
        password1,
        password2,
        privacyInfo,
        privacyMarketing,
        receiveMarketingInfo,
      });
      if (data.ok) {
        const { access, refresh } = data.data;

        ClientAuth.authenticate(access, refresh);
        setIsSigned(true);
      }
    } catch (e) {
      setIsSigned(false);

      if (isAxiosError(e) && e.response?.data?.error) {
        const { status, data } = e.response.data.error;

        if (status === 400) {
          const validationError = handleSignUpError(data);

          if (validationError) setError(validationError);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    fetch: fetchSignUp,
    reset: resetSignStatus,
    data: isSigned,
    isLoading,
    error,
  };
};

export default useSignUp;
