export const FOOTER_SNS_LINKS = [
  {
    name: 'Facebook',
    link: 'https://www.facebook.com/wishket',
    className: '',
  },
  {
    name: 'Blog',
    link: 'https://blog.wishket.com/',
    className: 'relative bottom-[2px] left-[1px]',
  },
  {
    name: 'Naver',
    link: 'https://blog.naver.com/wishket',
    className: 'relative bottom-[2px] left-[1px]',
  },
];

export const FOOTER_WISHKET_INFO_LINKS = [
  {
    name: '위시켓 소개',
    link: 'https://www.wishket.com/company-intro/',
  },
  {
    name: '신뢰와 안전',
    link: 'https://www.wishket.com/trust-safety/',
  },
];
export const FOOTER_USAGE_LINKS = [
  {
    name: '클라이언트 이용방법',
    link: 'https://www.wishket.com/customer-support/client/use-guide',
  },
  {
    name: '파트너스 이용방법',
    link: 'https://www.wishket.com/customer-support/partner/use-guide',
  },
  {
    name: '이용요금',
    link: 'https://www.wishket.com/customer-support/price/',
  },
];
export const FOOTER_CUSTOMER_SUPPORT_LINKS = [
  {
    name: '클라이언트 고객센터',
    link: 'https://www.wishket.com/customer-support/client/',
  },

  {
    name: '파트너스 고객센터',
    link: 'https://www.wishket.com/customer-support/partner/',
  },
];
export const FOOTER_OTHER_SERVICE_LINKS = [
  {
    name: '뉴스센터',
    link: 'https://www.wishket.com/news-center/all/',
  },
  {
    name: '위시켓 스토어',
    link: 'https://www.wishket.com/store/',
  },
  {
    name: '통합빌링 서비스',
    link: 'https://www.wishket.com/unity_bill/',
  },
];
