'use client';

import React, { useEffect, ComponentType } from 'react';

const DEV_STAGE = ['dev', 'local'];

type ServerComponent = {
  __isServerComponent: boolean;
};

const withComponentLogger = <P extends object>(
  WrappedComponent: ComponentType<P>,
  customLog?: object
) => {
  // 서버 컴포넌트인 경우 원본 컴포넌트를 그대로 반환
  const isServerComponent = (
    WrappedComponent as ComponentType<P> & ServerComponent
  ).__isServerComponent;

  const isDevStage = DEV_STAGE.includes(
    process.env.NEXT_PUBLIC_APP_STAGE as string
  );

  if (isServerComponent || !isDevStage) {
    return WrappedComponent;
  }

  const WithComponentLogger: React.FC<P> = (props) => {
    const start = performance.now();

    useEffect(() => {
      const end = performance.now();
      const renderTime = end - start;

      const componentName =
        WrappedComponent.displayName ||
        WrappedComponent.name ||
        'NoDisplayNameComponent';

      console.info({
        componentType: 'Client',
        componentName: componentName,
        renderTime: `${renderTime.toFixed(4)} ms`,
        props,
        ...customLog,
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    return <WrappedComponent {...props} />;
  };

  WithComponentLogger.displayName = `WithComponentLogger(${WrappedComponent.displayName || WrappedComponent.name || 'NoDisplayNameComponent'})`;

  return WithComponentLogger;
};

export default withComponentLogger;
