'use client';

import { Suspense, useEffect } from 'react';
import { useSearchParams } from 'next/navigation';
import { LoadingIndicator } from '@wishket/design-system';

import { useEmailUnSubscribeApi } from '@/entities/email/';
import { EmailUnsubscribeFail, EmailUnsubscribeSuccess } from '@/features';
import { parseQueryString } from '@/shared/utils';
import { withComponentLogger } from '@/shared/hocs';

const EmailUnsubscribe = () => {
  const { isLoading, isError, fetch, data } = useEmailUnSubscribeApi();
  const searchParams = useSearchParams();
  const isSuccess = data && !isError;

  useEffect(() => {
    const qsObject = parseQueryString(searchParams.toString());
    if (qsObject.hash && qsObject.flag) {
      fetch({
        hash: qsObject.hash,
        flag: qsObject.flag,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <LoadingIndicator size="lg" isFixed />;
  if (isSuccess) {
    return (
      <Suspense fallback={<LoadingIndicator size="lg" isFixed />}>
        <EmailUnsubscribeSuccess
          flagType={data.flag_type || ''}
          flagInfo={data.flag_info || ''}
        />
      </Suspense>
    );
  }
  return (
    <Suspense fallback={<LoadingIndicator size="lg" isFixed />}>
      <EmailUnsubscribeFail />
    </Suspense>
  );
};

export default withComponentLogger(EmailUnsubscribe);
