import {
  Box,
  Button,
  ServiceLogo,
  TextField,
  Typography,
} from '@wishket/design-system';
import { ChangeEvent, FormEvent } from 'react';

import { SignUpAgreementCheckbox } from '../SignUpForm/SignUpForm.parts';
import { BetaServiceBadge } from '../WithdrawalRequest/WithdrawalRequest.parts';

import { ContentsContainer, LoadingDialog } from '@/shared/Components';
import { PasswordChangeType } from '@/shared/types';

interface PasswordResetFormProps extends PasswordChangeType {
  isSignUp: boolean;
  agreement: boolean;
  isLoading: boolean;
  agreementError: boolean;
  onAgreementChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onPasswordChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
}

const PasswordResetForm = ({
  agreement,
  isSignUp,
  isLoading,
  errorStatus,
  inputValue,
  agreementError,
  onAgreementChange,
  onPasswordChange,
  onSubmit,
}: PasswordResetFormProps) => {
  return (
    <ContentsContainer
      title="비밀번호 재설정"
      isDivide
      testId="feature-password-reset"
    >
      {isLoading && <LoadingDialog description="비밀번호 변경 중입니다." />}
      <form
        data-testid="password-reset-form"
        onSubmit={onSubmit}
        className="flex flex-col gap-8"
      >
        <Box className="whitespace-break-spaces break-keep">
          <Typography
            variant="body1"
            className="text-w-gray-700"
          >{`위시켓 통합회원의 비밀번호를 변경할 수 있습니다.\n회원님의 비밀번호를 다시 설정해 주세요.`}</Typography>
        </Box>

        <Box className="flex justify-center gap-4 rounded-xl bg-w-gray-10 py-6">
          <Box className="flex justify-center gap-0.5">
            <ServiceLogo name="wishket_symbol" />
            <Typography variant="body2">위시켓</Typography>
          </Box>
          <Box className="flex justify-center gap-0.5">
            <ServiceLogo name="jobs_symbol" />
            <Typography variant="body2">위시켓잡스</Typography>
            <BetaServiceBadge className="pt-0.5" />
          </Box>
          <Box className="flex justify-center gap-0.5">
            <ServiceLogo name="yozmIT_symbol" />
            <Typography variant="body2">요즘IT</Typography>
          </Box>
        </Box>

        <Box className="flex w-full flex-col gap-4">
          <TextField
            name="password"
            variant="lable"
            lable="새 비밀번호"
            type="password"
            maxLength={32}
            supportMessage="8자 이상 32자 이하로 입력해 주세요."
            isError={!errorStatus?.password?.valid}
            errorMessage={errorStatus?.password?.errorMessage}
            value={inputValue?.password}
            onChange={onPasswordChange}
            isMaxLengthVisible={false}
          />
          <TextField
            name="passwordConfirm"
            variant="lable"
            lable="새 비밀번호 확인"
            type="password"
            maxLength={32}
            supportMessage="새 비밀번호를 한 번 더 입력해 주세요."
            isError={!errorStatus?.passwordConfirm?.valid}
            errorMessage={errorStatus?.passwordConfirm?.errorMessage}
            value={inputValue?.passwordConfirm}
            onChange={onPasswordChange}
            isMaxLengthVisible={false}
          />
          {isSignUp && (
            <SignUpAgreementCheckbox
              checked={agreement}
              isError={agreementError}
              onChange={onAgreementChange}
            />
          )}
        </Box>
        <Box data-testid="password-reset-form-submit">
          <Button className="w-full" size="md" variant="solid" type="submit">
            변경하기
          </Button>
        </Box>
      </form>
    </ContentsContainer>
  );
};

export default PasswordResetForm;
