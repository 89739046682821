'use client';

import { useEffect } from 'react';

import { SignUpCompleteContents } from '@/features';
import { useAuthCompletion } from '@/shared/hooks';
import { withComponentLogger } from '@/shared/hocs';

const SignUpComplete = () => {
  const { referrerService, redirectNext } = useAuthCompletion();

  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      redirectNext();
    }, 3000);
    return () => clearTimeout(redirectTimer);
  }, [redirectNext]);

  return <SignUpCompleteContents redirectService={referrerService} />;
};
export default withComponentLogger(SignUpComplete);
