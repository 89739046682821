'use client';

import { ChangeEvent, FormEvent, useState } from 'react';
import { Box, LoadingIndicator } from '@wishket/design-system';

import { useInput } from '@/shared/hooks';
import { WithdrawalRequest, WithdrawalRequestComplete } from '@/features';
import { useWithdrawalInfo, useWithdrawalRequest } from '@/entities/withdrawal';
import { useCancelWithdrawal } from '@/entities/withdrawal/cancel';
import { withComponentLogger } from '@/shared/hocs';

const Withdrawal = () => {
  const [agreementError, setAgreementError] = useState({
    valid: true,
    errorMessage: '',
  });

  const {
    isLoading: isWithdrawalRequestLoading,
    fetch: fetchWithdrawalRequest,
  } = useWithdrawalRequest();

  const { inputValue: withdrawalAgreement, handleInputChange: onCheckChange } =
    useInput(false);

  const { withdrawalInfo, fetchWithdrawalInfo, isAlreadyRequestedWithdrawal } =
    useWithdrawalInfo();

  const { fetch: fetchCancelWithdrawal } = useCancelWithdrawal();

  const onAgreementChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (checked) {
      setAgreementError({ valid: true, errorMessage: '' });
    }
    onCheckChange(e);
  };

  const handleWithdrawal = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!withdrawalAgreement) {
      return setAgreementError({
        valid: false,
        errorMessage: '탈퇴 동의에 체크해 주세요.',
      });
    }
    const checkAgreement = agreementError.valid && withdrawalAgreement;
    if (checkAgreement) {
      fetchWithdrawalRequest().then(() => {
        fetchWithdrawalInfo();
        window.scrollTo(0, 0);
      });
    }
  };

  return (
    <Box
      className="flex flex-col gap-7"
      data-testid="feature-withdrawal--container"
    >
      {isWithdrawalRequestLoading && <LoadingIndicator size="lg" isFixed />}

      {isAlreadyRequestedWithdrawal ? (
        <WithdrawalRequestComplete
          dueDate={withdrawalInfo?.dueDate}
          fetchCancelWithdrawal={fetchCancelWithdrawal}
        />
      ) : (
        <WithdrawalRequest
          agreement={withdrawalAgreement}
          agreementError={agreementError}
          onAgreementChange={onAgreementChange}
          handleWithdrawal={handleWithdrawal}
        />
      )}
    </Box>
  );
};

export default withComponentLogger(Withdrawal);
