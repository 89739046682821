import { ChangeEvent, FormEvent } from 'react';
import { Box, Button, TextField, Typography } from '@wishket/design-system';

import { PasswordChangeType } from '@/shared/types';

interface PasswordChangeFormProps extends PasswordChangeType {
  onSubmit?: (e: FormEvent<HTMLFormElement>) => void;
  onPasswordChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const PasswordChangeForm = ({
  inputValue,
  errorStatus,
  onSubmit,
  onPasswordChange,
}: PasswordChangeFormProps) => {
  return (
    <form
      className="flex flex-col gap-8 desktop:gap-10"
      key="password-change"
      data-testid="password-change-form"
      onSubmit={onSubmit}
    >
      <Box className="flex flex-col gap-2">
        <Typography variant="subTitle1" className="desktop:text-2xl">
          비밀번호 변경
        </Typography>
        <Typography variant="body2" className="text-w-gray-600">
          위시켓 통합회원의 비밀번호를 변경할 수 있습니다.
        </Typography>
      </Box>
      <Box className="flex flex-col gap-6 desktop:w-[460px]">
        <TextField
          type="password"
          variant="lable"
          lable="새 비밀번호"
          isError={!errorStatus?.password?.valid}
          errorMessage={errorStatus?.password?.errorMessage}
          name="password"
          supportMessage="8자 이상 32자 이하로 입력해 주세요."
          value={inputValue?.password}
          onChange={onPasswordChange}
          maxLength={32}
          isMaxLengthVisible={false}
        />

        <TextField
          type="password"
          variant="lable"
          lable="새 비밀번호 확인"
          isError={!errorStatus?.passwordConfirm?.valid}
          errorMessage={errorStatus?.passwordConfirm?.errorMessage}
          name="passwordConfirm"
          supportMessage="새 비밀번호를 한 번 더 입력해 주세요."
          value={inputValue?.passwordConfirm}
          onChange={onPasswordChange}
          maxLength={32}
          isMaxLengthVisible={false}
        />
      </Box>
      <Button
        type="submit"
        variant="solid"
        size="md"
        className="w-full desktop:w-[120px]"
      >
        변경하기
      </Button>
    </form>
  );
};

export default PasswordChangeForm;
