import Link from 'next/link';
import { SystemIcon, Typography } from '@wishket/design-system';

import { PropsWithChildren } from 'react';

import { NameWithLink } from '../Footer.types';

import {
  FOOTER_CUSTOMER_SUPPORT_LINKS,
  FOOTER_OTHER_SERVICE_LINKS,
  FOOTER_USAGE_LINKS,
  FOOTER_WISHKET_INFO_LINKS,
} from '../Footer.constants';

interface LinkSetProps extends PropsWithChildren {
  title?: string;
}

const LinkWithArrow = ({ name, link }: NameWithLink) => {
  return (
    <Typography variant="body2" className="font-bold text-w-gray-600">
      <Link
        href={link}
        className="mb-1 flex items-center gap-[6px] hover:underline"
      >
        {name}
        <SystemIcon name="small_arrow_right" />
      </Link>
    </Typography>
  );
};

const CompanyServiceLink = ({ name, link }: NameWithLink) => {
  return (
    <Typography variant="body2" className="text-w-gray-600 hover:underline">
      <Link href={link}>{name}</Link>
    </Typography>
  );
};

const LinkMenu = ({ title, children }: LinkSetProps) => {
  return (
    <div className="flex w-[185px] flex-col gap-2">
      {title && (
        <Typography variant="body2" className="mb-1 font-bold text-w-gray-600">
          {title}
        </Typography>
      )}
      {children}
    </div>
  );
};

export const LinkSet = () => {
  return (
    <div className="hidden justify-end py-8 grid-in-link desktop:flex">
      <LinkMenu title="위시켓">
        {FOOTER_WISHKET_INFO_LINKS.map((info) => (
          <CompanyServiceLink
            key={info.name}
            name={info.name}
            link={info.link}
          />
        ))}
      </LinkMenu>
      <LinkMenu title="이용방법">
        {FOOTER_USAGE_LINKS.map((usage) => (
          <CompanyServiceLink
            key={usage.name}
            name={usage.name}
            link={usage.link}
          />
        ))}
      </LinkMenu>
      <LinkMenu title="고객센터">
        {FOOTER_CUSTOMER_SUPPORT_LINKS.map((support) => (
          <CompanyServiceLink
            key={support.name}
            name={support.name}
            link={support.link}
          />
        ))}
      </LinkMenu>
      <LinkMenu>
        {FOOTER_OTHER_SERVICE_LINKS.map((service) => (
          <LinkWithArrow
            key={service.name}
            name={service.name}
            link={service.link}
          />
        ))}
      </LinkMenu>
    </div>
  );
};

export default LinkSet;
