'use client';

import { ChangeEvent, FormEvent, useEffect, useState } from 'react';

import PasswordVerifyForm from '@/features/PasswordVerifyForm';
import { usePasswordVerify } from '@/entities/password';
import { useInput, useThrottle } from '@/shared/hooks';
import { validateInput } from '@/shared/utils';
import { LoadingDialog } from '@/shared/Components';
import { PasswordChangeConfirmParams } from '@/shared/types';
import { withComponentLogger } from '@/shared/hocs';

interface PasswordVerifyProps {
  title: string;
  handleVerifyData: (data: PasswordChangeConfirmParams) => void;
}

const PasswordVerify = ({ title, handleVerifyData }: PasswordVerifyProps) => {
  const [passwordError, setPasswordError] = useState({
    currentPassword: { valid: true, errorMessage: '' },
  });
  const {
    data: passwordVerifyData,
    isError: isPasswordVerifyError,
    fetch: fetchPasswordVerify,
    isLoading,
  } = usePasswordVerify();
  const { inputValue, handleInputChange } = useInput<{
    currentPassword: string;
  }>({
    currentPassword: '',
  });
  const { password: validatePassword } = validateInput;

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, maxLength } = e.target;
    if (value.length === maxLength) {
      return setPasswordError({
        ...passwordError,
        [name]: { valid: false, errorMessage: '글자수를 초과하였습니다.' },
      });
    }
    const validation = validatePassword(value);

    if (!validation?.valid) {
      setPasswordError({ ...passwordError, [name]: validation });
    } else {
      setPasswordError({
        ...passwordError,
        [name]: { valid: true, errorMessage: '' },
      });
    }
    handleInputChange(e);
  };

  const handleAuthorization = useThrottle((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const trimmedValue = inputValue.currentPassword.trim();
    const validationCheck = validatePassword(trimmedValue);
    setPasswordError({ ...passwordError, currentPassword: validationCheck });

    if (validationCheck.valid) {
      fetchPasswordVerify({ password: trimmedValue });
    }
  });

  useEffect(() => {
    if (passwordVerifyData && passwordVerifyData.result)
      handleVerifyData(passwordVerifyData);
    window.scrollTo(0, 0);
  }, [passwordVerifyData, handleVerifyData]);

  useEffect(() => {
    if (isPasswordVerifyError) {
      setPasswordError({
        ...passwordError,
        currentPassword: {
          valid: false,
          errorMessage: '비밀번호가 일치하지 않습니다.',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPasswordVerifyError]);

  return (
    <>
      {isLoading && <LoadingDialog description="비밀번호 인증 중입니다." />}
      <PasswordVerifyForm
        title={title}
        errorStatus={passwordError}
        inputValue={inputValue}
        onPasswordChange={handlePasswordChange}
        onSubmit={handleAuthorization}
      />
    </>
  );
};

export default withComponentLogger(PasswordVerify);
