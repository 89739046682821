import { Typography } from '@wishket/design-system';
import Link from 'next/link';

import { PATH } from '@/shared/constants';

const CompanyInfo = () => {
  return (
    <div className="flex flex-col gap-4 py-8 grid-in-company desktop:pt-0">
      <div className="flex items-center">
        <Typography variant="body2" className="text-w-gray-600 hover:underline">
          <Link target="_blank" href={PATH.TERMS_SERVICE}>
            이용약관
          </Link>
        </Typography>
        <div className="mx-2 h-[14px] w-px bg-w-gray-200" />
        <Typography
          variant="body2"
          className="font-bold text-w-gray-600 hover:underline"
        >
          <Link target="_blank" href={PATH.TERMS_PRIVACY}>
            개인정보 처리방침
          </Link>
        </Typography>
      </div>

      <address className="flex w-full max-w-[680px] flex-wrap items-center gap-y-1.5 not-italic text-w-gray-400">
        <Typography variant="caption1" className="w-fit">
          ㈜위시켓
        </Typography>
        <div className="mx-2 h-[14px] w-px bg-w-gray-200" />
        <Typography
          variant="caption1"
          className="tablet:w-[610px] desktop:w-fit"
        >
          대표이사 : 박우범
        </Typography>
        <div className="mx-2 hidden h-[14px] w-px bg-w-gray-200 desktop:block" />
        <Typography
          variant="caption1"
          className="w-full tablet:w-fit desktop:w-[430px]"
        >
          서울특별시 강남구 테헤란로 211 3층 ㈜위시켓
        </Typography>
        <div className="mx-2 hidden h-[14px] w-px bg-w-gray-200 tablet:block desktop:hidden" />
        <Typography
          variant="caption1"
          className="w-full tablet:w-[430px] desktop:w-fit"
        >
          사업자등록번호 : 209-81-57303
        </Typography>
        <div className="mx-2 hidden h-[14px] w-px bg-w-gray-200 desktop:block" />
        <Typography variant="caption1" className="w-full tablet:w-fit">
          통신판매업신고 : 제2018-서울강남-02337 호
        </Typography>
        <div className="mx-2 hidden h-[14px] w-px bg-w-gray-200 tablet:block" />
        <Typography variant="caption1" className="w-full tablet:w-fit">
          직업정보제공사업 신고번호 : J1200020180019
        </Typography>
      </address>

      <Typography variant="caption1" className="text-w-gray-400">
        © 2013 Wishket Corp.
      </Typography>
    </div>
  );
};

export default CompanyInfo;
