import { serverAxios } from '@/shared/api';

export const sendEmailPasswordChangeApi = async ({
  email,
}: {
  email: string;
}) => {
  return await serverAxios.post('/member/password/change-request/', {
    email,
  });
};

export interface EmailUnsubscribeApiParams {
  hash: string;
  flag: string;
}

export const emailUnsubscribeApi = async ({
  hash,
  flag,
}: EmailUnsubscribeApiParams) => {
  return await serverAxios.post('/member/unsubscribe/', {
    hash,
    flag,
  });
};
