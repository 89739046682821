'use client';

import { useState } from 'react';
import { isAxiosError } from 'axios';

import { notifyToast } from '@/shared/utils';
import { clientAxios } from '@/shared/api';

const useVerifyEmailResend = () => {
  const [isResend, setIsResend] = useState<boolean | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const resetResendStatus = () => {
    setIsResend(undefined);
  };

  const fetchResend = async (email: string) => {
    setIsLoading(true);

    try {
      const { data } = await clientAxios.post('/resendEmailVerifyApi', {
        email,
      });

      if (data.ok) {
        setIsResend(true);
        notifyToast({ message: '인증 메일이 재발송되었습니다.' });
      } else {
        throw new Error();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setIsResend(false);

      if (isAxiosError(e) && e.response) {
        const {
          error: { data },
        } = e.response.data;

        if (data?.msg === 'Already activated')
          notifyToast({ message: '이미 인증된 이메일입니다.' });
        else {
          notifyToast({
            type: 'error',
            message: '인증 메일 재발송에 실패했습니다.',
          });
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    fetch: fetchResend,
    reset: resetResendStatus,
    data: isResend,
    isLoading,
  };
};

export default useVerifyEmailResend;
