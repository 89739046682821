'use client';

import { ChangeEvent, FormEvent, useEffect, useState } from 'react';

import { useAuthCompletion, useInput } from '@/shared/hooks';
import { SignUpInputErrorType, SignUpInputValueType } from '@/shared/types';
import { notifyToast, validateInput } from '@/shared/utils';
import { useSocialLogin } from '@/entities/login';
import { useSocialAuth } from '@/shared/providers';
import { useSignUp } from '@/entities/signUp';

const useSignUpWidget = () => {
  const { referrerService, redirectAfterSignup, redirectNext } =
    useAuthCompletion();
  const [inputError, setInputError] = useState<SignUpInputErrorType>({
    email: { valid: true, errorMessage: '' },
    id: { valid: true, errorMessage: '' },
    password: { valid: true, errorMessage: '' },
    passwordConfirm: { valid: true, errorMessage: '' },
    policyAgreement: { valid: true, errorMessage: '' },
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, maxLength, checked, type } = e.target;
    const isPassword = name === 'password';

    if (name === 'allAgreement') {
      setInputValue({
        ...inputValue,
        policyAgreement: checked,
        marketingUseAgreement: checked,
        marketingSubscribeAgreement: checked,
      });

      setInputError({
        ...inputError,
        policyAgreement: { valid: checked, errorMessage: '' },
      });

      return;
    }

    if (name === 'marketingUseAgreement') {
      setInputValue({
        ...inputValue,
        marketingUseAgreement: checked,
        marketingSubscribeAgreement: checked,
      });

      return;
    }

    if (name === 'marketingSubscribeAgreement' && checked) {
      setInputValue({
        ...inputValue,
        marketingUseAgreement: checked,
        marketingSubscribeAgreement: checked,
      });

      return;
    }

    if (value.length === maxLength) {
      return setInputError({
        ...inputError,
        [name]: { valid: false, errorMessage: '글자수를 초과하였습니다.' },
      });
    }
    const incomingValue = type === 'checkbox' ? checked : value.trim();
    const validation =
      name === 'passwordConfirm'
        ? validateInput.passwordConfirm(value.trim(), inputValue.password)
        : (
            validateInput[name as keyof typeof validateInput] as (
              value: string | boolean,
              reference?: string
            ) => { valid: boolean; errorMessage: string }
          )(incomingValue);

    handleTrimmedInputChange(e);

    if (isPassword) {
      const passwordConfirmValidation =
        isPassword && inputValue.passwordConfirm !== ''
          ? validateInput.passwordConfirm(
              inputValue.passwordConfirm.trim(),
              incomingValue as string
            )
          : inputError.passwordConfirm;
      return setInputError({
        ...inputError,
        [name]: validation,
        passwordConfirm: passwordConfirmValidation,
      });
    }
    if (name === 'policyAgreement' || !validation?.valid)
      return setInputError({ ...inputError, [name]: validation });

    setInputError({
      ...inputError,
      [name]: { valid: true, errorMessage: '' },
    });
  };

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const validationCheck = {
      email: validateInput.email(inputValue.email),
      id: validateInput.id(inputValue.id),
      password: validateInput.password(inputValue.password),
      passwordConfirm: validateInput.passwordConfirm(
        inputValue.passwordConfirm.trim(),
        inputValue.password.trim()
      ),
      policyAgreement: validateInput.policyAgreement(
        inputValue.policyAgreement
      ),
    };

    setInputError(validationCheck);

    if (
      validationCheck.email.valid &&
      validationCheck.id.valid &&
      validationCheck.password.valid &&
      validationCheck.passwordConfirm.valid &&
      validationCheck.policyAgreement.valid
    ) {
      fetchSignUp({
        signup_service: referrerService,
        email: inputValue.email,
        username: inputValue.id,
        password1: inputValue.password,
        password2: inputValue.passwordConfirm,
        privacyInfo: inputValue.policyAgreement,
        privacyMarketing: inputValue.marketingUseAgreement,
        receiveMarketingInfo: inputValue.marketingSubscribeAgreement,
      });
    }
  };

  const handleSignUpError = () => {
    if (signUpError) {
      setInputError({
        ...inputError,
        email: {
          valid: !signUpError.email,
          errorMessage: signUpError.email || '',
        },
        id: {
          valid: !signUpError.id,
          errorMessage: signUpError.id || '',
        },
      });
    } else {
      notifyToast({ type: 'error', message: '회원가입에 실패하였습니다.' });
    }
  };

  const { inputValue, handleTrimmedInputChange, setInputValue } =
    useInput<SignUpInputValueType>({
      email: '',
      id: '',
      password: '',
      passwordConfirm: '',
      policyAgreement: false,
      marketingUseAgreement: false,
      marketingSubscribeAgreement: false,
    });

  const {
    isLoading,
    data: isSigned,
    error: signUpError,
    fetch: fetchSignUp,
    reset,
  } = useSignUp();

  const { isLoading: isSocialLoginLoading, data: socialLoginData } =
    useSocialLogin();
  const { socialLoginMessageStatus, fetchSocialLogin } = useSocialAuth();

  useEffect(() => {
    if (isSigned === undefined || isLoading) return;

    if (isSigned) {
      redirectAfterSignup();
      notifyToast({ message: '회원가입에 성공했습니다.' });
    } else handleSignUpError();

    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSigned, isLoading]);

  useEffect(() => {
    if (socialLoginMessageStatus.get) {
      redirectNext(socialLoginMessageStatus.get);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialLoginMessageStatus.get]);

  return {
    isSocialLoginLoading,
    isLoading,
    inputValue,
    inputError,
    handleChange,
    handleFormSubmit,
    fetchSocialLogin,
    socialLoginData,
  };
};

export default useSignUpWidget;
