import Link from 'next/link';
import { Box, ProductIcon, Typography } from '@wishket/design-system';
import { twJoin } from 'tailwind-merge';

export type MainLinkCardIcon = 'person_smile' | 'lock' | 'logout';

interface MainLinkCardProps {
  link: string;
  iconName: MainLinkCardIcon;
  title: string;
  description: string;
}
const MainLinkCard = ({
  title,
  description,
  iconName,
  link,
}: MainLinkCardProps) => {
  return (
    <Box className="w-full">
      <Link href={link}>
        <Box
          data-testid="main-link-card"
          className={twJoin(
            `flex h-full items-center gap-4 rounded-xl border border-w-gray-200 p-6`,
            `transition duration-500 ease-in-out hover:-translate-y-1 hover:border-w-primary hover:shadow-contents-container`,
            `desktop:flex-col desktop:items-start desktop:py-6`
          )}
        >
          <ProductIcon name={iconName} size="medium" />
          <Box className="flex flex-col gap-1.5">
            <Typography
              variant="body1"
              className="whitespace-pre-line font-medium text-w-gray-900"
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              className="hidden whitespace-pre-line text-w-gray-600 desktop:block"
            >
              {description}
            </Typography>
          </Box>
        </Box>
      </Link>
    </Box>
  );
};

export default MainLinkCard;
