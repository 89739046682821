'use client';

import { useRouter } from 'next/navigation';
import { Box } from '@wishket/design-system';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';

import PATH from '@/shared/constants/path';
import { useInput, useNextUrl, useThrottle } from '@/shared/hooks';
import {
  PasswordChangeConfirmParams,
  SignUpInputValueType,
} from '@/shared/types';
import { notifyToast, validateInput } from '@/shared/utils';
import { LoadingDialog } from '@/shared/Components';
import { PasswordChangeForm } from '@/features';
import { usePasswordChange } from '@/entities/password';
import { withComponentLogger } from '@/shared/hocs';

interface PasswordChangeProps {
  passwordVerifyData: PasswordChangeConfirmParams | undefined;
}

const PasswordChange = ({ passwordVerifyData }: PasswordChangeProps) => {
  const router = useRouter();
  const [passwordError, setPasswordError] = useState({
    password: { valid: true, errorMessage: '' },
    passwordConfirm: { valid: true, errorMessage: '' },
  });

  const {
    data: isPasswordChange,
    fetch: fetchPasswordChange,
    isLoading: isPasswordChangeLoading,
  } = usePasswordChange();
  const { withNextUrl } = useNextUrl();

  const { inputValue, handleTrimmedInputChange } = useInput<
    Pick<SignUpInputValueType, 'password' | 'passwordConfirm'>
  >({
    password: '',
    passwordConfirm: '',
  });

  const {
    password: validatePassword,
    passwordConfirm: validatePasswordConfirm,
  } = validateInput;

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, maxLength } = e.target;
    const isPassword = name === 'password';
    const incomingValue = value.trim();
    if (incomingValue.length === maxLength) {
      return setPasswordError({
        ...passwordError,
        [name]: { valid: false, errorMessage: '글자수를 초과하였습니다.' },
      });
    }
    const validation =
      name === 'passwordConfirm'
        ? validatePasswordConfirm(incomingValue, inputValue.password.trim())
        : validatePassword(incomingValue);
    handleTrimmedInputChange(e);
    if (isPassword) {
      const passwordConfirmValidation =
        isPassword && inputValue.passwordConfirm !== ''
          ? validateInput.passwordConfirm(
              inputValue.passwordConfirm.trim(),
              incomingValue as string
            )
          : passwordError.passwordConfirm;
      return setPasswordError({
        ...passwordError,
        [name]: validation,
        passwordConfirm: passwordConfirmValidation,
      });
    }
    if (!validation?.valid) {
      setPasswordError({ ...passwordError, [name]: validation });
    } else {
      setPasswordError({
        ...passwordError,
        [name]: { valid: true, errorMessage: '' },
      });
    }
  };

  const handleChangePassword = useThrottle((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const passwordValidationCheck = validatePassword(inputValue.password);
    const passwordConfirmValidationCheck = validatePasswordConfirm(
      inputValue.passwordConfirm.trim(),
      inputValue.password.trim()
    );

    setPasswordError({
      ...passwordError,
      password: passwordValidationCheck,
      passwordConfirm: passwordConfirmValidationCheck,
    });
    if (
      passwordValidationCheck.valid &&
      passwordConfirmValidationCheck.valid &&
      passwordVerifyData
    ) {
      fetchPasswordChange({
        uid: passwordVerifyData.uid,
        token: passwordVerifyData.token,
        password1: inputValue.password,
        password2: inputValue.passwordConfirm,
      });
    }
  });

  useEffect(() => {
    if (isPasswordChange) {
      notifyToast({ message: '비밀번호가 변경되었습니다.' });
      router.push(withNextUrl(PATH.HOME));
    } else if (isPasswordChange === false) {
      notifyToast({
        type: 'error',
        message: '비밀번호 변경에 실패하였습니다.',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPasswordChange]);

  return (
    <Box
      className="flex flex-col gap-7"
      data-testid="feature-password-change--container"
    >
      {isPasswordChangeLoading && (
        <LoadingDialog description="비밀번호 변경 중입니다." />
      )}
      <PasswordChangeForm
        errorStatus={passwordError}
        inputValue={inputValue}
        onPasswordChange={handlePasswordChange}
        onSubmit={handleChangePassword}
      />
    </Box>
  );
};

export default withComponentLogger(PasswordChange);
