'use client';

import { useState } from 'react';
import { isAxiosError } from 'axios';

import { clientAxios } from '@/shared/api';

const ERROR_MESSAGES: { [key: string]: string } = {
  'Account which has same email address does not exists.':
    '가입된 계정이 없습니다. 이메일을 다시 확인해주세요.',
  'Account did not complete activation. Check your email and activate your account.':
    '회원가입 중인 계정이 있습니다. 회원가입을 완료해주세요.',
  'facebook Social login did not completed.':
    '페이스북 계정으로 가입 중인 이메일입니다. 페이스북 계정으로 회원가입을 완료해주세요.',
  'google-oauth2 Social login did not completed.':
    '구글 계정으로 가입 중인 이메일입니다. 구글 계정으로 회원가입을 완료해주세요.',
  default: '문제가 발생했습니다. 고객센터로 문의해주세요.',
};

const getErrorMessage = (error?: string) => {
  return (
    ERROR_MESSAGES[error as keyof typeof ERROR_MESSAGES] ||
    ERROR_MESSAGES.default
  );
};
const useSendEmailPasswordChangeApi = () => {
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchSendEmailPasswordChange = async ({ email }: { email: string }) => {
    setIsLoading(true);

    try {
      const { data } = await clientAxios.post('/sendEmailPasswordChangeApi', {
        email,
      });
      if (data.ok) {
        setIsSuccess(true);
      }
    } catch (e) {
      setIsError(true);

      if (isAxiosError(e) && e.response) {
        const {
          error: { status, data },
        } = e.response.data;

        switch (status) {
          case 400:
            setMessage(getErrorMessage(data.toString()));
            break;
          case 403:
            setMessage(getErrorMessage(data.detail));
            break;
          default:
            setMessage(getErrorMessage());
        }
      } else {
        setMessage('');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const resetData = () => {
    setIsSuccess(false);
    setIsError(false);
    setMessage('');
  };

  return {
    message,
    isSuccess,
    isError,
    isLoading,
    resetData,
    fetch: fetchSendEmailPasswordChange,
  };
};

export default useSendEmailPasswordChangeApi;
