import { PATH } from '@/shared/constants';

export const pageTitleMapper = new Map([
  ['default', '위시켓 통합회원 계정 설정'],
  [PATH.PASSWORD_FIND, '위시켓 통합회원 비밀번호 찾기'],
  [PATH.PASSWORD_RESET, '위시켓 통합회원 비밀번호 재설정'],
  [PATH.LOGIN, '로그인'],
  [PATH.SIGNUP, '위시켓 통합회원가입'],
  [PATH.SIGNUP_SOCIAL, '위시켓 통합회원가입'],
  ['google', '위시켓 구글 통합회원가입'],
  ['facebook', '위시켓 페이스북 통합회원가입'],
  [PATH.TERMS_PRIVACY, '위시켓 서비스 정책'],
  [PATH.TERMS_SERVICE, '위시켓 서비스 정책'],
  [PATH.TERMS_YOUTH_PROTECTION, '위시켓 서비스 정책'],
  [PATH.SIGNUP_EMAIL_VERIFY, '위시켓 통합회원 이메일 인증하기'],
  [PATH.SIGNUP_COMPLETE, '위시켓 통합회원가입 완료'],
]);
